import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { getIso2 } from '@rbilabs/intl';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useIntl } from 'react-intl';

import { ModalAgreement } from 'components/modal-agreement';
import { ICommunicationPreference, IFavoriteOffer, IFavoriteStore } from 'generated/rbi-graphql';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useErrorModal } from 'hooks/use-error-modal';
import { useGetRequiredUserAcceptance } from 'hooks/use-get-required-user-acceptance';
import { UserBlocked, UserNotFoundContinueToSignUp } from 'pages/authentication/sign-up/types';
import { getCurrentSession } from 'remote/auth';
import { blockedUserLink } from 'state/graphql/links/blocked-user-link';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { SwitchUpdateDateAcceptanceAgreement } from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { useNetworkContext } from 'state/network';
import { LocalStorage, StorageKeys } from 'utils/local-storage';
import { logger } from 'utils/logger';
import { routes } from 'utils/routing';
import { requiresUserAgreement } from 'utils/user-agreements';
import { IRequiredUserAcceptanceList } from 'utils/user-agreements/types';

import {
  ICreateAccount,
  ISignIn,
  ISignUpResult,
  ISocialLoginParams,
  useAccountAuthentication,
} from './hooks/use-account-authentication';
import { UserDetails, useCurrentUser } from './hooks/use-current-user';

export enum BooleanAsString {
  True = 'true',
  False = 'false',
}

export type CommunicationPreferences = Array<{
  id: string;
  value: string | BooleanAsString.True | BooleanAsString.False;
}>;

export type FavoriteStore = {
  storeId: string;
  storeNumber?: string;
};

export type RequiredAcceptanceAgreementInfo = {
  id: string;
  updatedAt: string;
};

export type FavoriteStores = Array<FavoriteStore>;

export type FavoriteOffer = {
  id: string;
};

export type FavoriteOffers = Array<FavoriteOffer>;

export type UpdateUserInfo = {
  dob: string;
  name: string;
  phoneNumber: string;
  promotionalEmails: boolean;
  isoCountryCode: string;
  zipcode: string;
  defaultReloadAmt: number;
  defaultCheckoutPaymentMethodId?: string;
  defaultReloadPaymentMethodId?: string;
  autoReloadEnabled: boolean;
  autoReloadThreshold: number;
};

type SignupArgs = {
  email: string;
  name: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails?: boolean;
  wantsPromotionalEmailOrSms?: boolean;
  providerType?: string;
};

export type UpdateAgreementAcceptance = {
  acceptanceFromSanity: IRequiredUserAcceptanceList;
  emailMarketing: boolean;
};

export interface AuthInterface {
  // Computed values
  loading: boolean;
  originLocation: null | string;
  user: null | UserDetails;
  currentUserSession: CognitoUserSession | null;
  // Functions
  getCurrentSession(): Promise<null | CognitoUserSession>;
  refreshCurrentUser(): Promise<void>;
  refreshCurrentUserWithNewSession(): Promise<void>;
  isAuthenticated(): boolean;
  setOriginLocation(location: null | string): void;
  signIn(args: ISignIn): Promise<void>;
  signInSocialLogin(
    userInfos: ISocialLoginParams
  ): Promise<void | UserNotFoundContinueToSignUp | UserBlocked>;
  signOut(): Promise<void>;
  signUp(
    args: SignupArgs,
    signInOverride?: (args: ISignIn) => Promise<void>
  ): Promise<ISignUpResult>;
  updateUserInfo(args: UpdateUserInfo, shouldMuteUserInfoErrors?: boolean): Promise<void>;
  updateUserCommPrefs(commPrefs: Array<ICommunicationPreference>): Promise<void>;
  updateUserFavStores(favStores: Array<IFavoriteStore>): Promise<void>;
  updateUserFavOffers(offers: Array<IFavoriteOffer>): Promise<void>;
  upsateUserPiiInfo(userPII: Record<string, string>): Promise<void>;
  updateUserPhoneNumber(phoneNumber: string): Promise<void>;
  updateUserLastKnownLoyaltyTier(loyaltyTier: string): Promise<void>;
  useUpdateMeMutationLoading: boolean;
  validateLogin(args: { jwt: string; username: string }): Promise<void>;
  validateLoginOtp(args: { otpCode: string; isGuest: boolean }): Promise<void>;
  userHasSignedIn: boolean;
  sendOtpToGuestEmail(args: ISignIn): Promise<void>;
  createAccountFromVerifiedGuest(args: ICreateAccount): Promise<void>;
}

export const AuthContext = createContext<AuthInterface>({} as any as AuthInterface);
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
  userHasSignedIn,
  userSession,
}: {
  children: ReactNode;
  userHasSignedIn: boolean;
  userSession: CognitoUserSession | null;
}) => {
  const { hasNotAuthenticatedError, setHasNotAuthenticatedError } = useNetworkContext();
  const { navigate } = useLocationContext();
  const signOutOnInvalidToken = useFlag(LaunchDarklyFlag.ENABLE_SIGNOUT_ON_INVALID_TOKEN);
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const [requiredAgreements, setRequiredAgreements] = useState<IRequiredUserAcceptanceList | null>(
    null
  );
  const [hideAgreementsModal, setHideAgreementsModal] = useState(false);

  const enableAccountRegionVerification = useFlag(
    LaunchDarklyFlag.ENABLE_ACCOUNT_REGION_VERIFICATION
  );

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    onConfirm: () => navigate(routes.signUp, { replace: true }),
    modalAppearanceEventMessage: 'Error: Auth Error',
  });

  const acceptanceAgreementUpdatedDates = useFlag<SwitchUpdateDateAcceptanceAgreement>(
    LaunchDarklyFlag.SWITCH_UPDATE_DATE_ACCEPTANCE_AGREEMENT
  );

  const {
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    setCurrentUser,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserFavOffers,
    upsateUserPiiInfo,
    updateUserPhoneNumber,
    updateUserInfo,
    updateRequiredAcceptanceAgreementInfo,
    updateUserLastKnownLoyaltyTier,
    user,
    userLoading,
    useUpdateMeMutationLoading,
    currentUserSession,
  } = useCurrentUser({
    openErrorDialog,
    hasSignedIn: userHasSignedIn,
    userSession,
  });
  const {
    authLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signInSocialLogin,
    signOut,
    signUp,
    validateLogin,
    validateLoginOtp,
    sendOtpToGuestEmail,
    createAccountFromVerifiedGuest,
  } = useAccountAuthentication({
    refreshCurrentUser,
    openErrorDialog,
    setCurrentUser,
  });

  const { getRequiredUserAcceptance, called: getRequiredUserAcceptanceCalled } =
    useGetRequiredUserAcceptance();

  // use cognito to determind if user is authed or not. Prevent showing un-auth view to auth user.
  // only use userHasSignedIn while waiting for user response.
  const authenticatedUser = typeof user !== 'undefined' ? !!user : userHasSignedIn;
  const isAuthenticated = useCallback(() => {
    return authenticatedUser;
  }, [authenticatedUser]);

  useEffect(() => {
    // update the user information in the Datadog RUM session, if initialised
    if (!datadogRum.getInitConfiguration()) {
      return;
    }
    if (!user?.cognitoId) {
      datadogRum.clearUser();
      return;
    }
    datadogRum.setUser({
      id: user.cognitoId,
      loyaltyId: user.loyaltyId ?? null,
    });
  }, [user?.cognitoId, user?.loyaltyId]);

  useEffectOnce(() => {
    const handleBlockedUser = () => {
      signOut();
    };

    blockedUserLink.setBlockedUserHandler(handleBlockedUser);
  });

  const getCtx = useCallback(() => {
    return {
      // Computed values
      loading: authLoading || userLoading,
      useUpdateMeMutationLoading,
      originLocation,
      user,
      currentUserSession,
      // Functions
      getCurrentSession,
      refreshCurrentUser,
      refreshCurrentUserWithNewSession,
      isAuthenticated,
      signIn,
      signInSocialLogin,
      signOut,
      signUp,
      updateUserInfo,
      updateUserCommPrefs,
      updateUserFavStores,
      updateUserFavOffers,
      upsateUserPiiInfo,
      updateUserPhoneNumber,
      updateUserLastKnownLoyaltyTier,
      validateLogin,
      validateLoginOtp,
      userHasSignedIn,
      setOriginLocation: (args: null | string) => setOriginLoc(args),
      sendOtpToGuestEmail,
      createAccountFromVerifiedGuest,
    };
  }, [
    authLoading,
    userLoading,
    useUpdateMeMutationLoading,
    originLocation,
    user,
    currentUserSession,
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    isAuthenticated,
    signIn,
    signInSocialLogin,
    signOut,
    signUp,
    updateUserInfo,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserFavOffers,
    upsateUserPiiInfo,
    updateUserPhoneNumber,
    updateUserLastKnownLoyaltyTier,
    validateLogin,
    validateLoginOtp,
    userHasSignedIn,
    setOriginLoc,
    sendOtpToGuestEmail,
    createAccountFromVerifiedGuest,
  ]);

  useEffect(() => {
    // save redirect url so user can get redirected correctly after login
    LocalStorage.setItem(StorageKeys.AUTH_REDIRECT, { callbackUrl: originLocation });
  }, [originLocation]);

  useEffect(() => {
    if (hasNotAuthenticatedError) {
      if (signOutOnInvalidToken) {
        signOut();
        logger.warn({
          message: 'User was signed out cause token was invalid',
        });
      }

      setHasNotAuthenticatedError(false);
    }
  }, [hasNotAuthenticatedError, setHasNotAuthenticatedError, signOut, signOutOnInvalidToken]);

  useEffect(() => {
    if (enableAccountRegionVerification && user?.details?.isoCountryCode) {
      const userRegion = getIso2({ iso3: user.details.isoCountryCode });

      // Make sure we have values for the current region and user region
      if (region && userRegion && region !== userRegion) {
        const sessionEmail = currentUserSession?.getIdToken().payload.email;
        const gqlEmail = user?.details?.email;

        // If the session email does not match the user details returned by the
        // GQL query wait for it to be updated before logging the user out
        if (sessionEmail !== gqlEmail) {
          refreshCurrentUser();
          return;
        }

        // Force sign out
        signOut();

        // Show detailed error message
        openErrorDialog({
          title: formatMessage({ id: 'authRegionErrorTitle' }),
          message: formatMessage({ id: 'authRegionErrorDetails' }),
        });
      }
    }
  }, [
    currentUserSession,
    enableAccountRegionVerification,
    formatMessage,
    openErrorDialog,
    refreshCurrentUser,
    region,
    signOut,
    user,
  ]);

  const clickContinue = useCallback(
    async (updateAgreementAcceptance: UpdateAgreementAcceptance) => {
      const result = await updateRequiredAcceptanceAgreementInfo(updateAgreementAcceptance);

      if (result) {
        setHideAgreementsModal(true);
        setRequiredAgreements(null);
      }
    },
    [updateRequiredAcceptanceAgreementInfo]
  );

  const onClickDisagree = useCallback(() => {
    setHideAgreementsModal(true);
    navigate(routes.signOut);
  }, [navigate]);

  const agreementsCdpData = useMemo(
    () => ({
      modalAppearanceEventMessage: 'Agree or disagree updated terms',
      modalHeader: formatMessage({ id: 'updatedAgreements' }),
      modalMessage: formatMessage({ id: 'reviewAcceptUpdatedAgreement' }),
    }),
    [formatMessage]
  );

  const commPrefs = useMemo(() => user?.details?.communicationPreferences, [user]);

  const validateAndSetRequiredAgrements = useCallback(async (): Promise<void> => {
    const acceptanceAgreementFromSanity = await getRequiredUserAcceptance();

    const requiredAgreementsList = acceptanceAgreementFromSanity.filter(
      requiresUserAgreement(
        user?.details?.requiredAcceptanceAgreementInfo,
        acceptanceAgreementUpdatedDates ?? undefined
      )
    );

    if (requiredAgreementsList?.length) {
      setRequiredAgreements(requiredAgreementsList);
    }
  }, [
    acceptanceAgreementUpdatedDates,
    getRequiredUserAcceptance,
    user?.details?.requiredAcceptanceAgreementInfo,
  ]);

  useEffect(() => {
    if (!user) {
      setRequiredAgreements(null);
      setHideAgreementsModal(false);
      return;
    }

    if (!requiredAgreements?.length) {
      setHideAgreementsModal(false);

      if (!getRequiredUserAcceptanceCalled) {
        validateAndSetRequiredAgrements();
      }
    }
  }, [
    requiredAgreements?.length,
    getRequiredUserAcceptanceCalled,
    user,
    validateAndSetRequiredAgrements,
  ]);

  return (
    <AuthContext.Provider value={getCtx()}>
      {children}
      <ErrorDialog />
      {!!requiredAgreements?.length && !hideAgreementsModal && (
        <ModalAgreement
          commPrefs={commPrefs}
          requiredTerms={requiredAgreements}
          continueUpdate={clickContinue}
          cancel={onClickDisagree}
          eventData={agreementsCdpData}
        />
      )}
    </AuthContext.Provider>
  );
};

export const Auth = AuthContext.Consumer;
