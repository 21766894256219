import React from 'react';

import { ISanityImage } from '@rbi-ctg/menu';
import { IImageFragment } from 'generated/sanity-graphql';
import { useUIContext } from 'state/ui';
import { logger } from 'utils/logger';

interface IRawPictureProps {
  image: IImageFragment | ISanityImage;
  alt: string;
  className?: string;
  height?: string;
  width?: string;
}

/**
 * RawPicture
 *
 * Similar to Picture, this fetches a Sanity img. The difference is that by not passing URL params (such as height & width)
 * on the img request, we bypass the Sanity image pipeline, retrieving the image as-is.
 * (https://www.sanity.io/docs/presenting-images#the-sanity-image-pipeline-QE5jDB7y)
 */
export const RawPicture: React.FC<IRawPictureProps> = ({
  image,
  alt,
  className,
  height,
  width,
  ...props
}) => {
  const { imageBuilder } = useUIContext();

  if (!image.asset) {
    logger.error(`No assets for this image: ${image}`);
    return null;
  }
  const imgSrc = imageBuilder.image(image).url();
  if (!imgSrc) {
    logger.warn(`Unable to build img src from image: ${image}`);
    return null;
  }

  return (
    <img src={imgSrc} alt={alt} className={className} height={height} width={width} {...props} />
  );
};
