import * as React from 'react';
import { FC, useCallback } from 'react';

import { Cell } from '@rbilabs/components-library/build/components/cell';
import { Grid } from '@rbilabs/components-library/build/components/grid';

import { MarketingCard } from 'components/features/components/marketing-card';
import { IMarketingCard, IMarketingCardFragment } from 'generated/sanity-graphql';
import { useBluecodeContext } from 'state/bluecode';
import { primitive } from 'styles/constants/primitives';
import { getWeekDay } from 'utils/dateTime';

import { IMarketingCardGroupProps } from './types';

/**
 *
 * MarketingCardGroup renders a group of marketing cards in a grid
 *
 */
export const MarketingCardGroup: FC<IMarketingCardGroupProps> = ({ doc, onShowTermsModal }) => {
  const cards = doc.Cards as IMarketingCard[];

  const { userHasBluecodeAccount, isBluecodeInitialized } = useBluecodeContext();

  const validBlueCodeMarketingCard = useCallback(
    (card: IMarketingCard): boolean => {
      const blueCodeRule = card.BKPayAudience;
      const bkSpecificTargeting = card.bkSpecificTargeting;

      if (!blueCodeRule || !bkSpecificTargeting) {
        return true;
      }

      if (!isBluecodeInitialized) {
        return false;
      }
      if (blueCodeRule === 'linked') {
        return userHasBluecodeAccount;
      } else if (blueCodeRule === 'unlinked') {
        return !userHasBluecodeAccount;
      }
      return true;
    },
    [isBluecodeInitialized, userHasBluecodeAccount]
  );

  const hasRules = ({ daysOfWeek, timeRanges, bkSpecificTargeting }: IMarketingCard) => {
    return !!daysOfWeek?.length || !!timeRanges?.length || !!bkSpecificTargeting;
  };

  const tileShouldBeDisplayed = (card: IMarketingCard) => {
    const now = new Date();
    const currentDayOfWeek = getWeekDay(now);
    const { daysOfWeek, timeRanges } = card;

    const getTimeParts = (time: string) => {
      const [hours, minutes, seconds] = time.split(':');
      const hoursInt = Number.parseInt(hours, 10);
      const minutesInt = Number.parseInt(minutes, 10);
      const secondsInt = Number.parseInt(seconds, 10);
      return [hoursInt, minutesInt, secondsInt];
    };

    const timeRangeAvailability = timeRanges?.map(range => {
      if (range && range.startTime && range && range && range.endTime) {
        const [startHour, startMinutes] = getTimeParts(range.startTime);
        const [endHour, endMinutes] = getTimeParts(range.endTime);
        const startTimeMs = new Date().setHours(startHour, startMinutes);
        const endTimeMs = new Date().setHours(endHour, endMinutes);
        const nowMs = now.getTime();
        return startTimeMs <= nowMs && nowMs <= endTimeMs;
      }

      return false;
    });

    const isActiveForDayOfWeek = daysOfWeek ? daysOfWeek.includes(currentDayOfWeek) : false;

    const isActiveForTimeRangeAvailability = timeRangeAvailability
      ? timeRangeAvailability?.some(range => range === true)
      : true; // If there is no timeRanges it is available all day.

    const validBlueCodeRule = validBlueCodeMarketingCard(card);

    return isActiveForDayOfWeek && isActiveForTimeRangeAvailability && validBlueCodeRule;
  };

  if (!cards || cards.length <= 0) {
    return null;
  }

  // NOTE: Return all cards, except those cards that have rule and do not meet the requirement
  const cardsList = cards.filter(card => {
    if (hasRules(card) && !tileShouldBeDisplayed(card)) {
      return false;
    }
    return true;
  });

  return (
    <Grid data-testid="marketing-tile-group-grid" style={{ rowGap: primitive.$spacing4 }}>
      {cardsList?.map((card, index) => {
        if (!card) {
          return null;
        }

        const key = `MarketingCard_${card._id}`;

        return (
          <Cell key={key} span={2} spanTablet={4} spanDesktopLarge={6}>
            <MarketingCard
              card={card as IMarketingCardFragment}
              onShowTermsModal={onShowTermsModal}
              index={index}
            />
          </Cell>
        );
      })}
    </Grid>
  );
};
