import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { Modal } from 'components/modal';
import { fadeIn } from 'utils/style/animations';

export const StyledModal = styled(Modal)`
  &[data-reach-dialog-content] {
    padding: 0;
    animation: ${fadeIn} 0.35s forwards;
    width: 90%;
    height: 245px;
    overflow: hidden;
    max-width: max-content;
    max-height: max-content;
    margin: calc(50vh - 100px) auto;
    position: relative;
    border-radius: ${Styles.borderRadius};

    > button {
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      margin-block-start: 10px;
      margin-block-end: 0;
      margin-inline-start: 10px;
      margin-inline-end: 0;
    }

    ${Styles.desktop} {
      height: 260px;
      margin: 0;
    }
    ${Styles.mobileTiny} {
      height: 255px;
    }
  }
`;

export const ModalInner = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  h3 {
    text-align: center;
    margin: 0;
    font-family: ${Styles.fontFamily.brand};
  }
`;

export const ModalHead = styled.div`
  width: 65%;
  margin-block-start: 2rem;

  ${Styles.desktop} {
    width: 55%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const Description = styled.div`
  padding: 1.5em;
  text-align: center;
  font-size: 1.15em;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 1em;

  ${Styles.desktop} {
    margin-block-start: 0;
  }
`;

export const DismissButton = styled(ActionButton)`
  width: 100%;
  margin-block-end: 0.6em;
  color: ${Styles.color.black};
  ${Styles.desktop} {
    width: 50%;
    margin-block-start: 0;
    margin-block-end: 0.6em;
    margin-inline: auto;
  }
`;

export const StyledStars = styled.div`
  display: flex;
`;
