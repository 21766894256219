import React, { SVGAttributes } from 'react';

export const IconLocked: React.FC<SVGAttributes<SVGElement>> = ({ ...props }) => {
  const fillColor = props.fill || Styles.color.contrastBackground;
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" {...props}>
      <path
        d="M9.99984 4.66667H9.33317V3.33333C9.33317 1.49333 7.83984 0 5.99984 0C4.15984 0 2.6665 1.49333 2.6665 3.33333V4.66667H1.99984C1.2665 4.66667 0.666504 5.26667 0.666504 6V12.6667C0.666504 13.4 1.2665 14 1.99984 14H9.99984C10.7332 14 11.3332 13.4 11.3332 12.6667V6C11.3332 5.26667 10.7332 4.66667 9.99984 4.66667ZM5.99984 10.6667C5.2665 10.6667 4.6665 10.0667 4.6665 9.33333C4.6665 8.6 5.2665 8 5.99984 8C6.73317 8 7.33317 8.6 7.33317 9.33333C7.33317 10.0667 6.73317 10.6667 5.99984 10.6667ZM8.0665 4.66667H3.93317V3.33333C3.93317 2.19333 4.85984 1.26667 5.99984 1.26667C7.13984 1.26667 8.0665 2.19333 8.0665 3.33333V4.66667Z"
        fill={fillColor}
      />
    </svg>
  );
};
