import React from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { RadioButton } from '@rbilabs/components-library/build/components/radio-button';
import { useIntl } from 'react-intl';

import { ActionButton } from 'components/action-button';
import { useLocale } from 'state/intl';
import { getNativeLanguageName, getSupportedMarketLanguages } from 'utils/i18n';

import {
  Disclaimer,
  LanguageSelectionCell,
  LanguageSelectionContainer,
  ModalAdditionalContentWrapper,
} from './styled';

interface ILanguageModalContentsProps {
  disclaimer?: string;
  applyButtonText: string;
  onLanguageChange: (selectedLanguage: string) => VoidFunction;
  onSaveClick: VoidFunction;
  langKey: string;
  /**
   * Pass the id of the heading that describes the radio group.
   */
  ariaRadioGroupLabeledBy: string;
}

export const LanguageSelectorModalContents: React.FC<ILanguageModalContentsProps> = ({
  langKey,
  disclaimer,
  applyButtonText,
  onLanguageChange,
  onSaveClick,
  ariaRadioGroupLabeledBy = '',
}) => {
  const { formatMessage } = useIntl();
  const { region } = useLocale();

  const supportedMarketLanguages = getSupportedMarketLanguages();

  const languageSelections = supportedMarketLanguages.map(language => {
    const languageName = getNativeLanguageName(language);

    return (
      <LanguageSelectionCell key={`dialog-button-${language}-${region}`}>
        <RadioButton
          value={`${language}-${region}`}
          data-testid={`dialog-button-${language}-${region.toLowerCase()}`}
          onChange={onLanguageChange(language)}
          checked={langKey === language}
          aria-label={formatMessage({ id: 'selectUiLanguage' }, { languageName })}
          labelHeader={<span lang={language}>{languageName}</span>}
          label={null}
        />
      </LanguageSelectionCell>
    );
  });

  return (
    <>
      <LanguageSelectionContainer role="radiogroup" aria-labelledby={ariaRadioGroupLabeledBy}>
        {languageSelections}
      </LanguageSelectionContainer>
      {disclaimer && (
        <ModalAdditionalContentWrapper>
          <Disclaimer>{disclaimer}</Disclaimer>
        </ModalAdditionalContentWrapper>
      )}
      <Box center width="100%" margin="0 0 2rem">
        <ActionButton onClick={onSaveClick} data-testid="action-button-apply-language-selection">
          {applyButtonText}
        </ActionButton>
      </Box>
    </>
  );
};
