import React, { SVGProps } from 'react';

export const IconLoyaltyPoints = (props: SVGProps<SVGSVGElement>) => (
  <svg data-dir="ltr" width="20" viewBox="0 0 16 16" {...props}>
    <path
      d="M1.99744 7.10528C1.82222 6.29519 2.65194 5.63582 3.40153 5.98947L5.03974 6.76236C5.50741 6.983 6.06615 6.81071 6.32835 6.36502L7.36342 4.60553C7.75403 3.94155 8.71714 3.95001 9.09603 4.62075L10.1029 6.40318C10.352 6.84426 10.8917 7.02934 11.3591 6.83405L13.2357 6.04997C13.9834 5.73758 14.7727 6.39759 14.5976 7.18876L13.6233 11.5911C13.522 12.049 13.116 12.375 12.6469 12.375L3.94413 12.375C3.47332 12.375 3.06628 12.0466 2.96674 11.5864L1.99744 7.10528Z"
      fill="#FFAA00"
    />
  </svg>
);
