import styled, { css } from 'styled-components';

import { IconHeart } from 'components/icons/heart';
import { IconHeartContoured } from 'components/icons/heart-contoured';
import { UnstyledButton } from 'components/unstyled-button';
import { filterProps } from 'utils/filter-props';

export const ButtonForIconFav = styled(UnstyledButton)<{ isTransparent?: boolean }>`
  margin: 0;
  padding: 0;
  line-height: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${p => (p.isTransparent ? 'transparent' : p.theme.token('icon-button-primary'))};
  -webkit-transition: all 0.125s ease;
  transition: all 0.125s ease;
  &:hover {
    background: #dcdcdc;
    -webkit-transition: all 0.125s ease;
    transition: all 0.125s ease;
  }
  &:active {
    box-shadow: 0 0 0 0.25rem #cecece;
  }
`;

const StyleIcon = css`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
`;

export const FavIconHeartFavorite = styled(filterProps(IconHeart, 'isFavorite'))<{
  isFavorite: boolean;
}>`
  fill: ${p =>
    p.isFavorite ? p.theme.token('icon-heart-favorite') : p.theme.token('icon-heart-unfavorite')};
  ${StyleIcon};
`;

export const FavIconHeartUnfavorite = styled(filterProps(IconHeartContoured, 'isFavorite'))<{
  isFavorite: boolean;
}>`
  fill: ${p =>
    p.isFavorite ? p.theme.token('icon-heart-favorite') : p.theme.token('icon-heart-unfavorite')};
  ${StyleIcon};
`;
