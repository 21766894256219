import React from 'react';

import { IconQrCode } from 'components/icons/qr-code';
import { ShortCodeSkeleton } from 'pages/loyalty/loyalty-widgets/loyalty-qr-and-short-code-widget/skeleton';

import { Container, Content, Footer, Title } from './loyalty-qr-and-short-code-widget.styled';

interface IShortCodeErrorProps {
  shortCodeExpirationTitle: string | null | undefined;
}

export const ShortCodeLoading: React.FC<IShortCodeErrorProps> = ({ shortCodeExpirationTitle }) => {
  return (
    <Container data-testid="loyalty-qr-and-short-code-loading">
      <Content>
        <Title>{shortCodeExpirationTitle}</Title>
        <IconQrCode fillOpacity={'0.1'} />
      </Content>
      <Footer>
        <ShortCodeSkeleton />
      </Footer>
    </Container>
  );
};
