import { useCallback, useMemo } from 'react';

import { useEffectOnce } from 'hooks/use-effect-once';
import { useReadyQueue } from 'hooks/use-ready-queue';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isRecentCookieTimestamp } from 'utils/cookies';
import { isMobile, isNative } from 'utils/environment';
import { logger } from 'utils/logger';

export interface IBranchContext {
  initBranch: VoidFunction;
}

export const useBranch = () => {
  const { drainQueue } = useReadyQueue();
  const enableCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER);
  const hasAcceptedCookies = isRecentCookieTimestamp();

  // Journeys Banner should only be loaded for Mobile Browsers (app not installed).
  const isMobileWeb = isMobile() && !isNative;
  const disableJourneysBanner = !isMobileWeb;

  const initBranch = useCallback(() => {
    import('../init')
      .then(({ init }) => {
        init(drainQueue, { disableJourneysBanner });
      })
      .catch(error => {
        logger.error({ error, message: 'Branch: import error' });
      });
  }, [disableJourneysBanner, drainQueue]);

  useEffectOnce(() => {
    if (!enableCookieBanner || hasAcceptedCookies) {
      initBranch();
    }
  });

  const ctxValue: IBranchContext = useMemo(
    () => ({
      initBranch,
    }),
    [initBranch]
  );

  return ctxValue;
};

declare global {
  interface Window {
    branch: any;
  }
}
