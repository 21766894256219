import React, { useCallback, useMemo, useState } from 'react';

import { TertiaryButton } from '@rbilabs/components-library/build/components';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';

import { QRCode } from 'components/qrcode';
import { useFreeRefillDrinks } from 'hooks/use-free-refill-drinks';
import { primitive } from 'styles/constants/primitives';
import { RBIBrand, brand } from 'utils/environment';

import {
  ButtonWrapper,
  Container,
  Content,
  Description,
  IconQRCodeScanner,
  IconRefillDrink,
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalTitle,
  Overlay,
  QRCodeWrapper,
  Title,
  Wrapper,
} from './styled';

type RefillDrinksQRCodeButtonProps = {
  posOrderId?: string | null;
};

export const RefillDrinksQRCodeButton = ({ posOrderId }: RefillDrinksQRCodeButtonProps) => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const {
    isFreeRefillEnabled,
    isFreeRefillRecentOrdersValid,
    qrCodeInfoValue,
    refillDrinksTimers,
  } = useFreeRefillDrinks();
  const isFHSBrand = brand() === RBIBrand.FHS;
  const brandColor = isFHSBrand ? primitive.fhs.$houseNavy : Styles.color.black;

  const minutes = refillDrinksTimers?.timeRecentOrder;

  const handleShowModal = useCallback(() => setShowModal(prevState => !prevState), []);

  const renderModal = useMemo(
    () => (
      <ModalContainer>
        <Overlay onClick={handleShowModal} />
        <Wrapper>
          <ModalContent>
            <ModalTitle isFHSBrand={isFHSBrand}>
              {formatMessage({ id: 'refillYourDrink' })}
            </ModalTitle>
            <ModalDescription isFHSBrand={isFHSBrand}>
              {formatMessage({ id: 'refillQRCodeTipMachine' })}
            </ModalDescription>
          </ModalContent>
          <QRCodeWrapper>
            <QRCode
              barcode={qrCodeInfoValue}
              options={{
                margin: 1,
                scale: 4,
                width: 275,
                color: {
                  dark: brandColor,
                },
              }}
            />
          </QRCodeWrapper>
          <ButtonWrapper>
            <TertiaryButton aria-label="close" onClick={handleShowModal}>
              {formatMessage({ id: 'close' })}
            </TertiaryButton>
          </ButtonWrapper>
        </Wrapper>
      </ModalContainer>
    ),
    [brandColor, formatMessage, handleShowModal, qrCodeInfoValue, isFHSBrand]
  );

  const isQrCodeValid = posOrderId ? qrCodeInfoValue.includes(posOrderId) : !!qrCodeInfoValue;

  if (!isFreeRefillEnabled || !isFreeRefillRecentOrdersValid || !isQrCodeValid) {
    return null;
  }

  return (
    <>
      <Container onClick={handleShowModal}>
        <IconRefillDrink />
        <Content>
          <Title isFHSBrand={isFHSBrand}>{formatMessage({ id: 'refillYourDrink' })}</Title>
          <Description isFHSBrand={isFHSBrand}>
            {formatMessage({ id: 'refillTapToScan' }, { minutes })}
          </Description>
        </Content>
        <IconQRCodeScanner fill={brandColor} aria-hidden />
      </Container>
      {showModal && createPortal(renderModal, document.body)}
    </>
  );
};
