import React, { FC } from 'react';

import { useFeatureEarningCalculationQuery } from 'generated/sanity-graphql';
import { MIN_IN_RESTAURANT_CART_QUANTITY } from 'state/loyalty/in-restaurant-redemption';

import { StyledIncrementor } from './styled';
import { IInRestaurantOfferIncrementorProps } from './types';

export const OfferRedemptionIncrementor: FC<IInRestaurantOfferIncrementorProps> = ({
  onChange,
  quantity,
}) => {
  const { data: earningCalculationData } = useFeatureEarningCalculationQuery({
    variables: { id: 'earningCalculation' },
  });

  const sameOfferLimit =
    earningCalculationData?.EarningCalculation?.offerRedemptionLimits?.sameOfferLimit ??
    MIN_IN_RESTAURANT_CART_QUANTITY;

  const isDisabled = sameOfferLimit === MIN_IN_RESTAURANT_CART_QUANTITY;

  if (isDisabled) {
    return null;
  }

  return (
    <StyledIncrementor
      aria-label={`${quantity}`}
      min={MIN_IN_RESTAURANT_CART_QUANTITY}
      max={sameOfferLimit}
      value={quantity}
      onChange={onChange}
    />
  );
};
