import React, { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { defaultTo, isEqualWith } from 'lodash';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { isFalse } from 'utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import { PreOrderTimeSlot } from '@rbi-ctg/menu';
import { IStore } from '@rbi-ctg/store';
import {
  IRemoveUnavailableItemsModalProps,
  RemoveUnavailableItemsModal,
} from 'components/modal-order-unavailable/remove-unavailable-items-modal';
import { MapProviders } from 'hooks/geolocation/enum';
import { Props as DialogProps, useDialogModal } from 'hooks/use-dialog-modal';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { useMediaQuery } from 'hooks/use-media-query';
import { useServiceModeStatusGenerator } from 'hooks/use-service-mode-status';
import { IAddressPredictions } from 'pages/store-locator/context/types';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { useServiceModeContext } from 'state/service-mode';
import { TLocalizationKey } from 'types/i18n';
import { StorageKeys } from 'utils/local-storage';
import {
  isMobileOrderingAvailable,
  isRestaurantOpen,
  mergeRestaurantData,
  useGetRestaurantFn,
} from 'utils/restaurant';
import { routes } from 'utils/routing';
import { getLocalizedServiceModeCategory, isCatering, isDelivery } from 'utils/service-mode';

import {
  ISelectStoreOptions,
  IStoreContext,
  SelectedStore,
  StorePermissions,
  createStoreProxyFromStore,
  curriedGetStoreStatusV1,
  curriedGetStoreStatusV2,
  findStoreNumberInUrl,
  useStore,
} from './hooks';
import { useStoreUtils } from './hooks/use-store-utils';

export * from './hooks/types';

export const StoreContext = React.createContext<IStoreContext>({} as IStoreContext);
export const useStoreContext = () => useContext(StoreContext);

const styleDialog = (Dialog: ComponentType) => styled(Dialog)`
  background-color: ${Styles.color.white} !important;
  ${Styles.mobile} {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
`;

export const StoreProvider = ({ children }: IBaseProps) => {
  // LD Flags
  const enableOrdering = defaultTo(useFlag(LaunchDarklyFlag.ENABLE_ORDERING), true);
  const enableStoreSelection2_0 = useFlag(LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0);

  const getRestaurant = useGetRestaurantFn();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { location, navigate } = useLocationContext();
  const { serviceMode } = useServiceModeContext();

  const {
    resetStore,
    setStore,
    store,
    storeProxy,
    selectUnavailableStore,
    onConfirmStoreChange,
    updateUserStoreWithCallback,
  } = useStore();

  const [preselectedPreOrderTimeSlot, setPreselectedPreOrderTimeSlot] =
    useLocalStorageState<PreOrderTimeSlot | null>({
      key: StorageKeys.PREORDER_SLOT,
      defaultReturnValue: null,
    });

  const curriedGetStoreStatus = enableStoreSelection2_0
    ? curriedGetStoreStatusV2
    : curriedGetStoreStatusV1;

  const {
    fetchStore,
    email,
    isPricesLoading,
    isRestaurantAvailable,
    prices,
    serviceModeStatus,
    resetLastTimeStoreUpdated,
    restaurantLogAttributesRef,
  } = useStoreUtils({
    resetStore,
    store: storeProxy,
  });

  const isDesktop = useMediaQuery('desktop');

  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const getStoreStatusFlags = useCallback<IStoreContext['getStoreStatusFlags']>(
    (storeToCheck, selectedServiceMode) => {
      if (!storeToCheck) {
        return {
          isStoreOpenAndAvailable: false,
          isStoreOpenAndUnavailable: false,
          isStoreClosed: false,
          noStoreSelected: true,
        };
      }
      const serviceModeStatuses = generateServiceModeStatusForStore(
        createStoreProxyFromStore(storeToCheck, language)
      );
      const status = curriedGetStoreStatus({
        store: storeToCheck,
        serviceModeStatuses,
        selectedServiceMode,
        preselectedPreOrderTimeSlot,
      })();

      return {
        isStoreOpenAndAvailable: status === StorePermissions.OPEN_AND_AVAILABLE,
        isStoreOpenAndUnavailable: status === StorePermissions.OPEN_AND_UNAVAILABLE,
        isStoreClosed: status === StorePermissions.CLOSED,
        noStoreSelected: status === StorePermissions.NO_STORE_SELECTED,
      };
    },
    [
      generateServiceModeStatusForStore,
      curriedGetStoreStatus,
      preselectedPreOrderTimeSlot,
      language,
    ]
  );

  const { isStoreOpenAndAvailable, isStoreOpenAndUnavailable, isStoreClosed, noStoreSelected } =
    useMemo(
      () => getStoreStatusFlags(store, serviceMode),
      [getStoreStatusFlags, store, serviceMode]
    );

  // If flags are enabled, refresh restaurant availability & poll for the selected store's availability to notify the user when the store becomes unavailable.

  const { isRestaurantPosOnline } = useRestaurantPosConnectivityStatus({
    storeId: storeProxy?.number,
  });

  const onConfirmLocateRestaurants = useCallback(() => {
    resetStore();

    if (location.pathname !== routes.storeLocator) {
      navigate(routes.storeLocator);
    }
  }, [location.pathname, navigate, resetStore]);

  const fetchAndSetStore = useCallback(
    async (storeId: string, hasSelection = true) => {
      const fetchedStore = await fetchStore(storeId);

      if (fetchedStore) {
        let formattedStore: SelectedStore | IStore = { ...fetchedStore };

        // add `hasSelection` if in store selection 1.0
        if (!enableStoreSelection2_0) {
          formattedStore = { ...fetchedStore, hasSelection };
        }

        setStore({ ...formattedStore });
      }
    },
    [enableStoreSelection2_0, fetchStore, setStore]
  );

  const [OrderingUnavailableDialog, openOrderingUnavailableDialog] = useDialogModal({
    onConfirm: onConfirmLocateRestaurants,
    onDismiss: resetStore,
    modalAppearanceEventMessage: 'Ordering is unavailable',
  });

  const [StoreChangeDialog, openStoreChangeDialog] = useDialogModal({
    onConfirm: onConfirmStoreChange,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Store change',
  });

  const [ItemsUnavailableDialog, openItemsUnavailableDialog, pendingData, dismissDialog] =
    useDialogModal<
      Pick<ISelectStoreOptions, 'callback' | 'unavailableCartEntries'> & { newStore: IStore },
      IRemoveUnavailableItemsModalProps
    >({
      Component: RemoveUnavailableItemsModal,
      onConfirm: useCallback(
        ({ callback, newStore }: { callback: VoidFunction; newStore: IStore }) => {
          onConfirmStoreChange({ callback, newStore });
        },
        [onConfirmStoreChange]
      ),
    });

  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );
  // ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  const enableDeliveryOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  const [changeStoreMessage, setChangeStoreMessage] = useState(
    formatMessage({ id: 'changeStoreMessage' })
  );

  const [storeAddresses, setStoreAddresses] = useState<IAddressPredictions[]>([]);
  const [addressProvider, setAddressProvider] = useState<MapProviders>(MapProviders.GOOGLE);

  const selectStoreDialog = useCallback(
    async ({
      sanityStore,
      hasCartItems,
      callback,
      requestedServiceMode,
      unavailableCartEntries,
    }: ISelectStoreOptions) => {
      // we do not care about POS if ordering is not enabled
      const rbiRestaurant = await getRestaurant(sanityStore.number);
      const isRestaurantPosAvailable = rbiRestaurant?.available;

      // Operating hours now come from our gql layer so we must merge the results
      // with the sanity store.
      // Default to the passed in sanity store if we cannot get a restaurant from
      // the gql layer.
      const newStore = rbiRestaurant
        ? mergeRestaurantData({ rbiRestaurant, sanityStore })
        : sanityStore;

      const selectedServiceMode = requestedServiceMode ?? serviceMode;
      const open =
        isCatering(selectedServiceMode) ||
        (isDelivery(selectedServiceMode) &&
          (isRestaurantOpen(newStore.deliveryHours) || enableDeliveryOutsideOpeningHours)) ||
        isRestaurantOpen(newStore.diningRoomHours) ||
        isRestaurantOpen(newStore.driveThruHours);

      // ensure store did not close between render and selection
      if (!open) {
        return openOrderingUnavailableDialog();
      }

      if (
        enableOrdering &&
        // has posData, but heartbeat is false
        (!isRestaurantPosAvailable ||
          // store has no mobile ordering available
          !isMobileOrderingAvailable(newStore, isAlphaBetaStoreOrderingEnabled))
      ) {
        return openOrderingUnavailableDialog({
          message: formatMessage({
            id: 'storeNoOrderingBody',
          }),
          title: formatMessage({
            id: 'storeNoOrderingHeading',
          }),
        });
      }

      if (isStoreOpenAndUnavailable) {
        return onConfirmStoreChange({ newStore, callback });
      }

      if (isEqualWith(storeProxy.physicalAddress, newStore.physicalAddress)) {
        return callback();
      }

      if (!hasCartItems) {
        return onConfirmStoreChange({
          newStore,
          callback,
        });
      }
      if (unavailableCartEntries?.length) {
        return openItemsUnavailableDialog({
          callback,
          newStore,
          unavailableCartEntries,
        });
      }

      const bodyMessageId: TLocalizationKey = 'changeStoreItemDisclaimer';

      setChangeStoreMessage(
        formatMessage(
          { id: bodyMessageId },
          {
            serviceMode:
              selectedServiceMode &&
              getLocalizedServiceModeCategory(selectedServiceMode, formatMessage),
          }
        )
      );

      openStoreChangeDialog({
        newStore,
        callback,
      });
    },
    [
      enableDeliveryOutsideOpeningHours,
      enableOrdering,
      formatMessage,
      getRestaurant,
      isAlphaBetaStoreOrderingEnabled,
      isStoreOpenAndUnavailable,
      onConfirmStoreChange,
      openItemsUnavailableDialog,
      openOrderingUnavailableDialog,
      openStoreChangeDialog,
      serviceMode,
      storeProxy.physicalAddress,
    ]
  );

  const storeNumber = findStoreNumberInUrl(location.search);

  useEffect(() => {
    if (storeNumber && storeNumber !== (store as IStore)?.number) {
      fetchAndSetStore(storeNumber);
    }
  }, [fetchAndSetStore, store, storeNumber]);

  useEffectOnce(() => {
    // Fetch store on initial render if a store is selected
    if (!storeNumber && store && 'number' in store && store.number !== null) {
      const storeHasSelection = !!store && 'hasSelection' in store && store.hasSelection;
      fetchAndSetStore(store.number, storeHasSelection);
    }
  });

  useEffect(() => {
    // We need to explicitly check for false since a restaurant's availability always begins as null when refreshing/logging out.
    if (!noStoreSelected && isStoreOpenAndAvailable && isFalse(isRestaurantPosOnline)) {
      return openOrderingUnavailableDialog();
    }
  }, [
    isRestaurantPosOnline,
    isStoreOpenAndAvailable,
    noStoreSelected,
    openOrderingUnavailableDialog,
  ]);

  const StyledOrderingUnavailableDialog = styleDialog(OrderingUnavailableDialog) as ComponentType<
    DialogProps<{}>
  >;

  const value = useMemo(
    () => ({
      email,
      isPricesLoading,
      isRestaurantAvailable,
      openOrderingUnavailableDialog,
      prices,
      selectStore: selectStoreDialog,
      fetchStore: fetchAndSetStore,
      selectUnavailableStore,
      serviceModeStatus,
      setStore,
      resetStore,
      resetLastTimeStoreUpdated,
      getStoreStatusFlags,
      isStoreOpenAndAvailable,
      isStoreOpenAndUnavailable,
      isStoreClosed,
      noStoreSelected,
      store: storeProxy,
      updateUserStoreWithCallback,
      storeAddresses,
      setStoreAddresses,
      addressProvider,
      setAddressProvider,
      restaurantLogAttributesRef,
      preselectedPreOrderTimeSlot,
      setPreselectedPreOrderTimeSlot,
    }),
    [
      email,
      isPricesLoading,
      isRestaurantAvailable,
      openOrderingUnavailableDialog,
      prices,
      selectStoreDialog,
      fetchAndSetStore,
      selectUnavailableStore,
      serviceModeStatus,
      setStore,
      resetStore,
      resetLastTimeStoreUpdated,
      getStoreStatusFlags,
      isStoreOpenAndAvailable,
      isStoreOpenAndUnavailable,
      isStoreClosed,
      noStoreSelected,
      storeProxy,
      updateUserStoreWithCallback,
      storeAddresses,
      addressProvider,
      setAddressProvider,
      restaurantLogAttributesRef,
      preselectedPreOrderTimeSlot,
      setPreselectedPreOrderTimeSlot,
    ]
  );

  return (
    <StoreContext.Provider value={value}>
      {children}

      <StoreChangeDialog
        heading={formatMessage({ id: 'changeStores' })}
        body={changeStoreMessage}
      />

      <StyledOrderingUnavailableDialog
        body={formatMessage({
          id: enableOrdering ? 'cannotPlaceOrder' : 'closeMessageToFindNearbyRestaurants',
        })}
        buttonLabel={formatMessage({ id: 'findRestaurants' })}
        heading={formatMessage({ id: 'storeClosed' })}
        image={
          <Box show={isDesktop} margin="2rem 17rem 1rem">
            <Icon icon="sadFace" color="primary" width="6.125rem" height="6.125rem" aria-hidden />
          </Box>
        }
      />
      <ItemsUnavailableDialog
        unavailableItems={pendingData?.unavailableCartEntries || []}
        onDismiss={dismissDialog}
      />
    </StoreContext.Provider>
  );
};

export const StoreContextConsumer = StoreContext.Consumer;
