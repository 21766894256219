import * as React from 'react';
import { FC, useMemo } from 'react';

import {
  ISerializers,
  ITypographyBlockOverrides,
  PassthroughComponent,
  SanityBlockRenderer,
} from 'components/sanity-block-renderer';

import {
  Body1,
  Body2,
  Caption,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  Subtitle1,
  Subtitle2,
} from './styles';
import { HorizontalPosition, ITypographyBlockProps } from './types';

/**
 * TypographyBlock is a simplified Sanity Content Block
 * It only allows Typography, no images, list or links
 */
export const TypographyBlock: FC<ITypographyBlockProps> = ({
  content,
  color = Styles.color.black,
  horizontalPosition = HorizontalPosition.LEFT,
}) => {
  const pointBlockContentOverrides: ITypographyBlockOverrides = useMemo(
    () => ({
      headline1: ({ children }) => (
        <Headline1 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Headline1>
      ),
      headline2: ({ children }) => (
        <Headline2 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Headline2>
      ),
      headline3: ({ children }) => (
        <Headline3 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Headline3>
      ),
      headline4: ({ children }) => (
        <Headline4 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Headline4>
      ),
      headline5: ({ children }) => (
        <Headline5 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Headline5>
      ),
      headline6: ({ children }) => (
        <Headline6 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Headline6>
      ),
      subtitle1: ({ children }) => (
        <Subtitle1 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Subtitle1>
      ),
      subtitle2: ({ children }) => (
        <Subtitle2 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Subtitle2>
      ),
      normal: ({ children }) => (
        <Body1 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Body1>
      ),
      body1: ({ children }) => (
        <Body1 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Body1>
      ),
      body2: ({ children }) => (
        <Body2 color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Body2>
      ),
      caption: ({ children }) => (
        <Caption color={color} horizontalPosition={horizontalPosition}>
          {children}
        </Caption>
      ),
    }),
    [color, horizontalPosition]
  );

  const pointBlockSerializers: ISerializers = {
    marks: {},
    container: PassthroughComponent,
  };
  return (
    <SanityBlockRenderer
      blockContentOverrides={pointBlockContentOverrides}
      serializers={pointBlockSerializers}
      content={content}
    />
  );
};
