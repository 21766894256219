import { useMemo } from 'react';

import { AllowedEvent } from '@rbilabs/intl-mparticle-client';
import { useIntl } from 'react-intl';

import { useEffectOnce } from 'hooks/use-effect-once';
import { useFormatCalories } from 'hooks/use-format-calories';
import { useFormatJoules } from 'hooks/use-format-joules';
import { useCdpContext } from 'state/cdp';
import { EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useUIContext } from 'state/ui';

import { ICartItemProps } from './types';

const PRICE_DIVIDE = 100;

export const useCartItem = ({
  item,
  logEventData,
  hasUniqueEvent = false,
}: Omit<ICartItemProps, 'addToCard'>) => {
  const { logEventName } = logEventData;
  const cdp = useCdpContext();
  const { formatMessage } = useIntl();
  const formatCalories = useFormatCalories();
  const { pricingFunction } = useMenuContext();
  const { formatCurrencyForLocale } = useUIContext();
  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);
  const formatJoules = useFormatJoules();
  const itemCalories = item.nutrition?.calories;

  const price = useMemo(
    () => formatCurrencyForLocale(pricingFunction(item)),
    [item, pricingFunction, formatCurrencyForLocale]
  );

  const caloriesInJoules = formatJoules(itemCalories);

  const calories = useMemo(
    () => formatCalories(itemCalories, 'calories'),
    [formatCalories, itemCalories]
  );

  const euFormattedNutritionText = formatMessage(
    { id: 'euNutritionValues' },
    { calories, joules: caloriesInJoules }
  );

  const globalizedCalorieFormat = calories ? `${euFormattedNutritionText}` : '';
  const finalCalories = enableEUNutritionDisplay ? globalizedCalorieFormat : calories;

  useEffectOnce(() => {
    if (!hasUniqueEvent) {
      const eventData = {
        name: logEventName,
        type: EventTypes.Other,
        attributes: {
          name: item.name?.locale,
          sanityId: item._id,
          price: pricingFunction(item) / PRICE_DIVIDE,
        },
      } as AllowedEvent;

      cdp.trackEvent(eventData);
    }
  });

  return {
    price,
    finalCalories,
    itemCalories: formatCalories(item.nutrition?.calories),
  };
};
