import React from 'react';

export const IconCircleTimes = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <title>Circle Times Icon</title>
    <path d="M10 0a10 10 0 0 1 10 10 10 10 0 0 1-10 10A10 10 0 0 1 0 10 10 10 0 0 1 10 0z"></path>
    <path
      d="M14 9.2h-3.22V6A.79.79 0 0 0 9.2 6v3.2H6a.79.79 0 1 0 0 1.58h3.2V14a.79.79 0 1 0 1.58 0v-3.22H14a.79.79 0 0 0 0-1.58z"
      transform="rotate(45 10, 10) scale(1.3, 1.3) translate(-2, -2)"
      fill={Styles.color.white}
    ></path>
  </svg>
);
