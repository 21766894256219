import { from } from '@apollo/client';

import { httpLink } from './http-link';
import { initContext } from './init-context';
import { stripTypenameLink } from './strip-typename';
import { withErrorLogger } from './with-error-logger';
import { withHeaders } from './with-headers';

export const getConfiguredLink = (staticContext: Record<string, unknown>) => {
  return from([
    initContext(staticContext),
    withErrorLogger,
    withHeaders({ 'Content-Type': 'application/json' }),
    stripTypenameLink,
    httpLink,
  ]);
};
