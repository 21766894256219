import { themeBase } from './theme-base';

export const theme = {
  ...themeBase,

  delivery: {
    ...themeBase.delivery,
    icon: 'delivery',
  },

  catering: {
    delivery: {
      ...themeBase.catering.delivery,
      icon: 'delivery',
    },
    pickup: {
      ...themeBase.catering.pickup,
    },
  },

  badge: {
    color: Styles.color.tertiary,
  },
};
