import { DistanceWarningModal } from 'components/modal-distance-warning';
import { IDistanceWarningModalProps } from 'components/modal-distance-warning/modal-distance-warning';
import { useGetUserOrdersLazyQuery as useGetUserOrdersLazyQueryFulfillment } from 'generated/graphql-gateway';
import { RbiOrderStatus, useGetUserOrdersLazyQuery } from 'generated/rbi-graphql';
import { useDialogModal } from 'hooks/use-dialog-modal';
import { useGeolocation } from 'state/geolocation';
import { useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/order';
import { isLocationFarFromThreshold, isLocationTheSame } from 'utils/distance/distance';
import { Coordinates } from 'utils/geolocation/types';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { PICKUP_SERVICE_MODES } from 'utils/service-mode';

const DISTANCE_WARNING_THRESHOLD_METERS = 1000;

interface IUseDistanceWarning {
  isMobileOrdering?: boolean;
}

export const useDistanceWarning = ({ isMobileOrdering = false }: IUseDistanceWarning = {}) => {
  const { isPermissionGranted, userCoordinates } = useGeolocation();

  const [ConfirmLocationModal, openConfirmationModal, , dismissDialog] = useDialogModal<
    {},
    IDistanceWarningModalProps
  >({
    modalAppearanceEventMessage: '',
    showActionsButton: false,
    Component: DistanceWarningModal,
  });

  const isFulfillmentServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_GET_USER_ORDERS
  );

  const variables = {
    serviceModes: isMobileOrdering ? PICKUP_SERVICE_MODES : [ServiceMode.DELIVERY],
    orderStatuses: [
      RbiOrderStatus.INSERT_SUCCESSFUL,
      RbiOrderStatus.UPDATE_SUCCESSFUL,
      RbiOrderStatus.REFUND_SUCCESSFUL,
    ],
  };

  const [getOrderFulfillment, { loading: loadingGetOrderFulfillment }] =
    useGetUserOrdersLazyQueryFulfillment({
      variables,
    });
  const [getOrder, { loading: loadingGetOrder }] = useGetUserOrdersLazyQuery({ variables });

  const isLoading = loadingGetOrder || loadingGetOrderFulfillment;

  const fetchLastOrderAddress = async () => {
    const { data } = isFulfillmentServiceEnabled ? await getOrderFulfillment() : await getOrder();
    const lastOrder = isMobileOrdering
      ? data?.userOrders?.orders?.[0]?.cart.storeDetails
      : data?.userOrders?.orders?.[0]?.delivery?.dropoff;
    const hasCoordinates =
      typeof lastOrder?.latitude === 'number' && typeof lastOrder?.longitude === 'number';

    if (!lastOrder || !hasCoordinates) {
      return null;
    }

    return {
      lat: lastOrder.latitude,
      lng: lastOrder.longitude,
    };
  };

  const isLocationConfirmationNeeded = async (
    selectedCoordinates: Coordinates
  ): Promise<boolean> => {
    if (isPermissionGranted && userCoordinates) {
      return isLocationFarFromThreshold(
        userCoordinates,
        selectedCoordinates,
        DISTANCE_WARNING_THRESHOLD_METERS
      );
    }

    const lastOrderAddress = await fetchLastOrderAddress();
    if (!lastOrderAddress) {
      return false;
    }

    const isDifferentLocation = !isLocationTheSame(selectedCoordinates, lastOrderAddress);
    return isDifferentLocation;
  };

  return {
    isLocationConfirmationNeeded,
    openConfirmationModal,
    dismissDialog,
    ConfirmLocationModal,
    isLoading,
    isGeolocationEnabled: isPermissionGranted,
  };
};
