import { HandlerType, datadogLogs } from '@datadog/browser-logs';

import {
  appVersionCode,
  brand,
  env,
  getConfigValue,
  isLocalDev,
  platform,
} from 'utils/environment';
import { logger } from 'utils/logger';

import { DEFAULT_SITE } from './constants';
import { Context } from './types';
import { createLogsBeforeSendCallback } from './utils';

export { StatusType } from '@datadog/browser-logs';

export const getHandler = (isLocalDev: boolean): HandlerType =>
  isLocalDev ? HandlerType.silent : HandlerType.http;

export const init = (
  context: Context,
  configuredSampleRate?: number,
  ddSampleRateMparticleEvents?: number
) => {
  const defaultRate = isLocalDev ? 100 : 1;
  const sampleRate = typeof configuredSampleRate === 'number' ? configuredSampleRate : defaultRate;
  const sampleRateMParticle =
    typeof ddSampleRateMparticleEvents === 'number' ? ddSampleRateMparticleEvents : sampleRate;

  const logsConfig = getConfigValue('datadog').logs;
  const clientToken = logsConfig?.clientToken;
  const site = logsConfig?.dataCenter ?? DEFAULT_SITE;

  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    env: env(),
    service: `${brand()}_${platform()}`,
    version: appVersionCode(),
    beforeSend: createLogsBeforeSendCallback(sampleRate, sampleRateMParticle),
  });

  logger.setContext(context);

  datadogLogs.logger.setHandler(getHandler(isLocalDev));
};
