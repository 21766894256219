import React from 'react';

import { useIntl } from 'react-intl';
import { PointsDisplay } from 'ui-system/components/points-display';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';
import { useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { StyledLoyaltyButton } from './styled';

export const LoyaltyPointsButton = () => {
  const enableLoyaltyPointsInHeader = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_POINTS_IN_HEADER);
  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const showLoyaltyCta =
    enableLoyaltyPointsInHeader && !loyaltyLoading && loyaltyEnabled && loyaltyUser;
  const { featureLoyaltyUi } = useFeatureLoyaltyUi();
  const loyaltyPath =
    featureLoyaltyUi?.loyaltyCTAButtons?.loyaltyBannerHomepageAuthenticatedButton
      ?.loyaltyBannerHomepageAuthenticatedLink?.locale ||
    formatMessage({ id: 'routes.loyaltyDashboard' });

  return showLoyaltyCta ? (
    <StyledLoyaltyButton
      variant={ActionButtonVariants.OUTLINE}
      size={ActionButtonSizes.SMALL}
      data-testid="app-header-loyalty-cta"
      to={loyaltyPath}
    >
      <PointsDisplay value={loyaltyUser?.points} />
    </StyledLoyaltyButton>
  ) : null;
};
