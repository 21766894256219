import { getConfigValue } from 'utils/environment';

export { buildImageUrl } from './build-image-url';

interface IGetSanityUrl {
  sanityDataset: string;
  useCdn?: boolean;
}

const getSanityBaseUrl = (useCdn: boolean = true) =>
  `https://${getConfigValue('sanityProjectId')}.api${useCdn ? 'cdn' : ''}.sanity.io/v2023-08-01`;

export const getSanityGroqQueryUrl = ({ sanityDataset, useCdn }: IGetSanityUrl) =>
  `${getSanityBaseUrl(useCdn)}/data/query/${sanityDataset}`;

export const getSanityGraphqlQueryUrl = ({ sanityDataset, useCdn }: IGetSanityUrl) =>
  `${getSanityBaseUrl(useCdn)}/graphql/${sanityDataset}/gen3`;
