import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';

export const SubheaderContainer = styled.div<IBaseProps>`
  background: ${Styles.color.white};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: ${Styles.color.grey.three};
  font-family: ${Styles.fontFamily.body};
  font-size: 0.75rem;
  font-weight: ${Styles.fontWeight.heavy};
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-block-start: 1px;

  display: flex;
  justify-content: center;

  padding: 0 2rem;

  ${Styles.desktopLarge} {
    justify-content: space-between;
    padding-inline-start: 3.5rem;
    padding-inline-end: 3rem;
  }

  & div:first-of-type {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  & h3 {
    font-size: 0.75rem;
    font-weight: heavy;
    line-height: 32px;
    height: 32px;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & a {
    font-weight: inherit;
  }

  & a:link,
  & a:visited,
  & a:focus,
  & a:hover,
  & a:active {
    color: ${Styles.color.primary};
    margin-inline-start: 1rem;
    text-decoration: none;
  }

  & a:hover {
    color: #b4611b;
  }

  ${Styles.desktopLarge} {
    font-size: 0.9375rem;

    & div:first-of-type {
      width: unset;
    }

    & h3 {
      font-size: 0.9375rem;
    }
  }
`;
