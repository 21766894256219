import { camelCase } from 'lodash';

import { Nullable } from '@rbi-ctg/frontend';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';
import { StoreProxy, useStoreContext } from 'state/store';
import { PosVendors } from 'utils/vendor-config';

interface IUsePosVendor {
  vendor: PosVendors | null;
}

export const getPosVendorFromStore = (
  store: StoreProxy | IRestaurantNode,
  serviceMode: Nullable<ServiceMode> = null
): PosVendors | null => {
  if (!store?.pos?.vendor) {
    return null;
  }

  const vendor = camelCase(store.pos.vendor);
  return (serviceMode === ServiceMode.DELIVERY ? vendor.concat('Delivery') : vendor) as PosVendors;
};

export const usePosVendor = (): IUsePosVendor => {
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  return {
    vendor: getPosVendorFromStore(store, serviceMode),
  };
};
