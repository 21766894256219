import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { primitive } from 'styles/constants/primitives';

import { SkeletonBox, SkeletonContainer } from './loyalty-qr-and-short-code-widget.styled';

export const ShortCodeSkeleton = () => {
  return (
    <SkeletonContainer>
      <SkeletonTheme
        baseColor={primitive.$blackOpacity10}
        highlightColor={primitive.$blackOpacity4}
      >
        <Skeleton wrapper={SkeletonBox} height="10px" width="100%" />
        <Skeleton wrapper={SkeletonBox} height="20px" width="70%" />
      </SkeletonTheme>
    </SkeletonContainer>
  );
};
