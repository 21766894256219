import React, { ComponentType } from 'react';

import { catchPromise, compose } from 'utils';

import { ErrorNetwork } from 'components/error-network';

export type LazyRoute = React.LazyExoticComponent<ComponentType<any>>;
export type LazyComponent<P> = React.LazyExoticComponent<ComponentType<P>>;

// used with route components
export const lazyWithFallback = compose(
  React.lazy,
  catchPromise<Error, { default: ComponentType<any> }, { default: ComponentType<any> }>(() => ({
    default: () => <ErrorNetwork />,
  }))
);

// used with non-route components
export const lazyWithNullFallback = compose(
  React.lazy,
  catchPromise<Error, { default: ComponentType<any> }, { default: ComponentType<any> }>(() => ({
    default: () => null,
  }))
);
