import styled from 'styled-components';

import { Link } from 'components/link';

// TODO(ICFE-1173): use components/link directly after it uses react-router-dom
export const StyledLink = styled(Link)`
  display: inline-flex;
  white-space: nowrap;
  color: ${p => p.theme.token('text-default')};
  font-family: ${Styles.fontFamily.base};
  font-size: 1.125rem;
  margin: 0;

  &:hover {
    text-decoration: none;
    color: ${Styles.color.navLinkColor.active};
    font-weight: bold;
  }

  &.selected,
  &.selected:hover {
    color: ${Styles.color.navLinkColor.active};
    font-weight: bold;
  }
`;
