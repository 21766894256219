import React, { FC, ReactNode, createContext, useContext, useMemo } from 'react';

import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useStoreContext } from 'state/store';

import { IValidDayPart, isValidDayPart, useActiveDayParts } from './hooks/use-active-day-parts';

export interface IDayPartCtx {
  activeDayParts: ReturnType<typeof useActiveDayParts>;
  dayParts: ReadonlyArray<IValidDayPart>;
}

export const DayPartContext = createContext<IDayPartCtx>({ activeDayParts: [], dayParts: [] });

export const useDayPartContext = () => useContext(DayPartContext);

export const DayPartProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { featureMenu } = useFeatureMenu();
  const { store } = useStoreContext();
  const dayParts = useMemo(
    () => featureMenu?.dayParts?.filter(isValidDayPart) || [],
    [featureMenu]
  );

  const activeDayParts = useActiveDayParts({ dayParts, store });

  return (
    <DayPartContext.Provider value={{ activeDayParts, dayParts }}>
      {children}
    </DayPartContext.Provider>
  );
};
