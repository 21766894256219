import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';

interface ILimitMessageProps {
  limit: number;
  totalAmount?: number;
}

const MutedText = styled.div`
  text-align: center;
  color: ${Styles.color.grey.one};
  font-size: 0.8rem;
`;

export const LimitMessage: React.FC<ILimitMessageProps> = ({ limit, totalAmount }) => {
  const checkoutCashLimit = Number(useFlag(LaunchDarklyFlag.CHECKOUT_CASH_PRICE_LIMIT));
  const checkoutLimit = Number(useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT));
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const { isCatering, checkoutCateringPriceLimit } = useOrderContext();
  const isCashLimitExceed = Number(totalAmount) > checkoutCashLimit;
  const isCheckoutLimitExceed = Number(totalAmount) > checkoutLimit;

  return (
    <MutedText>
      {checkoutCashLimit > 0
        ? isCheckoutLimitExceed
          ? formatMessage(
              { id: 'orderLimitWarningX' },
              {
                limit: formatCurrencyForLocale(isCatering ? checkoutCateringPriceLimit : limit),
              }
            )
          : isCashLimitExceed &&
            formatMessage(
              { id: 'orderLimitWarningCash' },
              {
                limit: formatCurrencyForLocale(checkoutCashLimit),
              }
            )
        : formatMessage(
            { id: 'orderLimitWarningX' },
            {
              limit: formatCurrencyForLocale(isCatering ? checkoutCateringPriceLimit : limit),
            }
          )}
    </MutedText>
  );
};
