import React, { ReactNode, useState } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';

import { StyledActionButton, SelectionsList as StyledSelectionsList } from './product.styled';

interface SelectionsListProps {
  selections: { id: string; element: ReactNode }[];
  id: string;
  expandable: boolean;
}

// length of selections to show before truncating list
const MAX_SELECTIONS = 2;

export const SelectionsList: React.FC<SelectionsListProps> = ({ selections, id, expandable }) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldShowExpandToggle = expandable && selections.length > MAX_SELECTIONS;
  const selectionsListLength = isExpanded ? selections.length : MAX_SELECTIONS;

  const remainingSelections = selections.length - selectionsListLength;

  const handleClick = (e: React.SyntheticEvent) => {
    if (expandable) {
      e.stopPropagation();
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <>
      <StyledSelectionsList
        role="region"
        aria-labelledby={`load-more-modifiers-${id}`}
        id={`modifiers-list-${id}`}
      >
        {selections.slice(0, selectionsListLength).map(selection => (
          <li key={selection.id}>{selection.element}</li>
        ))}
        {!shouldShowExpandToggle &&
          remainingSelections > 0 &&
          formatMessage({ id: 'amountMore' }, { amountMore: remainingSelections })}
      </StyledSelectionsList>
      {shouldShowExpandToggle && (
        <StyledActionButton
          id={`load-more-modifiers-${id}`}
          aria-controls={`modifiers-list-${id}`}
          variant={ActionButtonVariants.TEXT_ONLY}
          onClick={handleClick}
          aria-expanded={isExpanded}
        >
          <VisuallyHidden>{formatMessage({ id: 'itemModifiers' })}</VisuallyHidden>
          {isExpanded ? formatMessage({ id: 'showLess' }) : formatMessage({ id: 'showMore' })}
        </StyledActionButton>
      )}
    </>
  );
};
