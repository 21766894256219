import React from 'react';

import { Chip } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IBaseItem, ICartEntry } from '@rbi-ctg/menu';
import { primitive } from 'styles/constants/primitives';

import { UpsellItem } from './upsell-item';
import { UpsellModalAddToCart } from './upsell-modal-add-to-cart';
import { UpsellModalQuantityChange } from './upsell-modal-quantity-change';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1.5rem;
  padding-block-end: 6rem;
`;

const StyledChip = styled(Chip)`
  position: absolute;
  inset-block-start: 0.5rem;
  inset-inline-start: 0.5rem;

  height: 1.5rem;
  padding-inline: 0.5rem;

  color: ${primitive.$success};
  background: ${primitive.$successBg};

  > p {
    margin-block-start: 0.875rem;

    ${Styles.mobile} {
      margin-block-start: 0.75rem;
    }

    ${Styles.desktopLarge} {
      margin-block-start: 1rem;
    }
  }

  & > div {
    background-color: ${primitive.$success};
    border-radius: 50%;
    padding: 1px;
    margin-inline-end: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      fill: ${primitive.$successBg};
    }
  }
`;

interface UpsellModalContentProps {
  upsellItems: IBaseItem[];
  cartEntries: ICartEntry[];
  onAddItem: (item: IBaseItem) => void;
}

export const UpsellModalContent: React.FC<UpsellModalContentProps> = ({
  cartEntries,
  upsellItems,
  onAddItem,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      {upsellItems.map((item, index) => {
        const cartItem = cartEntries.find(c => c._id === item._id);
        const isAdded = Boolean(cartItem);

        return (
          <UpsellItem
            flow="menu"
            item={item}
            key={item._id}
            index={index}
            isAdded={isAdded}
            chipComponent={
              cartItem && (
                <StyledChip icon="check" aria-label="Upsell item added">
                  {formatMessage({ id: 'addedChip' })}
                </StyledChip>
              )
            }
            actions={
              cartItem ? (
                <UpsellModalQuantityChange item={cartItem} />
              ) : (
                <UpsellModalAddToCart onClick={() => onAddItem(item)} />
              )
            }
          />
        );
      })}
    </Container>
  );
};
