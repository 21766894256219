import {
  ImageUrlBuilderOptionsWithAliases,
  SanityImageSource,
} from '@sanity/image-url/lib/types/types';

import { ImageBuilderType } from 'state/ui';

export const buildImageUrl = (
  imageUrlBuilder: ImageBuilderType,
  source: SanityImageSource,
  options: Partial<ImageUrlBuilderOptionsWithAliases> = {}
) => {
  return imageUrlBuilder.image(source).withOptions(options).url() || '';
};

export const parseAssetID = (assetID: string = '') => {
  const [, id, dimensions, format] = assetID.split('-');
  if (!(id && dimensions && format)) {
    return {
      id: '',
      format: '',
      dimensions: {
        aspectRatio: 0,
        width: 0,
        height: 0,
      },
    };
  }
  const [width = 0, height = 0] = dimensions?.split('x').map(str => parseInt(str, 10));
  return {
    id,
    format,
    dimensions: {
      aspectRatio: width / height,
      width,
      height,
    },
  };
};
