import { DEFAULT_HANDLERS, DEFAULT_MIDDLEWARES } from './constants';
import { globalContext } from './context';
import { createLogger } from './logger';

export const defaultLogger = createLogger(globalContext, {
  middlewares: DEFAULT_MIDDLEWARES,
  handlers: DEFAULT_HANDLERS,
});

export const logger = defaultLogger;

export { globalContext } from './context';
export { type ILogger, LogLevel, type TContext } from './types';
