import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconLoyaltyLogo } from 'components/icons/loyalty-logo';
import { Picture } from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';

const PictureContainer = styled.div`
  min-width: 8.75rem;
`;

const DefaultLoyaltyLogo = styled(IconLoyaltyLogo)`
  width: 13rem;
  height: 4rem;
`;

export interface ILoyaltyRewardsLogo {
  inverted?: boolean;
}

export const LoyaltyRewardsLogo = ({ inverted, ...props }: ILoyaltyRewardsLogo) => {
  const { featureLoyaltyUiLoading, featureLoyaltyUi } = useFeatureLoyaltyUi();
  const { formatMessage } = useIntl();

  if (featureLoyaltyUiLoading) {
    return null;
  }

  // If custom logo hasn't been uploaded in Sanity, use default one
  if (!featureLoyaltyUi?.rewardsLogo?.locale && !featureLoyaltyUi?.rewardsLogoInverted?.locale) {
    return <DefaultLoyaltyLogo />;
  }

  return (
    <PictureContainer data-testid="bk-rewards-logo" {...props}>
      <Picture
        image={
          inverted
            ? featureLoyaltyUi?.rewardsLogoInverted?.locale
            : featureLoyaltyUi?.rewardsLogo?.locale
        }
        alt={formatMessage({ id: 'homepage' })}
        objectFitContain
      />
    </PictureContainer>
  );
};
