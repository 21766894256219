import React, { FC } from 'react';

import { Product } from 'components/product';

import { Container } from './container';
import { ProductControl } from './product-list-item.styled';
import { IProductListItemProps } from './types';

/**
 * ProductListItem is a component used to display a product in a given list view.
 * It is a container to display a <Product /> component on the left,
 * AND an optional action on the right which could be either -
 * modifier style, favorite/add, carrot to view more.
 */
export const ProductListItem: FC<IProductListItemProps> = ({
  tabIndex,
  controls,
  isClickable,
  onProductClick,
  productProps,
}) => {
  return (
    <Container tabIndex={tabIndex} isClickable={isClickable} onProductClick={onProductClick}>
      <Product {...productProps} />
      {controls && <ProductControl>{controls}</ProductControl>}
    </Container>
  );
};
