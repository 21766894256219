import React, { Suspense } from 'react';

import { OptionalColorProps } from 'components/language-selector';
import { LazyComponent, lazyWithNullFallback } from 'components/layout/util';

const LanguageSelectorImpl: LazyComponent<OptionalColorProps> = lazyWithNullFallback(async () => ({
  default: (await import('components/language-selector')).LanguageSelector,
}));

export const SubheaderLanguageSelector: React.FC = () => {
  return (
    <Suspense fallback={null}>
      <LanguageSelectorImpl color={Styles.color.grey.two} />
    </Suspense>
  );
};
