import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { Picture } from 'components/picture';
import { LoyaltyRewardsLogo } from 'pages/loyalty/loyalty-rewards-logo';

export const Header = styled.div`
  width: 100%;
  padding-block-start: 3rem;
  padding-block-end: 1rem;
  padding-inline: 1rem;
  text-align: center;
`;

export const StyledRewardsLogo = styled(LoyaltyRewardsLogo)`
  width: 10rem;
  margin: 0 auto;
`;

export const TitleDescription = styled.div`
  text-align: center;
  margin-block-start: 1rem;
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;

  ${Styles.desktop} {
    max-width: 50rem;
    margin: 0 auto;
  }
`;

export const Tile = styled.div`
  display: flex;
  width: 100%;
  padding: 1.2rem;
  background: #fdf2e2;
  border-radius: 1.3rem;
  margin: 1.5rem 0;
`;

export const StyledPicture = styled(Picture)`
  max-width: 6rem;
  border-radius: 1rem;
  margin: auto 0;

  ${Styles.mobileTiny} {
    max-width: 4rem;
  }
`;

export const CellDetails = styled.div`
  width: 75%;
  padding-inline-start: 1rem;
  margin: auto 0;
`;

export const CellTitle = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
  padding-block-end: 0.2rem;
`;

export const CellDescription = styled.div`
  font-size: 0.9rem;
`;

export const SignupButton = styled(ActionButton)`
  width: 100%;
  font-size: 1rem;
`;

export const Disclaimer = styled.div`
  width: 100%;
  padding: 2rem;
  line-height: 0.875rem;
  font-size: 0.84rem;

  ${Styles.desktop} {
    max-width: 50rem;
    margin: 0 auto;
  }
`;
