import * as React from 'react';

import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ActionButton } from 'components/action-button';
import { ILocaleStringFragment } from 'generated/sanity-graphql';
import { useMaximumScreenBrightness } from 'hooks/use-maximum-screen-brightness';
import {
  QRCodeContainer,
  QRCodeSubtitle,
  QRCodeTitle,
  QRCodeWrapper,
  StyledQRCode,
  StyledRefreshIcon,
} from 'pages/loyalty/loyalty-in-restaurant-redemption/loyalty-in-restaurant-redemption.styled';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { primitive } from 'styles/constants/primitives';
import { PerformancePages, performanceReport, trackComponent } from 'utils/render-performance';

import { RewardRedemptionListMemo } from '../../loyalty-in-restaurant-redemption/reward-redemption-list';

import { QR_CODE_SCALE, QR_CODE_SELECTOR, QR_CODE_TEST_ID } from './constants';

export interface ILoyaltyQrWidgetProps extends IBaseProps {
  title: ILocaleStringFragment;
  subtitle: ILocaleStringFragment;
  expirationMessage: ILocaleStringFragment;
  hideTitle?: boolean;
  className?: string;
  enableMyCodeNewUI?: boolean;
}

const BARCODE_WIDTH = 180;

export const LoyaltyQrWidget: React.FC<ILoyaltyQrWidgetProps> = ({
  title,
  expirationMessage,
  hideTitle = false,
  enableMyCodeNewUI = false,
  className,
}) => {
  const { formatMessage } = useIntl();

  const { shortCodeState, shortCodeLoading, shortCode, generateShortCode, staticIdentifier } =
    useInRestaurantRedemptionContext();
  const { trackEvent } = useCdpContext();

  const barCodeData = staticIdentifier || shortCode || '';
  useMaximumScreenBrightness();

  // Track performance for certain elements on the page
  trackComponent(PerformancePages.MY_CODE_PAGE, 'QR Code', QR_CODE_SELECTOR, () => {
    trackEvent({
      name: CustomEventNames.LOYALTY_LOAD,
      type: EventTypes.Other,
      attributes: performanceReport(PerformancePages.MY_CODE_PAGE),
    });
  });

  const barcodeOptions = {
    margin: 1,
    scale: QR_CODE_SCALE,
    width: BARCODE_WIDTH,
    color: {
      dark: Styles.color.black,
      light: primitive.$white,
    },
  };

  const shouldHideTitle = hideTitle || shortCodeState === ShortCodeState.Expired;

  return (
    <>
      <QRCodeWrapper className={className}>
        <QRCodeContainer className={className}>
          {!shouldHideTitle && <QRCodeTitle>{title?.locale}</QRCodeTitle>}
          <QRCodeSubtitle>
            {shortCodeState !== ShortCodeState.Expired ? '' : expirationMessage?.locale}
          </QRCodeSubtitle>
          {shortCodeState !== ShortCodeState.Expired && (
            <StyledQRCode
              data-testid={QR_CODE_TEST_ID}
              barcode={barCodeData}
              options={
                enableMyCodeNewUI
                  ? { ...barcodeOptions, width: BARCODE_WIDTH, color: { dark: primitive.$black } }
                  : barcodeOptions
              }
            />
          )}
          {shortCodeState === ShortCodeState.Expired && (
            <ActionButton
              isLoading={shortCodeLoading}
              loadingColor={Styles.color.white}
              onClick={generateShortCode}
            >
              <StyledRefreshIcon />
              {formatMessage({ id: 'refresh' })}
            </ActionButton>
          )}
        </QRCodeContainer>
      </QRCodeWrapper>
      <RewardRedemptionListMemo loyaltyEmptyCartTileWidget={null} />
    </>
  );
};
