import React, { useRef } from 'react';

import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useIosKeyboardAdjustment } from 'hooks/use-ios-keyboard-adjustment';

import { theme } from '../theme';

import { Scroller } from './scroller';

interface IModalContentProps extends IBaseProps, IInner {
  bgColor?: string;
  disableFooter?: boolean;
}

const ModalWrapper = styled.div<IModalContentProps>`
  position: relative;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  background-color: ${p => p.bgColor};
`;

interface IInner extends IBaseProps {
  expand?: boolean;
  widthFull?: boolean;
}

const Inner = styled.div<IInner>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 710px;
  height: ${p => (p.expand ? '95%' : 'auto')};
  padding-block-start: env(safe-area-inset-top);
  padding-block-end: env(safe-area-inset-bottom);
  padding-inline-start: env(safe-area-inset-right);
  padding-inline-end: env(safe-area-inset-left);

  /**
   * Aligns with 'x' in closing button
   * 100% - (2 * button-left) - (2 * button-padding-left)
   */
  width: ${p => (p.widthFull ? '95%' : 'calc(100% - 4.75rem)')};
`;

export const ModalContent = ({
  children,
  className,
  bgColor = theme.modalWrapperBackground,
  expand = false,
  widthFull = false,
}: IModalContentProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useIosKeyboardAdjustment({ containerRef: wrapperRef });

  return (
    <ModalWrapper
      ref={wrapperRef}
      aria-modal="true"
      role="dialog"
      className={className}
      bgColor={bgColor}
    >
      <Scroller className="modal-scroller">
        <Inner className="modal-inner" expand={expand} widthFull={widthFull}>
          {children}
        </Inner>
      </Scroller>
    </ModalWrapper>
  );
};
