import * as location from 'utils/location';

type FlagValue = string | boolean | number;

type FlagOverrides = Record<string, FlagValue>;

const RBI_FEATURE = 'rbiFeature';

/**
 * Retrieve feature flag overrides passed into the app as query parameters
 *
 * NOTE:
 * all feature flag overrides must be prepended with `rbiFeature.`
 * ex: rbiFeature.enableFunkyFreshRhymes=true
 *
 * @returns { [featureFlag: string]: boolean; } an object where the keys are launch darkly flags and the values are booleans
 */
export const getFeatureFlagOverridesFromQueryParameters = (): FlagOverrides => {
  const searchParams = location.getSearchParams();
  const featureFlagOverrides: FlagOverrides = {};

  searchParams.forEach((value, key) => {
    const [prefix, featureFlagName] = key.split('.');

    if (prefix === RBI_FEATURE) {
      let parsedValue: FlagValue = value;

      if (value === 'true') {
        parsedValue = true;
      } else if (value === 'false') {
        parsedValue = false;
      } else if (!Number.isNaN(+value)) {
        parsedValue = +value;
      }
      featureFlagOverrides[featureFlagName] = parsedValue;
    }
  });

  return featureFlagOverrides;
};
