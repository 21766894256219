import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useCartItemPrice } from 'components/cart-item/price/use-cart-item-price';
import { Currency } from 'components/currency';
import { IRewardFragment } from 'generated/graphql-gateway';
import { usePosVendor } from 'hooks/menu/use-pos-vendor';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { selectors, useAppSelector } from 'state/global-state';
import { rewardIsCartDiscount } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { isDiscountReward } from 'state/loyalty/types';
import { useOrderContext } from 'state/order';

import { PriceAndQuantity } from '../cart-item/price-and-quantity';
import { RedeemReward } from '../cart-item/redeem-reward';

import {
  CartPreviewActions,
  CartPreviewEditButton,
  CartPreviewIncrementor,
  CartPreviewRemoveButton,
  CartPreviewTitle,
  CartPreviewVisuallyHidden,
} from './cart-preview-actions';
import { CartEntryQuantityText } from './styled/cart-entry-quantity-text';
import { CartPreviewEditContainer } from './styled/cart-preview-edit-container';
import { CartPreviewEntryContainer } from './styled/cart-preview-entry-container';
import { CartPreviewItem } from './styled/cart-preview-item';
import { ItemPrice } from './styled/item-price';
import { theme } from './theme';
import { ICartPreviewEntry } from './types';

const PeopleLabel = styled.span`
  color: ${theme.peopleLabelColor};
  margin-inline-end: 1rem;
`;

const RedeemRewardWrapper = styled.div`
  > div {
    margin: 1.25rem 0;
  }
`;

export const CartPreviewEntry: React.FC<ICartPreviewEntry> = ({ item, rewardApplied = false }) => {
  const { formatMessage } = useIntl();
  const { isCartEntryInSwaps } = useOrderContext();
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { sanityRewardsMap } = useLoyaltyRewardsList();
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();
  const { vendor } = usePosVendor();
  const isSwapOffer = isCartEntryInSwaps(item);
  const offerDetails = appliedOffers?.find(offer => offer.cartId === item.cartId);
  const isLoyaltyOffer = isSwapOffer || offerDetails;
  const isExtra = item?.isExtra;
  const loyaltyRewardAvailable = getAvailableRewardFromCartEntry(item);
  const isItemEditableInline = !isLoyaltyOffer && loyaltyEnabled && !isExtra;

  const appliedReward = appliedLoyaltyRewards?.[item.cartId || 'discount-offer'];
  const sanityReward = appliedReward?.sanityId ? sanityRewardsMap?.[appliedReward.sanityId] : null;
  const isRewardDiscount = isDiscountReward(sanityReward);
  const isRewardCartDiscount = isRewardDiscount
    ? rewardIsCartDiscount(sanityReward, vendor)
    : false;

  const { price, discountPrice } = useCartItemPrice({
    item,
    isOffer: isLoyaltyOffer,
    offerId: offerDetails?.id!,
  });

  return (
    <CartPreviewEntryContainer>
      <CartPreviewItem>
        <CartPreviewTitle item={item} />
        {isItemEditableInline ? (
          <PriceAndQuantity item={item} rewardApplied={rewardApplied} />
        ) : (
          <ItemPrice>
            <Currency amount={discountPrice || price} />
          </ItemPrice>
        )}
      </CartPreviewItem>

      {isItemEditableInline && loyaltyRewardAvailable && !isRewardDiscount && (
        <RedeemRewardWrapper>
          <RedeemReward
            isRewardApplied={rewardApplied}
            item={item}
            reward={loyaltyRewardAvailable}
          />
        </RedeemRewardWrapper>
      )}
      {loyaltyEnabled && isRewardDiscount && (
        <RedeemRewardWrapper>
          <RedeemReward
            isRewardApplied={rewardApplied}
            item={item}
            reward={appliedReward as unknown as IRewardFragment}
            isRewardDiscount={isRewardDiscount}
            isRewardCartDiscount={isRewardCartDiscount}
          />
        </RedeemRewardWrapper>
      )}
      <CartPreviewActions isReward={rewardApplied} item={item}>
        <CartPreviewEditContainer>
          {!isLoyaltyOffer && !isRewardDiscount && <CartPreviewEditButton />}
          <CartPreviewRemoveButton isRewardCartDiscount={isRewardCartDiscount} />
        </CartPreviewEditContainer>
        {isExtra && <CartEntryQuantityText>{`${item.quantity}x`}</CartEntryQuantityText>}
        {!isLoyaltyOffer && !isRewardDiscount && !isExtra && (
          <CartPreviewIncrementor>
            {item.labelAsPerPerson && (
              <PeopleLabel>
                {formatMessage({
                  id: 'people',
                })}
                :
              </PeopleLabel>
            )}
          </CartPreviewIncrementor>
        )}
        <CartPreviewVisuallyHidden />
      </CartPreviewActions>
    </CartPreviewEntryContainer>
  );
};
