import React, { FC, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useShortCodeRefresh } from 'hooks/loyalty/use-short-code-refresh';
import { useRedeemInRestaurantConfig } from 'hooks/use-redeem-in-restaurant-config';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { isMobile } from 'utils/environment';

import { componentWidgetMap } from '../loyalty-in-restaurant-redemption/loyalty-in-restaurant-redemption';
import { useWidgetComponents } from '../loyalty-widget-components';

import { StyledBottomModal } from './loyalty-cart-drawer.styled';
import { CartListDrawer } from './your-code-drawer/cart-list-drawer';
import { HowToUseDrawer } from './your-code-drawer/how-to-use-drawer';
import { QRCodeDrawer } from './your-code-drawer/qr-code-drawer';
import { InRestaurantScreen, InRestaurantScreenVariants } from './your-code-drawer/types';

export interface ILoyaltyCartDrawerProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

// Used for enabling the close animation of the drawer
const EXIT_ANIMATION_TIMEOUT = 350;

export const LoyaltyCartDrawer: FC<ILoyaltyCartDrawerProps> = ({ isOpen, setIsOpen }) => {
  const enableRedesignLoyaltyQRCodePage = useFlag(
    LaunchDarklyFlag.ENABLE_REDESIGN_LOYALTY_QR_CODE_PAGE
  );

  const { formatMessage } = useIntl();
  const { trackEvent } = useCdpContext();
  const { location } = useLocationContext();

  const { inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();
  const { redeemInRestaurantConfig } = useRedeemInRestaurantConfig();

  const widgets = useWidgetComponents(componentWidgetMap, redeemInRestaurantConfig?.widgets);

  const [drawerType, setDrawerType] = useState(
    !inRestaurantRedemptionCart.length ? InRestaurantScreen.QR_CODE : InRestaurantScreen.CART_LIST
  );

  const drawerConfig: InRestaurantScreenVariants = {
    [InRestaurantScreen.QR_CODE]: {
      title: '',
      component: <QRCodeDrawer widgets={widgets} setDrawerType={setDrawerType} />,
    },
    [InRestaurantScreen.CART_LIST]: {
      title: formatMessage({ id: 'yourInRestaurantCart' }),
      component: <CartListDrawer setDrawerType={setDrawerType} />,
    },
    [InRestaurantScreen.HOW_TO_USE]: {
      title: formatMessage({ id: 'yourInRestaurantCartInfoPageTitle' }),
      component: <HowToUseDrawer setIsOpen={setIsOpen} setDrawerType={setDrawerType} />,
    },
  };

  useShortCodeRefresh();

  useEffect(() => {
    // If we remove all items from the in-restaurant cart we close the drawer
    if (drawerType === InRestaurantScreen.CART_LIST && !inRestaurantRedemptionCart.length) {
      setIsOpen(false);
    }
  }, [drawerType, inRestaurantRedemptionCart, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        name: CustomEventNames.SHEET,
        type: EventTypes.Other,
        attributes: {
          Name: drawerType === InRestaurantScreen.CART_LIST ? 'Your In-Restaurant Cart' : 'Scan',
          Path: location.pathname,
        },
      });
    }
  }, [isOpen, trackEvent]);

  // Only show this If 3rd experiment is enabled
  if (!enableRedesignLoyaltyQRCodePage || !isMobile()) {
    return null;
  }

  return (
    <StyledBottomModal
      open={isOpen}
      title={drawerConfig[drawerType].title}
      onCloseModal={() => {
        trackEvent({
          name: CustomEventNames.BUTTON_CLICK,
          type: EventTypes.Other,
          attributes: {
            Name: 'Close',
            Path: location.pathname,
          },
        });
        setTimeout(() => {
          setIsOpen(false);
        }, EXIT_ANIMATION_TIMEOUT);
      }}
      hideOnClickOutside
    >
      {drawerConfig[drawerType].component}
    </StyledBottomModal>
  );
};
