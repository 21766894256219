import React from 'react';

export const IconX = props => (
  <svg viewBox="0 0 20 20" {...props}>
    <title>Close</title>
    <path
      aria-hidden="true"
      d="M12.79,10l6.5-6.5A2,2,0,0,0,16.5.71L10,7.21,3.5.71A2,2,0,0,0,.71,3.5L7.21,10,.71,16.5A2,2,0,0,0,3.5,19.29l6.5-6.5,6.5,6.5a2,2,0,0,0,1.39.57,2,2,0,0,0,1.4-3.36Z"
    />
  </svg>
);
