import React from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { ServiceMode, useServiceModeContext } from 'state/service-mode';

import { ChangeServiceModeLinkContentsProps } from './types';

export const ChangeServiceModeLinkContents: React.FC<ChangeServiceModeLinkContentsProps> = ({
  linkLabel,
  linkRoute,
  linkText,
  orderingEnabled,
}) => {
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const navigate = useNavigate();
  // we need to set a default service mode to prevent
  // the service mode modal from popping up when we route to the store locator
  const setDefaultServiceMode = () => {
    setServiceMode(ServiceMode.TAKEOUT);
  };
  const handleClick = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!orderingEnabled && !serviceMode) {
      setDefaultServiceMode();
    }
    navigate(linkRoute);
  };

  return (
    <>
      <Link
        aria-label={linkLabel || linkText}
        data-testid="restaurants-mobile"
        onClick={handleClick}
        to={linkRoute}
      >
        <b>{linkText}</b>
      </Link>
    </>
  );
};
