import styled from 'styled-components';

import { IconLoyaltyPoints } from 'components/icons/loyalty-points-icon';
import { brandFont } from 'components/layout/brand-font';
import { UnstyledButton } from 'components/unstyled-button';
import { primitive } from 'styles/constants/primitives';
import { fadeIn } from 'utils/style/animations';

export const OverFlowText = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CardButton = styled(UnstyledButton)`
  height: 100%;
  inset-inline-start: 0;
  padding: 0;
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  width: 100%;
`;

export const FadeIn = styled.div`
  animation: 0.2s ${fadeIn} forwards;
  opacity: 0;
`;

export const PrimaryText = styled.span`
  color: ${Styles.color.primary};
`;

export const Wrapper = styled.div`
  box-shadow: ${p => p.theme.token('drop-shadow-component')};
  position: relative;
  z-index: ${Styles.zIndex.below};
`;

export const HalalContainer = styled.div`
  display: inline-flex;
  position: relative;
  inset-block-start: 0.1rem;
  width: 5rem;
`;

export const Subtitle2 = styled.span`
  font: ${brandFont.headerThree};
  padding-block-end: ${primitive.$spacing1};
  text-transform: capitalize;
  font-weight: ${Styles.fontWeight.heavy};
`;

export const Body2 = styled.span`
  font: ${brandFont.copyTwo};
`;

export const LoyaltyTagsContainer = styled.div`
  display: flex;
`;

export const LoyaltyTag = styled.div<{ mobile: boolean }>`
  display: flex;
  line-height: 1.25rem;
  background: ${p => (p.mobile ? '#f5f5f5' : Styles.color.background)};
  padding: 0.2rem 0.7rem;
  margin-block: 0.5rem;
  margin-inline-start: 0;
  margin-inline-end: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.4rem;
`;

export const StyledLoyaltyIcon = styled(IconLoyaltyPoints)`
  margin-inline-end: 0.5rem;
`;

export const ViewDetailsLink = styled.button`
  color: ${Styles.color.tertiary};
  cursor: pointer;
  font-size: 0.75rem;
  text-decoration: underline;
  border: none;
  width: fit-content;
  background: transparent;
  margin: 2px 0;
  padding: 0;

  &:hover {
    color: ${Styles.color.primaryHover};
  }
`;
