import { useCallback, useMemo } from 'react';

import { Card } from '@braze/web-sdk';

import { useReadyQueue } from 'hooks/use-ready-queue';
import * as Braze from 'utils/braze';

type ToggleContentCards = <E extends Element>(p: E) => void;

export interface IBrazeContext {
  initBraze: VoidFunction;

  getCachedContentCards(): Card[];

  setUserId(userId: string | null): void;

  toggleContentCards: ToggleContentCards;
}

export const useBraze = () => {
  const { enqueueIfNotDrained, drainQueue } = useReadyQueue();

  const toggleContentCards = useCallback<ToggleContentCards>(
    enqueueIfNotDrained(parentNode => {
      Braze.toggleContentCards(parentNode);
    }),
    [enqueueIfNotDrained]
  );

  const setUserId = useCallback(
    enqueueIfNotDrained((userId: string | null) => {
      Braze.changeUser(userId ?? '');
      return Braze.requestContentCardsRefresh();
    }),
    [enqueueIfNotDrained]
  );

  const getCachedContentCards = useCallback(() => {
    if (!Braze.getSdk()) {
      return [];
    }
    return (Braze.getCachedContentCards()?.cards as Card[]) ?? [];
  }, []);

  const initBraze = () => {
    if (Braze?.initialize()) {
      Braze.automaticallyShowInAppMessages();
      Braze.openSession();
      drainQueue();
      Braze.requestImmediateDataFlush();
    }
  };

  const ctxValue: IBrazeContext = useMemo(
    () => ({
      initBraze,
      getCachedContentCards,
      setUserId,
      toggleContentCards,
    }),
    [initBraze, getCachedContentCards, setUserId, toggleContentCards]
  );

  return ctxValue;
};
