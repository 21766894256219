import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

export const IconRefresh = props => (
  <svg viewBox="0 0 22 24" width="22" {...props}>
    <title>Refresh</title>
    <path
      d="M19.8,6.6c-0.5-0.7-1.5-0.8-2.2-0.3c-0.7,0.5-0.8,1.5-0.3,2.2c1.1,1.4,1.6,3.1,1.6,4.9
	c-0.1,3.9-3.2,7.2-7.1,7.5c-4.8,0.4-8.8-3.4-8.6-8.1c0.1-3.2,2.3-5.9,5.2-7V7c0,0.4,0.4,0.6,0.7,0.4l5.8-3.3c0.3-0.2,0.3-0.6,0-0.8
	L9.1,0.1C8.7-0.1,8.4,0.1,8.4,0.5v2.1C3.6,3.8,0,8.1,0,13.2C0.1,19.4,5.3,24.4,11.6,24C17.5,23.7,22,18.8,22,13
	C22,10.7,21.2,8.5,19.8,6.6z"
      fillRule="evenodd"
    />
  </svg>
);
