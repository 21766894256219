import { IBrandRoutes } from './types';
import { LazyRoute, lazyWithFallback } from './util';

const Trending: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('components/trending-page')).TrendingPage,
}));
const PreferencesModal: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('components/preferences-modal-bkde')).PreferencesModalBkDe,
}));
const IframeCampaign: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('components/iframe-campaign/iframe-campaign')).IframeCampaign,
}));

export const brandRoutes: IBrandRoutes = {
  'routes.trending': Trending,
  'routes.userPreferencesModal': PreferencesModal,
};

export const fullPageBrandRoutes: IBrandRoutes = {
  'routes.campaign': IframeCampaign,
};
