import { useEffect } from 'react';

import { useLoyaltyUserQuery } from 'generated/graphql-gateway';
import { useEffectOnUpdates } from 'hooks/use-effect-on-updates';
import { useAuthContext } from 'state/auth';
import { actions, useAppDispatch } from 'state/global-state';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import type { IUseLoyaltyUserState } from './types';

const ROUTE_LIST_REFETCH_LOYALTY_USER = new Set([routes.account, routes.base]);

// helper hook to encapsulate logic to force refetching the user on certain urls
const useForceRefetchUserOnRoute = ({
  refetch,
  skip,
}: {
  refetch: VoidFunction;
  skip: boolean;
}) => {
  const {
    location: { pathname },
  } = useLocationContext();

  // Refetching loyaltyUser based on certain routes until subscriptions are available
  useEffectOnUpdates(() => {
    if (skip) {
      return;
    }

    if (ROUTE_LIST_REFETCH_LOYALTY_USER.has(pathname)) {
      refetch();
    }
  }, [pathname]);
};

/**
 * Fetch and set the initial user state.
 */
export const useLoyaltyUserState = (): IUseLoyaltyUserState => {
  const dispatch = useAppDispatch();
  const { user } = useAuthContext();

  const loyaltyId = user?.loyaltyId;
  const skipLoyaltyUserQuery = !loyaltyId;

  // load init state
  const {
    loading,
    data,
    refetch: refetchLoyaltyUser,
    error,
  } = useLoyaltyUserQuery({
    skip: skipLoyaltyUserQuery,
    variables: { loyaltyId: loyaltyId || '' },
    fetchPolicy: 'network-only',
  });

  // TODO: move this functionality to the routes components
  useForceRefetchUserOnRoute({
    refetch: refetchLoyaltyUser,
    skip: skipLoyaltyUserQuery,
  });

  const loyaltyUser = data?.loyaltyUser || null;

  useEffect(() => {
    dispatch(actions.loyalty.setLoyaltyLoading(loading));
    dispatch(actions.loyalty.setUser(loyaltyUser));
  }, [dispatch, loading, loyaltyUser]);

  return {
    loyaltyUser: data?.loyaltyUser || null,
    error,
    refetch: refetchLoyaltyUser,
    loading,
  };
};
