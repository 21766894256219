import { ApolloClient } from '@apollo/client';

import { getConfiguredCache } from './cache';
import { getConfiguredLink } from './links';

export const getClient = (initialContext: Record<string, unknown>) =>
  new ApolloClient({
    cache: getConfiguredCache(),
    link: getConfiguredLink(initialContext),
  });
