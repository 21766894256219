import { gql } from '@apollo/client';

import { CartEntryFragment } from './cart-entry';

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    _id: rbiOrderId
    createdAt
    posOrderId
    rbiOrderId
    status
    fireOrderIn
    orderNumberFormatted
    orderErrors {
      orderInjectionErrors {
        isConfigurationError
        isMenuError
        isStoreAvailabilityError
        isTimeoutError
        isUnknownError
      }
      posErrors {
        errorCode
        errorName
        message
        rbiErrorCode
        rbiErrorDomain
      }
    }
    fulfillmentDetails {
      pickupType
      pickupPin
      pickupReady
    }
    cart {
      appliedOffers {
        sanityId
      }
      customerName
      cartEntries {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                }
              }
            }
          }
        }
      }
      cartVersion
      discounts {
        name
        value
      }
      donations {
        id
        name
        totalCents
      }
      fees {
        name
        priceCents
        quantity
        totalCents
      }
      guestDetails {
        isoCountryCode
      }
      guestId
      payment {
        cardType
        ccLast4
        panToken
        paymentType
        paymentMethodBrand
        paymentRequestTimeLimit
      }
      posVendor
      rewardsApplied {
        cartId
        rewardId
        timesApplied
      }
      serviceMode
      storeAddress {
        addressLine1
        addressLine2
        city
        state
        zip
      }
      storeDetails {
        latitude
        longitude
        storeNumber
      }
      subTotalCents
      taxCents
      ticketNumber
      totalCents
      unavailableCartEntries {
        lineId
        sanityId
      }
      wasLoyaltyEarned
      vatNumber
    }
    delivery {
      pickup {
        latitude
        longitude
      }
      dropoff {
        arrivalTime
        beforeTime
        afterTime
        addressLine1
        addressLine2
        city
        state
        zip
        latitude
        longitude
        phoneNumber
      }
      driver {
        name
        profileImageUrl
        phoneNumber
      }
      feeCents
      feeDiscountCents
      instructions
      status
      tipCents
      serviceFeeCents
      smallCartFeeCents
      geographicalFeeCents
      quotedFeeCents
      baseDeliveryFeeCents
      deliverySurchargeFeeCents
      serviceFeeDiscountCents
      unavailabilityReason
    }
    loyaltyTransaction {
      id
      pointsEarned
      bonusPointsEarned
      pointsUsed
      pointsBalance
      rewardsUsed
    }
  }

  ${CartEntryFragment}
`;
