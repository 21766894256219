/* eslint-disable */
import { getCurrentVersion } from 'utils/live-updates';
import { getApiKey } from 'utils/environment';

const getSrc = () => {
  return '/bloomreach.js';
};

export const initSdk = callback => {
  getCurrentVersion().then(data => {
    init(getApiKey('bloomreachTarget'), getApiKey('bloomreachProjectToken'), callback);
  });
};

export const init = (target, projectToken, callback) => {
  console.log('Bloomreach sdk init');
  window.exponea = window.exponea || {};
  var e = ['track', 'identify', 'update', 'anonymize', 'start'];

  e.forEach(function (t) {
    window.exponea[t] = n(t);
  });

  function n(e, o) {
    return function () {
      if (o) {
        e = o + '.' + e;
      }
      var t = Array.prototype.slice.call(arguments);
      t.unshift(e);
      window.exponea(t);
    };
  }

  let mp = document.createElement('script');
  mp.setAttribute('bloomreachTarget', target);
  mp.setAttribute('bloomreachProjectToken', projectToken);
  mp.type = 'text/javascript';
  mp.async = true;
  mp.src = getSrc();
  mp.onload = callback;
  var c = document.getElementsByTagName('script')[0];
  c.parentNode.insertBefore(mp, c);
};
