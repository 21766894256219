import React, { FC } from 'react';

import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { useMediaQuery } from 'hooks/use-media-query';

import { ImageContainer, Layout } from './product.styled';

const WrapperLabel = styled.div`
  display: grid;
  align-items: center;
  height: fit-content;
  padding: 8px;
`;

export const ProductSkeleton: FC<{ $inline: boolean }> = ({ $inline }) => {
  const isMobile = useMediaQuery('mobile');

  return (
    <Layout data-testid="product-loading" $inline={$inline}>
      <ImageContainer>
        <Skeleton height="100%" />
      </ImageContainer>
      <WrapperLabel>
        <Skeleton height="20px" width={isMobile ? '160px' : '210px'} />
        {$inline && (
          <>
            <div>
              <Skeleton height="18px" width={isMobile ? '65px' : '110px'} />
            </div>
            <div>
              <Skeleton height="18px" width={isMobile ? '65px' : '110px'} />
            </div>
            <div>
              <Skeleton height="18px" width={isMobile ? '65px' : '110px'} />
            </div>
          </>
        )}
      </WrapperLabel>
    </Layout>
  );
};
