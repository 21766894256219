import { IFeatureFooterQuery, useFeatureFooterQuery } from 'generated/sanity-graphql';
import { useMediaQuery } from 'hooks/use-media-query';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureFooterValue {
  featureFooterLoading: boolean;
  featureFooter: IFeatureFooterQuery['FeatureFooter'] | null;
}

export const useFeatureFooter = (): IUseFeatureFooterValue => {
  const { featureIdsLoading } = useFeaturesContext();
  const isDesktop = useMediaQuery('desktopLarge');

  const featureFooterId = isDesktop
    ? 'feature-footer-singleton'
    : 'feature-footer-mobile-singleton';

  const { data, loading } = useFeatureFooterQuery({
    variables: { featureFooterId },
    skip: !featureFooterId,
  });

  const featureFooter = data?.FeatureFooter ?? null;
  const featureFooterLoading = featureIdsLoading || loading;

  return {
    featureFooterLoading,
    featureFooter,
  };
};
