import { IImageFragment } from 'generated/sanity-graphql';
import { maybeLocaleImage, maybeLocaleString } from 'utils/graphql';

type TGenericData = Record<string, unknown>;

interface ILocaleStringData extends TGenericData {
  __typename: 'LocaleString';
  locale: string | null;
}

interface ILocaleImageData extends TGenericData {
  __typename: 'LocaleImage';
  locale: IImageFragment | null;
}

type TLocaleData = ILocaleStringData | ILocaleImageData;

const typeParser = (data: TLocaleData) => {
  switch (data?.__typename) {
    case 'LocaleString':
      return maybeLocaleString(data);
    case 'LocaleImage':
      return maybeLocaleImage(data);
    default:
      return null;
  }
};

const isLocaleData = (data: any): data is TLocaleData =>
  data?.__typename === 'LocaleString' || data?.__typename === 'LocaleImage';

type TResultData = string | IImageFragment;

export const parseData = <T, U extends Record<string, TResultData>>(
  data: T,
  keysToValidate: string[]
) => {
  if (!data) {
    return null;
  }

  const parsedData = Object.entries(data).reduce(
    (acc, [key, val]) => {
      if (isLocaleData(val)) {
        const parsedField = typeParser(val);
        if (parsedField) {
          acc[key as keyof T] = parsedField;
        }
      }
      return acc;
    },
    {} as Record<keyof T, TResultData>
  );

  const isValidData = keysToValidate.every(key => Boolean(parsedData[key as keyof T]));

  return isValidData ? (parsedData as U) : null;
};

export const validationKeys = {
  modal: ['continue', 'body', 'header', 'image', 'selectNew', 'itemNotAvailableForDelivery'],
};
