import { CardTypes } from 'state/payment/types';

export enum FirstDataAcceptedCards {
  MASTERCARD = 'mc',
  VISA = 'visa',
}

export const FirstDataAcceptedCardsToCardType: Record<string, CardTypes> = {
  [FirstDataAcceptedCards.MASTERCARD]: CardTypes.MASTERCARD,
  [FirstDataAcceptedCards.VISA]: CardTypes.VISA,
};
