import React, { FC, useMemo } from 'react';

import { ModifierCheckbox } from './modifier-checkbox';
import { ModifierMultipleChoices } from './modifier-multiple-choices';
import { ModifierNonNumeric } from './modifier-non-numeric';
import { ModifierNumeric } from './modifier-numeric';
import { ModifierSingleChoice } from './modifier-single-choice';
import { Container } from './modifier.styled';
import { IModifierProps, ModifierTypes } from './types';
import { getModifierType } from './utils';

export const Modifier: FC<IModifierProps> = ({
  modifier,
  selectedItem,
  selections,
  onSelectionsChange,
}) => {
  const modifierType = useMemo(() => getModifierType(modifier), [modifier]);

  const modifierTypeUIProps = {
    modifier,
    onSelectionsChange,
    selectedItem,
    selections,
  };

  const modifierTypeUI = useMemo(() => {
    switch (modifierType) {
      case ModifierTypes.NON_NUMERIC:
        return <ModifierNonNumeric {...modifierTypeUIProps} />;
      case ModifierTypes.CHECKBOX:
        return <ModifierCheckbox {...modifierTypeUIProps} />;
      case ModifierTypes.NUMERIC:
        return <ModifierNumeric {...modifierTypeUIProps} />;
      case ModifierTypes.SINGLE_CHOICE:
        return <ModifierSingleChoice {...modifierTypeUIProps} />;
      case ModifierTypes.MULTIPLE_CHOICE:
        return <ModifierMultipleChoices {...modifierTypeUIProps} />;
      default:
        return null;
    }
  }, [modifierType, modifierTypeUIProps]);

  const ariaLabel = useMemo(() => {
    const selectionsNames = Object.values(selections).map(sel => sel.name?.locale || '');
    return `Modifier ${modifier.name?.locale}, Current selection ${selectionsNames.join(', ')}`;
  }, [modifier.name, selections]);

  return (
    <Container data-testid="modifier" aria-label={ariaLabel}>
      {modifierTypeUI}
    </Container>
  );
};
