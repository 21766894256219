import React from 'react';

import { useIntl } from 'react-intl';

import { CookieBannerType } from 'components/layout/cookie-banner-container/types';
import { CookieSettingsLink } from 'pages/account/cookie-settings-link';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getConfigValue, isWeb } from 'utils/environment';
import { isExternalLink } from 'utils/is-external-link';
import { normalize, routes } from 'utils/routing';

import { Container, List, ListItem, Section, SectionTitle, StyledLink } from './styled';
import { ListOfLinksProps, SingleNode } from './types';
import { createLinkClickHandler, getNodes } from './utils';

export const ListOfLinks: React.FC<ListOfLinksProps> = ({
  borderColor,
  entries,
  onDismiss,
  showAboutAppLink,
  showSectionHeadings,
}) => {
  const { formatMessage } = useIntl();
  const enableNutritionExplorerLink = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER);
  const enableMobileCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_COOKIE_BANNER);
  const { cookieBanner }: { cookieBanner: string } = getConfigValue('cookieBanners');

  const customOptionLinksNodes: SingleNode[] = showAboutAppLink
    ? [
        {
          type: 'single',
          title: formatMessage({ id: 'aboutApp' }),
          path: routes.aboutApp,
        },
      ]
    : [];

  const showOpenCookieBanner = isWeb || enableMobileCookieBanner;
  if (cookieBanner === CookieBannerType.ONE_TRUST_COOKIE_BANNER && showOpenCookieBanner) {
    customOptionLinksNodes.push({
      type: 'component',
      element: <CookieSettingsLink />,
      title: '',
      path: '',
    });
  }

  const nodes = getNodes(entries, enableNutritionExplorerLink).concat(customOptionLinksNodes);

  const renderSingleNode = ({ title, path, element }: SingleNode) => {
    if (React.isValidElement(element)) {
      return <ListItem>{element}</ListItem>;
    }

    // Fix relative paths not having a leading slash
    const isExternal = isExternalLink(path);
    const to = isExternal ? path : normalize(path);
    return (
      <ListItem key={`${title}:${to}`}>
        <StyledLink to={to} onClick={createLinkClickHandler({ isExternal, onDismiss })}>
          {title}
        </StyledLink>
      </ListItem>
    );
  };

  return (
    <Container data-testid="links-list">
      {nodes.map((node, i) => {
        const isSection = node.type === 'array';
        return (
          <Section $borderColor={borderColor} key={`path-group-${i}`}>
            {isSection && showSectionHeadings && <SectionTitle>{node.title}</SectionTitle>}
            <List>{isSection ? node.pages.map(renderSingleNode) : renderSingleNode(node)}</List>
          </Section>
        );
      })}
    </Container>
  );
};
