import {
  IRedeemInRestaurantConfigQuery,
  useRedeemInRestaurantConfigQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseRedeemInRestaurantConfig {
  redeemInRestaurantConfig: IRedeemInRestaurantConfigQuery['ConfigRedeemInRestaurant'] | null;
  redeemInRestaurantConfigLoading: boolean;
}

export const useRedeemInRestaurantConfig = (): IUseRedeemInRestaurantConfig => {
  const { featureRedeemInRestaurantConfigId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useRedeemInRestaurantConfigQuery({
    variables: { redeemInRestaurantConfigId: featureRedeemInRestaurantConfigId },
    skip: !featureRedeemInRestaurantConfigId,
  });

  const redeemInRestaurantConfig = data?.ConfigRedeemInRestaurant ?? null;

  return {
    redeemInRestaurantConfig,
    redeemInRestaurantConfigLoading: featureIdsLoading || loading,
  };
};
