import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { OptionalProps } from '@rbi-ctg/frontend';
import {
  GetPrepaidsBalanceDocument,
  IGetPrepaidsBalanceMutationVariables,
  IsoCountryCode,
  useGetPrepaidsBalanceMutation,
} from 'generated/rbi-graphql';
import { logger } from 'utils/logger';

import { IUsePrepaidsBalanceProps } from './types';

type Variables = IGetPrepaidsBalanceMutationVariables;

const MutationName = getOperationName(GetPrepaidsBalanceDocument)!;

export const usePrepaidsBalance = ({ region }: IUsePrepaidsBalanceProps) => {
  const onError = useCallback((error: ApolloError) => {
    const { graphQLErrors: errors } = error;
    if (errors.length) {
      logger.error({
        errors,
        message: 'Error retrieving prepaid balance.',
        queryName: MutationName,
      });
    }
  }, []);

  const [fireMutation, mutationResult] = useGetPrepaidsBalanceMutation({ onError });

  const getPrepaidsBalance = useCallback(
    ({
      cardNumber,
      feCountryCode = IsoCountryCode[region],
    }: OptionalProps<Variables, 'feCountryCode'>) => {
      return fireMutation({ variables: { cardNumber, feCountryCode } });
    },
    [fireMutation, region]
  );

  const { data } = mutationResult;
  const currentBalance = data && data.prepaidsBalance ? data.prepaidsBalance.currentBalance : null;

  return { ...mutationResult, currentBalance, getPrepaidsBalance };
};
