import React from 'react';

import styled from 'styled-components';

import { Icon } from './icon';

interface IColorable {
  color?: string;
}

const IconContainer = styled.div<IColorable>`
  fill: ${props => (props.color ? props.color : Styles.color.grey.three)};
  display: flex;
  justify-content: center;
  svg {
    width: 10rem;
  }
`;

interface IBrandIcon {
  color?: string;
}

export const BrandIcon = ({ color }: IBrandIcon) => {
  return (
    <IconContainer color={color}>
      <Icon />
    </IconContainer>
  );
};
