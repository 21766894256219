import React, { FC } from 'react';

import { Icon } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { IconBKFries } from 'components/icons/bk-fries';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyIdentificationCodeTypeVariation } from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import {
  ActionsWrapper,
  AddIncentiveButton,
  Container,
  InfoBackButton,
  InfoDescription,
  InfoTitle,
  InfoWrapper,
} from '../loyalty-cart-drawer.styled';

import { InRestaurantScreen } from './types';

interface HowToUseDrawerProps {
  setIsOpen: (value: boolean) => void;
  setDrawerType: (type: InRestaurantScreen) => void;
}

export const HowToUseDrawer: FC<HowToUseDrawerProps> = ({ setIsOpen, setDrawerType }) => {
  const { formatMessage } = useIntl();
  const { navigate, location } = useLocationContext();
  const { trackEvent } = useCdpContext();

  const enableLoyaltyInApps = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_IN_APPS);
  const loyaltyIdentificationCodeType = useFlag<LoyaltyIdentificationCodeTypeVariation>(
    LaunchDarklyFlag.LOYALTY_IDENTIFICATION_CODE_TYPE
  );
  return (
    <>
      <InfoBackButton
        data-testid="how-to-use-loyalty-back-button"
        onClick={() => {
          trackEvent({
            name: CustomEventNames.BUTTON_CLICK,
            type: EventTypes.Other,
            attributes: {
              Name: 'Back',
              Path: location.pathname,
            },
          });
          setDrawerType(InRestaurantScreen.QR_CODE);
        }}
      >
        <Icon icon="back" color="icon-default" width="24px" aria-hidden />
      </InfoBackButton>

      <Container>
        <InfoWrapper>
          <IconBKFries />
          <InfoTitle>{formatMessage({ id: 'yourInRestaurantCartInfoTitle' })}</InfoTitle>
          <InfoDescription>
            {formatMessage({ id: 'yourInRestaurantCartInfoDescription' })}
          </InfoDescription>
          <ActionsWrapper>
            {enableLoyaltyInApps && (
              <AddIncentiveButton
                variant={ActionButtonVariants.OUTLINE}
                data-testid="redemption-add-rewards-button"
                size={ActionButtonSizes.LARGE}
                perceptible
                startIcon={<Icon icon="add" color="primary" aria-hidden />}
                onClick={() => {
                  setIsOpen(false);
                  navigate(routes.rewardsList);
                }}
              >
                {formatMessage({ id: 'loyaltyAddRewards' })}
              </AddIncentiveButton>
            )}
            {loyaltyIdentificationCodeType === LoyaltyIdentificationCodeTypeVariation.DYNAMIC && (
              <AddIncentiveButton
                variant={ActionButtonVariants.OUTLINE}
                data-testid="redemption-add-offers-button"
                size={ActionButtonSizes.LARGE}
                perceptible
                startIcon={<Icon icon="add" color="primary" aria-hidden />}
                onClick={() => {
                  setIsOpen(false);
                  navigate(routes.rewardsOffers);
                }}
              >
                {formatMessage({ id: 'loyaltyAddOffers' })}
              </AddIncentiveButton>
            )}
          </ActionsWrapper>
        </InfoWrapper>
      </Container>
    </>
  );
};
