import { useEffect, useRef } from 'react';

import { env } from 'utils/environment';

/**
 * `useInterval`
 *
 * `useInterval` is a hook that implements an "interval runner".
 * this interval runner is an effect with some custom logic and handles setting
 * up and tearing down the interval.
 *
 * @param callback The function to run on an interval
 * @param delay The amount of time to wait to run the `callback`, in ms.
 *              If `delay` is `null`, the interval runner returns `undefined`,
 *              effectively resulting in a noop
 * @param deps Any hook dependencies to pass to the interval runner. `delay` is passed in by default
 */
export const useInterval = (callback: VoidFunction, delay: number | null, deps: any[] = []) => {
  const savedCallback = useRef(callback);

  savedCallback.current = callback;

  // Set up the interval.
  useEffect(() => {
    if (env() === 'test') {
      return undefined;
    }

    if (delay === null) {
      return undefined;
    }

    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};
