import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IsoCountryCode2, Locale, asIsoCountryCode2, getIso2 } from '@rbilabs/intl';
import imageUrlBuilder from '@sanity/image-url';
import {
  ImageUrlBuilderOptionsWithAliases,
  SanityImageSource,
} from '@sanity/image-url/lib/types/types';
import { IFormatCurrencyProps, formatCurrency } from 'utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import { buildImageUrl as buildImageUrlUtil } from 'remote/build-image-url';
import { useAuthContext } from 'state/auth';
import { UserDetails } from 'state/auth/hooks/types';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { FALLBACK_ISO2 } from 'utils/constants';
import { sanityDataset as defaultSanityDataset, getConfigValue } from 'utils/environment';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';

export type ImageBuilderType = ReturnType<typeof imageUrlBuilder>;

export type BuildImageUrlType = (
  source: SanityImageSource,
  options?: Partial<ImageUrlBuilderOptionsWithAliases>
) => string;

export type FormatCurrencyForType = (amount: number) => string;

export interface IUIContext {
  imageBuilder: ImageBuilderType;
  buildImageUrl: (
    source: SanityImageSource,
    options?: Partial<ImageUrlBuilderOptionsWithAliases>
  ) => string;

  shouldConfirmStoreOnCheckout(flag: boolean): void;

  confirmStoreOnCheckout: boolean;

  formatCurrencyForLocale(amount: number): string;

  formatCurrencyForUser(amount: number | undefined): string;
}

interface GetFormatCurrenctForUserOptions {
  user: UserDetails | null;
  locale: string;
  region: IsoCountryCode2;
}

export const getFormatCurrencyForUser =
  ({ user, locale, region }: GetFormatCurrenctForUserOptions) =>
  (amount: number) => {
    const userISOCountryCode = (user?.details?.isoCountryCode ||
      FALLBACK_ISO2) as keyof typeof ISOs;
    const { currencyCode, countryCode } = getCountryAndCurrencyCodes(ISOs[userISOCountryCode]);

    const formatOptions: IFormatCurrencyProps = {
      language: locale,
      currency: currencyCode,
      currencyDisplay: countryCode === region ? 'narrowSymbol' : 'symbol',
      amount,
    };

    return formatCurrency(formatOptions);
  };

interface GetFormatCurrenctForLocaleOptions {
  user: UserDetails | null;
  language: string;
  region: IsoCountryCode2;
}

export const getFormatCurrencyForLocale =
  ({ user, language, region }: GetFormatCurrenctForLocaleOptions) =>
  (amount: number) => {
    const userISOCountryCode = user?.details?.isoCountryCode;
    const userRegion = ((userISOCountryCode && getIso2({ iso3: userISOCountryCode })) ||
      region ||
      FALLBACK_ISO2) as IsoCountryCode2;

    const userLocale = `${language}-${userRegion}`;

    const { currencyCode } = getCountryAndCurrencyCodes(ISOs[region]);

    const formatOptions: IFormatCurrencyProps = {
      language: userLocale as Locale,
      currency: currencyCode,

      currencyDisplay: userRegion === region ? 'narrowSymbol' : 'symbol',
      amount,
    };

    return formatCurrency(formatOptions);
  };

export const UIContext = createContext<IUIContext>({} as IUIContext);
export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }: IBaseProps) => {
  const { location, setReferrerLocation } = useLocationContext();
  const { user } = useAuthContext();
  const { language, region, locale } = useLocale();
  const [confirmStoreOnCheckout, setConfirmStoreOnCheckout] = useState(true);
  const enableStoreConfirmationModal = useFlag(LaunchDarklyFlag.ENABLE_STORE_CONFIRMATION_MODAL);
  const previousUrl = useRef<string>('');

  useEffect(() => {
    const currentUrl = location.pathname + location.search;

    if (previousUrl.current === currentUrl) {
      return;
    }

    setReferrerLocation(previousUrl.current);

    previousUrl.current = currentUrl;
  }, [location.pathname, location.search, setReferrerLocation]);

  const shouldConfirmStoreOnCheckout = useCallback(
    (flag: boolean) => {
      if (enableStoreConfirmationModal) {
        setConfirmStoreOnCheckout(flag);
      }
    },
    [setConfirmStoreOnCheckout, enableStoreConfirmationModal]
  );

  const imageBuilder = useMemo(() => {
    const dataset = region
      ? `${defaultSanityDataset}_${region.toLowerCase()}`
      : defaultSanityDataset.toLowerCase();

    return imageUrlBuilder({ dataset, projectId: getConfigValue('sanityProjectId') });
  }, [region]);

  const buildImageUrl: BuildImageUrlType = useCallback(
    (source, options = {}) => buildImageUrlUtil(imageBuilder, source, options),
    [imageBuilder]
  );

  /**
   * Use the user profile's isoCountryCode to determine the locale.
   * Use the site's locale to determine currency.
   * Useful for product prices which are displayed in the currency of the site (e.g. show a US user '$CA' on the fr-CA and en-CA sites)
   */
  const formatCurrencyForLocale: FormatCurrencyForType = useCallback(
    getFormatCurrencyForLocale({
      user,
      region: asIsoCountryCode2(region) ?? FALLBACK_ISO2,
      language,
    }),
    [user, region, language]
  );

  /**
   * Use the site's locale as the formatted number's locale
   * Use the user profile's isoCountryCode to determine the currency,
   * Useful for showing user an amount that they are going to pay in their account's currency (e.g. reloading a pre-paid card)
   */
  const formatCurrencyForUser: FormatCurrencyForType = useCallback(
    getFormatCurrencyForUser({
      user,
      locale,
      region: asIsoCountryCode2(region) ?? FALLBACK_ISO2,
    }),
    [user, locale, region]
  );

  const value = useMemo(
    () => ({
      buildImageUrl,
      imageBuilder,
      confirmStoreOnCheckout: enableStoreConfirmationModal && confirmStoreOnCheckout,
      shouldConfirmStoreOnCheckout,
      formatCurrencyForLocale,
      formatCurrencyForUser,
    }),
    [
      buildImageUrl,
      confirmStoreOnCheckout,
      enableStoreConfirmationModal,
      formatCurrencyForLocale,
      formatCurrencyForUser,
      imageBuilder,
      shouldConfirmStoreOnCheckout,
    ]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
