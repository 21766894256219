import React, { ReactNode, useContext, useEffect } from 'react';

import { QueryLazyOptions } from '@apollo/client';

import { IGetStaticPageQuery } from 'generated/sanity-graphql';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useCdpContext } from 'state/cdp';
import { useLocale } from 'state/intl';

import { useStaticPage } from './hooks/use-static-page';
import { useStaticPageRoutes } from './hooks/use-static-page-routes';

export interface IStaticPageContext {
  currentPage: IGetStaticPageQuery['StaticPage'] | undefined;
  currentPageLoading: boolean;
  retrieveStaticPageById(id: string): void;
  routes: IStaticPageRoute[];
  routesLoading: boolean;
  loadRoutes: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
  loadRoutesHasBeenCalled: boolean;
  isStaticPage: (pathname?: string) => boolean;
  getStaticPageRoute: (pathname?: string) => IStaticPageRoute | undefined;
}

export const PageManagerContext = React.createContext<IStaticPageContext>({} as IStaticPageContext);
export const usePageManagerContext = () => useContext(PageManagerContext);

export function PageManagerProvider({ children }: { children: ReactNode }) {
  const { language } = useLocale();
  const {
    loadRoutes,
    routes,
    loading: routesLoading,
    loadRoutesHasBeenCalled,
  } = useStaticPageRoutes();
  const { currentPage, loading: currentPageLoading, retrieveStaticPageById } = useStaticPage();
  const { updateStaticRoutes } = useCdpContext();

  const findCurrentStaticPageRoute = (route: IStaticPageRoute, pathname?: string) =>
    `/${route.localePath?.[language]?.current}` === pathname;

  const isStaticPage = (pathname?: string) =>
    routes.some(route => findCurrentStaticPageRoute(route, pathname));

  const getStaticPageRoute = (pathname?: string) =>
    routes.find(route => findCurrentStaticPageRoute(route, pathname));

  // Updates static routes in mParticle to log when users visit static pages
  useEffect(() => {
    updateStaticRoutes(routes);
  }, [routes, updateStaticRoutes]);

  return (
    <PageManagerContext.Provider
      value={{
        currentPage,
        currentPageLoading,
        retrieveStaticPageById,
        routes,
        routesLoading,
        loadRoutes,
        loadRoutesHasBeenCalled,
        isStaticPage,
        getStaticPageRoute,
      }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export const StaticPageManager = PageManagerContext.Consumer;
