import type { ConnectionStatus } from '@capacitor/network';

import { fetch } from 'utils/network';

import { GroqError, IGroqError } from '../exceptions/groq-error';

// Chrome starts truncating urls at around 7500 so
// we put a max limit on it. Sanity supports up to 11KB
const URL_SIZE_LIMIT = 7500;

export type GroqArgs<A extends object> = {
  connection?: ConnectionStatus;
} & A;

export interface IGroqResponse<R extends object> {
  error: IGroqError;
  ms: number;
  query: string;
  result: R;
}

export const groqQuery = <R extends object, V extends object>(
  url: string,
  query: string,
  { connection, ...params }: GroqArgs<V> = {} as GroqArgs<V>
) => {
  const paramStr = Object.entries(params).reduce((str, [key, value]) => {
    str += `&${encodeURIComponent('$' + key)}=${encodeURIComponent(JSON.stringify(value))}`;
    return str;
  }, '');

  // strip out excess whitespace
  query = query
    .trim()
    .split('\n')
    .map(line => line.trim())
    .join('');

  const getUrl = `${url}?query=${`${encodeURIComponent(query)}${paramStr}`}`;

  const fetchConfig = {
    connection,
    then: (res: IGroqResponse<R>) => {
      if (res.error) {
        throw new GroqError(res.error);
      }

      return res.result;
    },
  };

  return lengthInUtf8Bytes(getUrl) <= URL_SIZE_LIMIT
    ? // the url is smaller than the size limit, so we should
      // query with GET
      fetch<R, {}>(getUrl, { ...fetchConfig, maxRetries: 3, method: 'GET' })
    : // the url is larger than the size limit, so we should
      // query with POST
      fetch<R, {}>(url, {
        ...fetchConfig,
        body: JSON.stringify({ params, query }),
        maxRetries: 3,
        method: 'POST',
      });
};

// Helpers

// taken from:
// https://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript
function lengthInUtf8Bytes(str: string) {
  // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
  const m = encodeURIComponent(str).match(/%[89ABab]/g);
  return str.length + (m ? m.length : 0);
}
