import * as React from 'react';
import { FC, useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useRoutesDisplayBottomService as useRoutesDisplayTopService } from 'components/bottom-service-mode/hooks/use-routes-display-bottom-service';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { useTopServiceModeDetails } from './hooks/use-top-service-details';
import { TopServiceModeView } from './top-service-mode.view';
import { ITopServiceModeProps } from './types';

/**
 *
 * TopServiceMode purpose
 *
 */
export const TopServiceMode: FC<ITopServiceModeProps> = () => {
  const enableTopServiceMode = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const topServiceModeDetails = useTopServiceModeDetails();
  const {
    ConfirmationModal,
    CurbsideConfirmationModal,
    curbsideConfirmationModalOpen,
    setCurbsideConfirmationModalOpen,
  } = topServiceModeDetails;
  const showTopServiceOnRoutes = useRoutesDisplayTopService();
  const { emptyCart, setCurbsidePickupOrderId, setCurbsidePickupOrderTimePlaced } =
    useOrderContext();

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { resetStore } = useStoreContext();

  const isDisplayTopServiceMode = useMemo(
    () => enableTopServiceMode && !enableBottomServiceMode && showTopServiceOnRoutes,
    [enableBottomServiceMode, enableTopServiceMode, showTopServiceOnRoutes]
  );

  useEffect(() => {
    if (!enableOrdering) {
      resetStore();
    }
  }, [enableOrdering, resetStore]);

  if (!isDisplayTopServiceMode) {
    return null;
  }

  const modalDismiss = () => setCurbsideConfirmationModalOpen(false);
  const cancelCurbsideOrder = () => {
    emptyCart();
    setCurbsidePickupOrderId('');
    setCurbsidePickupOrderTimePlaced('');
    modalDismiss();
    navigate(routes.storeLocator);
  };

  return (
    <>
      <TopServiceModeView {...topServiceModeDetails} />

      <ConfirmationModal
        heading={formatMessage({ id: 'bsmConfirmationModalHeading' })}
        body={formatMessage({ id: 'bsmConfirmationModalBody' })}
      />

      {curbsideConfirmationModalOpen && (
        <CurbsideConfirmationModal
          onKeepOrder={modalDismiss}
          onCancelOrder={cancelCurbsideOrder}
          onDismiss={modalDismiss}
        />
      )}
    </>
  );
};
