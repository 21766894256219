import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconBurgerKingLogo } from 'components/icons/burger-king-logo';

const StyledBurgerKingLogo = styled(IconBurgerKingLogo)`
  margin: 0 auto;
  transform: scale(1.3);
`;

export const CheckoutIcon: React.FC = props => {
  const { formatMessage } = useIntl();

  const title = formatMessage({ id: 'bk_logo_title' });

  return <StyledBurgerKingLogo title={title} {...props} />;
};
