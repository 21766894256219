import React, { createContext, useContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

import { BrazeCssBugOverride } from './braze-css-bug-override';
import { IBrazeContext, useBraze } from './hooks/use-braze';

export const BrazeContext = createContext<IBrazeContext>({} as IBrazeContext);

export const useBrazeContext = () => useContext(BrazeContext);

export const BrazeProvider = ({ children }: IBaseProps) => {
  const ctxValue = useBraze();

  return (
    <BrazeContext.Provider value={ctxValue}>
      <BrazeCssBugOverride />
      {children}
    </BrazeContext.Provider>
  );
};
