// This mParticleAdapter is used as the adapter layer to talk to the mParticle web SDK
// Usage of window.mParticle should be strongly discouraged outside of this file

import { CdpAdapterBase } from '../cdp-adapter-base';
import { EventTypes } from '../constants';
import { ICdpAdapter } from '../types';

class BloomreachAdapterWeb extends CdpAdapterBase implements ICdpAdapter {
  logEvent(
    name: string,
    eventTypes: EventTypes,
    attributes?: Record<string, unknown>,
    customFlags?: Record<string, unknown>
  ) {
    name = this.standardizeEventName(name);
    attributes = this.standardizePropertiesName(attributes);
    customFlags = this.standardizePropertiesName(customFlags);
    if (this.skipEvent(name, attributes, customFlags)) {
      return;
    }
    this.updatePrevEvent(name, attributes, customFlags);
    let eventProperties = {
      type: eventTypes,
      ...attributes,
      ...customFlags,
    };
    try {
      window.exponea.track(name, eventProperties);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Bloomreach - track event not found', err);
    }
  }

  update(customerAttributes: any) {
    customerAttributes.customer_id = this.getCustomerId(customerAttributes);
    customerAttributes.email_id = this.getEmailId(customerAttributes);
    customerAttributes = this.standardizePropertiesName(customerAttributes);
    try {
      window.exponea.update(customerAttributes);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Bloomreach - update not found', err);
    }
  }

  identify(
    email: string,
    customerId: string,
    userAttributes: Record<string, unknown>,
    successCallback: Function,
    errorCallback?: Function
  ) {
    userAttributes = this.standardizePropertiesName(userAttributes);
    try {
      window.exponea.identify(
        { email_id: email, customer_id: customerId },
        { ...userAttributes, email },
        successCallback,
        errorCallback
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Bloomreach - identify not found', err);
    }
  }

  getCustomerId(props: any) {
    return props?.customerId || props?.customerid || '';
  }

  getEmailId(props: any) {
    return props?.email || '';
  }

  standardizeEventName(name: string) {
    return this.replaceNonAllowedCharacter(name);
  }

  standardizePropertiesName(properties?: Record<string, unknown>) {
    let newProperties: { [key: string]: any } = {};
    if (properties && Object.keys(properties).length > 0) {
      Object.keys(properties).map((key: string) => {
        let newKey = this.validateKeyValue(key);
        if (newKey) {
          newProperties[newKey] = properties[key];
        }
        return newKey;
      });
    }
    return newProperties;
  }

  validateKeyValue(keyProperty: string) {
    if (!keyProperty) {
      return false;
    } else {
      return this.replaceNonAllowedCharacter(keyProperty);
    }
  }

  replaceNonAllowedCharacter(value: string) {
    if (this.isCamelCase(value)) {
      value = value.replace(/([a-z])([A-Z])/g, '$1_$2');
    }

    if (value.search('(?=.*[$.])S')) {
      value = this.replaceCharacter(value, '$', '_');
      value = this.replaceCharacter(value, '.', '_');
      value = this.replaceCharacter(value, ' ', '_');
    }
    return value.toLocaleLowerCase();
  }

  isCamelCase(input: string): boolean {
    const camelCaseRegex = /^[a-z][a-zA-Z]*$/;
    return camelCaseRegex.test(input);
  }

  replaceCharacter(value: string, found: string, replace: string) {
    return value.split(found).join(replace);
  }

  Identity = BloomreachAdapterWeb;
}

export const BloomreachAdapter = new BloomreachAdapterWeb();
