import * as React from 'react';
import { FC } from 'react';

import {
  IBlockContentOverrides,
  ISerializers,
  PassthroughComponent,
  SanityBlockRenderer,
} from 'components/sanity-block-renderer';

import { Name } from './styled';
import { IItemNameProps } from './types';

const nameBlockContentOverrides: IBlockContentOverrides = {
  normal: Name,
};

const nameBlockSerializers: ISerializers = {
  marks: {
    em: PassthroughComponent,
  },
  container: PassthroughComponent,
};

export const OfferItemName: FC<IItemNameProps<'offer'>> = ({ entryCartDetails: { offer } }) => (
  <SanityBlockRenderer
    blockContentOverrides={nameBlockContentOverrides}
    serializers={nameBlockSerializers}
    content={offer?.name?.localeRaw}
  />
);

export const RewardItemName: FC<IItemNameProps<'reward'>> = ({ entryCartDetails: { reward } }) => (
  <Name>{reward?.name?.locale || ''}</Name>
);
