import { CognitoUserPool, CognitoUserSession, ICognitoStorage } from 'amazon-cognito-identity-js';

import { getCurrentSession } from 'remote/auth';

import { getConfigValue } from '../environment';

import { AuthStorage } from './storage';

export const initCognitoSession = async (): Promise<CognitoUserSession | null> => {
  await AuthStorage.sync();
  return getCurrentSession();
};

/**
 * @class
 * The cognito class provides helper methods for configuring and interacting
 * with cognito services
 */
export class Cognito {
  public static storage: ICognitoStorage = AuthStorage as ICognitoStorage;

  private static userPoolEndpoint() {
    const region = getConfigValue('aws').region;
    return `https://cognito-idp.${region}.amazonaws.com/`;
  }

  public static userPool() {
    const { userPoolClientId, userPoolId } = getConfigValue('aws');

    return new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: userPoolClientId,
      endpoint: Cognito.userPoolEndpoint(),
      Storage: AuthStorage as ICognitoStorage,
    });
  }
}
