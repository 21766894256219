import React, { useCallback, useRef, useState } from 'react';

import { normalizedTranslate } from '@rbilabs/components-library';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { noop } from 'lodash';
import styled, { css } from 'styled-components';

import { CloseButton as CloseButtonComponent } from 'components/close-button';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';

import { theme } from './theme';
import { IModalProps } from './types';
import { findInnerHeaderText } from './utils';

export enum ModalSize {
  FULLSCREEN = 'FULLSCREEN',
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  LANDSCAPE = 'LANDSCAPE',
  AUTO = 'AUTO',
}

export const modalSizeStyles = {
  borderRadius: {
    [ModalSize.FULLSCREEN]: '0',
    [ModalSize.REGULAR]: Styles.borderRadius,
    [ModalSize.SMALL]: Styles.borderRadius,
    [ModalSize.LANDSCAPE]: Styles.borderRadius,
    [ModalSize.AUTO]: Styles.borderRadius,
  },
  height: {
    [ModalSize.FULLSCREEN]: '100%',
    [ModalSize.REGULAR]: 'calc(100% - 10rem)',
    [ModalSize.SMALL]: 'calc(100% - 15rem)',
    [ModalSize.LANDSCAPE]: 'calc(100% - 10rem)',
    [ModalSize.AUTO]: 'auto',
  },
  width: {
    [ModalSize.FULLSCREEN]: '100%',
    [ModalSize.REGULAR]: 'calc(100% - 10rem)',
    [ModalSize.SMALL]: 'calc(60% - 15rem)',
    [ModalSize.LANDSCAPE]: '400px',
    [ModalSize.AUTO]: 'auto',
  },
  maxHeight: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '100%',
    [ModalSize.SMALL]: '100%',
    [ModalSize.LANDSCAPE]: 'auto',
    [ModalSize.AUTO]: '100%',
  },
  maxWidth: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '860px',
    [ModalSize.SMALL]: '480px',
    [ModalSize.LANDSCAPE]: '400px',
    [ModalSize.AUTO]: '100%',
  },
  minHeight: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '860px',
    [ModalSize.SMALL]: '480px',
    [ModalSize.LANDSCAPE]: '860px',
    [ModalSize.AUTO]: 'auto',
  },
  minWidth: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '540px',
    [ModalSize.SMALL]: '400px',
    [ModalSize.LANDSCAPE]: '400px',
    [ModalSize.AUTO]: 'auto',
  },
};

const ModalContainer = styled(DialogOverlay)<IModalProps>`
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  inset-inline-start: 0;
  position: fixed;
  inset-block-start: 0;
  width: 100%;
  /* TODO: refactor z-indexes */
  z-index: ${Styles.zIndex.max - 200};

  [data-reach-dialog-content] {
    background-color: ${p => p.$backgroundColor || theme.modalContentBackground};
    background-image: ${p => (p.$backgroundImage ? `url(${p.$backgroundImage})` : '')};
    display: flex;
    flex-direction: column;
    height: 100%;
    inset-inline-start: 0;
    overflow-y: auto;
    position: absolute;
    inset-block-start: 0;
    width: 100%;

    /* webkit hack to ensure content renders with -webkit-overflow-scrolling: touch above; */

    > * {
      -webkit-transform: translateZ(0);
    }

    ${Styles.desktop} {
      border-radius: ${(p: IModalProps) => modalSizeStyles.borderRadius[p.size!]};
      flex-direction: row;
      inset-inline-start: 50%;
      inset-block-start: 50%;
      transform: ${normalizedTranslate('-50%', '-50%')};
      height: ${(p: IModalProps) => modalSizeStyles.height[p.size!]};
      width: ${(p: IModalProps) => modalSizeStyles.width[p.size!]};
      max-width: ${(p: IModalProps) => modalSizeStyles.maxWidth[p.size!]};
      max-height: ${(p: IModalProps) => modalSizeStyles.maxHeight[p.size!]};
      height: ${({ height }: IModalProps) => height};
      background-color: ${(p: any) => p.$backgroundColor || theme.modalContentBackground};
    }
  }
`;

const ModalContent = styled(DialogContent)`
  ${Styles.mobile} {
    padding-block-start: env(safe-area-inset-top);
    padding-block-end: env(safe-area-inset-bottom);
    padding-inline-start: env(safe-area-inset-right);
    padding-inline-end: env(safe-area-inset-left);
  }

  ${props => props.overrideStyles}
`;

const StyledCloseButtonDefault = styled(CloseButtonComponent)`
  inset-inline-start: 1.25rem;
  inset-inline-start: calc(1.25rem + env(safe-area-inset-left));
  position: fixed;
  inset-block-start: calc(1rem + env(safe-area-inset-top));
  box-shadow: ${theme.closeButtonBoxShadow};
  z-index: ${Styles.zIndex.below};

  ${Styles.native(css`
    inset-block-start: calc(1.25rem + env(safe-area-inset-top));
  `)}
`;

export const Modal = ({
  allowsDismiss = true,
  className,
  children,
  onDismiss = noop,
  size = ModalSize.REGULAR,
  closeButton: CloseButton = StyledCloseButtonDefault,
  eventData: inEventData,
  height,
  skipLoggingHeaderAndMessage = false,
  backgroundColor,
  backgroundImage,
  overrideStyles,
  ...props
}: IModalProps) => {
  const { trackEvent = noop } = useCdpContext();
  const eventData = useRef(inEventData);
  const [lastModalMessage, setLastModalMessage] = useState('');

  const contentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) {
        return;
      }

      if (!eventData.current) {
        return;
      }

      const { modalHeader, modalMessage, modalAppearanceEventMessage } = eventData.current;
      const header = modalHeader || findInnerHeaderText(node) || '';
      const message = modalMessage || node.innerText;

      if (lastModalMessage !== 'Email modal sign-up') {
        trackEvent({
          name: CustomEventNames.MODAL_APPEARANCE,
          type: EventTypes.Other,
          attributes: {
            Message: modalAppearanceEventMessage,
            ModalHeader: skipLoggingHeaderAndMessage ? '' : header,
            ModalMessage: skipLoggingHeaderAndMessage ? '' : message,
          },
        });
        setLastModalMessage(modalAppearanceEventMessage);
      }
    },
    [trackEvent, skipLoggingHeaderAndMessage, lastModalMessage]
  );

  return (
    <ModalContainer
      isOpen
      $backgroundColor={backgroundColor}
      $backgroundImage={backgroundImage}
      onDismiss={onDismiss}
      size={size}
      ref={contentRef}
      height={height}
    >
      <ModalContent
        className={className}
        role="dialog"
        aria-modal={true}
        aria-labelledby="modal-heading"
        {...props}
        overrideStyles={overrideStyles}
      >
        {allowsDismiss && (
          <CloseButton aria-hidden="true" onClick={onDismiss} data-testid="modal-close-button" />
        )}
        {children}
      </ModalContent>
    </ModalContainer>
  );
};

export { ModalContent } from './modal-content';
export { ModalHeading } from './modal-heading';
