import defaultPlugin from 'store/plugins/defaults';
import expiredPlugin from 'store/plugins/expire';
import engine from 'store/src/store-engine';
import sessionStorage from 'store/storages/sessionStorage';

import { logger } from 'utils/logger';

import { Keys } from './constants';

const plugins = [defaultPlugin, expiredPlugin];
const sessionStore = engine.createStore([sessionStorage], plugins);

export class WebSessionStorage {
  static getItem = <V = any>(key: Keys): V | null => {
    return sessionStore.get(key) || null;
  };

  static setItem = <V = any>(key: Keys, value: V) => {
    try {
      sessionStore.set(key, value);
    } catch (error) {
      const message = 'Session Storage setItem Error';
      logger.warn({ error, message });
    }
  };

  static clearAll = () => {
    sessionStore.clearAll();
  };

  static removeItem = (storageKey: string) => {
    sessionStore.remove(storageKey);
  };
}
