import React from 'react';

import { Icon } from '@rbilabs/components-library';
import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import { Logo } from 'components/logo';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';

import { AppInfo } from './app-info';

const StyledLogo = styled(Logo)`
  margin-block-end: 58px;
  width: 134px;

  path {
    fill: ${Styles.color.contrastBackground};
  }
`;

const PageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-block-start: 111px;
`;

const Disclaimers = styled.div`
  margin-block-start: 30px;

  p {
    margin: 0;
    text-align: center;
    font: ${brandFont.copyTwo};
  }
`;

export const AboutApp: React.FC = () => {
  const { featureDisclaimers } = useFeatureDisclaimers();

  return (
    <>
      <BackArrowHistory icon={<Icon icon="close" width="24px" color="icon-header-contrast" />} />
      <PageWrapper>
        <StyledLogo />
        <AppInfo />
        {featureDisclaimers && (
          <Disclaimers>
            <p>{featureDisclaimers.copyrightAndTrademark?.locale}</p>
            <p>{featureDisclaimers.reservedRights?.locale}</p>
          </Disclaimers>
        )}
      </PageWrapper>
    </>
  );
};
