/* eslint-disable no-console */
import { StatusType, datadogLogs } from '@datadog/browser-logs';

import { RBIEnv } from 'utils/environment';

import { TLogHandler, TMethod } from './types';

// Map between a logger method and Datadog logger status.
const methodToStatus: Record<TMethod, StatusType> = {
  trace: StatusType.debug,
  debug: StatusType.debug,
  info: StatusType.info,
  warn: StatusType.warn,
  error: StatusType.error,
  fatal: StatusType.error,
};

/**
 * Handler that sends logs to Datadog.
 *
 * Please note there are multiple factors that determine whether logs will be
 * forwarded to Datadog or not:
 * - The configured sampling rate
 * - The current environment (development vs. production)
 * - The configured log level
 */
export const datadogHandler: TLogHandler = ({ context, env, method }) => {
  if (env !== RBIEnv.TEST) {
    const ctxError = context.error;
    const ctxMessage = (ctxError as Error) || context.message;
    const strMessage = typeof ctxMessage === 'string' ? ctxMessage : ctxMessage?.message || 'n/a';
    const errorOrUndefined = ctxError instanceof Error ? ctxError : undefined;

    datadogLogs.logger.log(strMessage, context, methodToStatus[method], errorOrUndefined);
  }
};

/**
 * We keep a copy of the original console methods because the DD-SDK overrides them.
 */
export const originalConsole = {
  debug: console.debug.bind(console),
  error: console.error.bind(console),
  fatal: console.error.bind(console),
  info: console.info.bind(console),
  trace: console.trace.bind(console),
  warn: console.warn.bind(console),
};

/**
 * Handler that prints out logs to the console.
 */
export const consoleHandler: TLogHandler = ({ context, method }) => {
  originalConsole[method](context.message, context);
};
