import React from 'react';

import { createRoot } from 'react-dom/client';

import { App } from './app';
import { RenderApp, bootstrap } from './bootstrap';

export const renderApp: RenderApp = ({
  appLanguage,
  appCountry,
  flags: flattenedFlags,
  userHasSignedIn,
  userSession,
  shouldUseLanguageCodeInUrls,
}) => {
  const rootTag: HTMLElement = document.getElementById('root') as HTMLElement;
  const root = createRoot(rootTag);

  root.render(
    <App
      shouldUseLanguageCodeInUrls={shouldUseLanguageCodeInUrls}
      appLanguage={appLanguage}
      appCountry={appCountry}
      flags={flattenedFlags}
      userHasSignedIn={userHasSignedIn}
      userSession={userSession}
    />
  );
};

bootstrap(renderApp);
