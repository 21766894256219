import 'intersection-observer';
import { useEffect, useRef, useState } from 'react';

declare global {
  interface Window {
    IntersectionObserver: typeof IntersectionObserver;
  }
}

interface UseVisibilitySensorOptions {
  triggerOnce?: boolean;
  threshold?: number;
  rootMargin?: string | null;
  triggerWhenFullyVisible?: boolean;
  isSingleton?: boolean;
  initialIsVisible?: boolean;
}

/**
 * LISTENERS holds the callback functions for each observed element.
 */
const LISTENERS = new Map<Element, (isVisible: boolean) => void>();

/**
 * GLOBAL_OBSERVER is a global IntersectionObserver singleton.
 * Since our primary use-case is simple enough we can get away with using one
 * IntersectionObserver rather than create a new one for each execution.
 *
 * Alternatively, isSingleton may be set to false to allow for more fine grained configuration.
 * It needs to be wrapped in a function so the pre-build process can complete.
 */
let GLOBAL_OBSERVER: IntersectionObserver;
const getObserver = ({
  threshold,
  rootMargin,
  triggerWhenFullyVisible,
  isSingleton = true,
}: Omit<UseVisibilitySensorOptions, 'triggerOnce'>) => {
  if (GLOBAL_OBSERVER && isSingleton) {
    return GLOBAL_OBSERVER;
  }

  const observerInstance = new window.IntersectionObserver(
    entries => {
      entries.forEach(({ target, intersectionRatio }) => {
        const listener = LISTENERS.get(target);

        if (listener) {
          const isVisible = triggerWhenFullyVisible
            ? intersectionRatio === 1
            : intersectionRatio > 0;

          listener(isVisible);
        }
      });
    },
    {
      rootMargin: rootMargin ?? '10px',
      threshold: threshold ?? 0.1,
    }
  );

  if (isSingleton) {
    GLOBAL_OBSERVER = observerInstance;
  }

  return observerInstance;
};

interface UseVisibilitySensorHook {
  visibilityRef: React.MutableRefObject<Element | undefined>;
  isVisible: boolean;
}

export const useVisibilitySensor = ({
  triggerOnce,
  threshold,
  rootMargin,
  triggerWhenFullyVisible,
  isSingleton,
  initialIsVisible,
}: UseVisibilitySensorOptions = {}): UseVisibilitySensorHook => {
  const visibilityRef = useRef<Element>();
  const visibilityElement = visibilityRef?.current;

  const [isVisible, setIsVisible] = useState(
    initialIsVisible ?? Boolean(window.IntersectionObserver)
  );

  useEffect(() => {
    const observer = getObserver({ threshold, rootMargin, triggerWhenFullyVisible, isSingleton });

    if (!window.IntersectionObserver || !visibilityElement) {
      return;
    }

    observer.observe(visibilityElement);

    LISTENERS.set(visibilityElement, (visible: boolean) => {
      if (isVisible !== visible) {
        setIsVisible(visible);
      }

      if (visible && triggerOnce) {
        observer.unobserve(visibilityElement);
        LISTENERS.delete(visibilityElement);
      }
    });

    return () => {
      observer.unobserve(visibilityElement);
    };
  }, [
    visibilityElement,
    setIsVisible,
    isVisible,
    triggerOnce,
    threshold,
    rootMargin,
    triggerWhenFullyVisible,
    isSingleton,
  ]);

  return { visibilityRef, isVisible };
};
