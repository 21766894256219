import React, { useContext } from 'react';

import { IsoCountryCode2, Language } from '@rbilabs/intl';
import { noop } from 'lodash';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { isTest } from 'utils/environment';

import { useIntlProviderValue } from './hook';
import { IIntlProviderContext } from './types';

export type { IIntlProviderContext } from './types';

export const defaultLocale = 'en-US';

export const IntlContext = React.createContext<IIntlProviderContext>({} as IIntlProviderContext);

export const useLocale = () => useContext(IntlContext);

// NOTE: cypress-v2 test suite requirement
// Skip react-intl error logging when running in cypress
const skipErrorLogging = Boolean(isTest || window.Cypress);

interface IIntlProviderProps {
  appLanguage: Language;
  appCountry: IsoCountryCode2;
  children: React.ReactNode;
}

export function IntlProvider({ appLanguage, appCountry, children }: IIntlProviderProps) {
  const value = useIntlProviderValue(appLanguage, appCountry);

  return (
    <IntlContext.Provider value={value}>
      <ReactIntlProvider
        defaultLocale={defaultLocale}
        locale={value.locale}
        messages={value.messages}
        {...(skipErrorLogging && { onError: noop })}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
}

export const Intl = IntlContext.Consumer;
