import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';

import { ActionButtonVariants } from 'components/action-button';

import {
  Container,
  Content,
  Title,
  TryAgainCodeButton,
} from './loyalty-qr-and-short-code-widget.styled';

interface IShortCodeErrorProps {
  shortCodeExpirationTitle: string | null | undefined;
  shortCodeExpirationButtonCtaLabel: string | null | undefined;
  onRefresh: VoidFunction;
}

export const ShortCodeError: React.FC<IShortCodeErrorProps> = ({
  shortCodeExpirationTitle,
  shortCodeExpirationButtonCtaLabel,
  onRefresh,
}) => {
  return (
    <Container data-testid="loyalty-qr-and-short-code-error">
      <Content>
        <Title>{shortCodeExpirationTitle}</Title>
        <TryAgainCodeButton
          data-testid="try-again-short-code-button"
          startIcon={<Icon icon={'refresh'} color={'primary'} width="20px" aria-hidden />}
          variant={ActionButtonVariants.OUTLINE}
          onClick={onRefresh}
        >
          {shortCodeExpirationButtonCtaLabel}
        </TryAgainCodeButton>
      </Content>
    </Container>
  );
};
