import * as React from 'react';

import { useIntl } from 'react-intl';

import { ArrowLink } from 'components/arrow-link';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { useFeaturesLoyaltyTiersContent } from 'state/loyalty/hooks/use-feature-loyalty-tiers-content';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyRewards } from 'state/loyalty/hooks/use-loyalty-rewards';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import {
  LinkContainer,
  PointsContainer,
  StyledPointsDisplay,
  StyledRewardsLogo,
} from './rewards-link.styled';

interface IRewardsLinkProps {
  onClickEventName: CustomEventNames;
  showPointsLabel?: boolean;
  textLabel?: string;
}

export const RewardsLink = ({
  onClickEventName,
  showPointsLabel,
  textLabel,
}: IRewardsLinkProps) => {
  const cdp = useCdpContext();
  const { formatMessage } = useIntl();
  const handleClick = () => cdp.logNavigationClick(onClickEventName);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { enableLoyaltyTiers } = useLoyaltyRewards(loyaltyUser);
  const { data } = useFeaturesLoyaltyTiersContent();

  const route = enableLoyaltyTiers
    ? data?.loyaltyTiersHowItWorksButtonLink?.locale
    : formatMessage({ id: 'routes.rewardsHistory' });

  return loyaltyEnabled && loyaltyUser ? (
    <ArrowLink data-testid="loyalty-link" to={route || '#'} onClick={handleClick}>
      <LinkContainer>
        {textLabel || <StyledRewardsLogo />}
        <PointsContainer>
          <StyledPointsDisplay
            prefix={showPointsLabel ? `${formatMessage({ id: 'points' })}` : undefined}
            value={loyaltyUser.points}
          />
        </PointsContainer>
      </LinkContainer>
    </ArrowLink>
  ) : null;
};
