/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, ReactNode, lazy } from 'react';

import { useAmplitudeContext } from 'state/cdp/amplitude';
import { useBloomreachContext } from 'state/cdp/bloomreach';
import { useMParticleContext } from 'state/cdp/mParticle';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { CdpProviderTypes, ICdpCtx } from './types';

const providersMap = {
  mparticle: lazy(() =>
    import('state/cdp/mParticle').then(module => ({ default: module.MParticleProvider }))
  ),
  bloomreach: lazy(() =>
    import('state/cdp/bloomreach').then(module => ({ default: module.BloomreachProvider }))
  ),
  amplitude: lazy(() =>
    import('state/cdp/amplitude').then(module => ({ default: module.AmplitudeProvider }))
  ),
};

const providersContext = {
  mparticle: useMParticleContext,
  bloomreach: useBloomreachContext,
  amplitude: useAmplitudeContext,
};

const CDP_PROVIDER_DEFAULT = CdpProviderTypes.MParticle;

function useCdpProviderName() {
  return useFlag<CdpProviderTypes>(LaunchDarklyFlag.CDP_PROVIDER) ?? CDP_PROVIDER_DEFAULT;
}

export function CdpProvider({ children }: { children: ReactNode }) {
  const provider = useCdpProviderName();
  const MappedCdpProvider = providersMap[provider] ?? Fragment;

  return <MappedCdpProvider>{children}</MappedCdpProvider>;
}

export function useCdpContext(): ICdpCtx {
  const provider = useCdpProviderName();
  return providersContext[provider] ? providersContext[provider]() : useMParticleContext();
}
