import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import { ReceiptHeadInfoBase } from './receipt-head-info.base';
import { Title } from './receipt-head-info.styled';
import { IOrderHeaderProps } from './types';

export const ReceiptHeadInfo: FC<IOrderHeaderProps> = ({ order }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Title>{formatMessage({ id: 'orderDetailsHeading' })}</Title>
      <ReceiptHeadInfoBase order={order} />
    </>
  );
};
