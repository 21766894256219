import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  BottomDrawer,
  useBottomDrawerUIState,
} from '@rbilabs/components-library/build/components/bottom-drawer';
import {
  BottomDrawerBody,
  BottomDrawerHeader,
} from '@rbilabs/components-library/build/components/bottom-drawer/bottom-drawer.styled';
import { Box } from '@rbilabs/components-library/build/components/layout/Box';
import { noop } from 'lodash';

import { Modal, ModalSize } from 'components/modal';
import { MediaQuery, useMediaQuery } from 'hooks/use-media-query';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';

import { IBottomModalProps, ModalState } from './types';

export const BottomModal = ({
  open,
  title,
  eventData: inEventData,
  onCloseModal,
  children,
  ...props
}: IBottomModalProps) => {
  const { trackEvent = noop } = useCdpContext();
  const [lastModalState, setLastModalState] = useState(ModalState.CLOSE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const bottomDrawer = useBottomDrawerUIState();
  const isMobile = useMediaQuery(MediaQuery.Mobile);
  const eventData = useRef(inEventData);

  const logModalEvent = useCallback(() => {
    if (!eventData.current) {
      return;
    }
    const { modalHeader, modalAppearanceEventMessage } = eventData.current;
    const header = modalHeader || title || '';
    trackEvent({
      name: CustomEventNames.MODAL_APPEARANCE,
      type: EventTypes.Other,
      attributes: {
        Message: modalAppearanceEventMessage,
        ModalHeader: header,
      },
    });
  }, [trackEvent, title]);

  useEffect(() => {
    if (isMobile) {
      setIsModalOpen(false);
    } else {
      bottomDrawer.hide();
    }

    if (open) {
      if (isMobile) {
        bottomDrawer.show();
      } else {
        setIsModalOpen(true);
      }
      if (lastModalState === ModalState.CLOSE) {
        logModalEvent();
      }
      setLastModalState(ModalState.OPEN);
    } else {
      bottomDrawer.hide();
      setIsModalOpen(false);
    }
  }, [open, isMobile]);

  useEffect(() => {
    if (bottomDrawer.visible) {
      return;
    }
    // bottomDrawer was closed
    if (isMobile && open && lastModalState !== ModalState.CLOSE) {
      onCloseModal();
    }
  }, [bottomDrawer.visible]);

  return (
    <>
      <BottomDrawer
        dialogState={bottomDrawer}
        header={title}
        aria-label={title}
        hideOnClickOutside={true}
        {...props}
      >
        {children}
      </BottomDrawer>
      {isModalOpen && (
        <Modal onDismiss={onCloseModal} size={ModalSize.AUTO} {...props}>
          <Box width="100%" flexDirection="column" padding="0 1rem 1rem">
            <BottomDrawerHeader>{title}</BottomDrawerHeader>
            <BottomDrawerBody>{children}</BottomDrawerBody>
          </Box>
        </Modal>
      )}
    </>
  );
};
