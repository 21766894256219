import { useCallback, useEffect, useState } from 'react';

import { isFunction, isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { IStore } from '@rbi-ctg/store';
import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { IRestaurant, IRestaurantNode } from 'generated/rbi-graphql';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { serviceModeList } from 'pages/cart/service-mode-details/service-mode-list';
import { NotFoundMenuItemError } from 'remote/exceptions';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import {
  checkIfWithinOneHourOfCloseToday,
  milesBetweenCoordinates,
  readableCloseHourToday,
  readableDistanceFromStore,
  useGetRestaurantAvailabilityFn,
  useIsMobileOrderingAvailable,
} from 'utils/restaurant';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';
import { getStoreSEOSlug } from 'utils/slugify';

interface IDefaultServiceModeOptions {
  restaurant: IStore | IRestaurantNode;
}

export function useDefaultServiceMode({ restaurant }: IDefaultServiceModeOptions) {
  const defaultServiceModeInStore = useFlag(LaunchDarklyFlag.DEFAULT_SERVICE_MODE_IN_STORE);
  const { serviceModeStatus } = useServiceModeStatus(restaurant);

  // get default service mode from ld
  const defaultAvailableServiceMode = serviceModeList.find(key => {
    return (
      key === defaultServiceModeInStore &&
      serviceModeStatus[key].available &&
      !serviceModeStatus[key].disabled
    );
  });
  // default service mode to:
  // 1) ld flag defaultServiceModeInStore
  // 2) the first available shown in cart when choosing a store
  // 3) the first service mode
  return (
    defaultAvailableServiceMode ||
    serviceModeList.find(key => {
      return serviceModeStatus[key].available && !serviceModeStatus[key].disabled;
    }) ||
    serviceModeList[0]
  );
}

interface IUseSelectRestaurantOptions {
  restaurant: IStore | IRestaurantNode;
}

interface ISelectRestaurantOptions {
  callback?: VoidFunction;
  requestedServiceMode?: ServiceMode;
}

export function useSelectRestaurant({ restaurant }: IUseSelectRestaurantOptions) {
  const [reordering, setReordering] = useState(false);
  const {
    selectStore,
    reorder: { handleReorder, pendingReorder },
  } = useOrderContext();
  const { isStoreOpenAndAvailable, prices } = useStoreContext();
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const [checkingItemAvailability, setCheckingItemAvailability] = useState(false);
  const { trackEvent, updateUserAttributes } = useCdpContext();
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();
  const { checkStaticMenuItemAvailability, selectedStaticMenuItemId } = useMenuContext();
  const enableServiceModeCartSelection = useFlag(
    LaunchDarklyFlag.ENABLE_SERVICE_MODE_CART_SELECTION
  );
  const { storeLocatorCallbackUrl } = useLocationContext();
  const navigate = useNavigate();
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const defaultServiceMode = useDefaultServiceMode({ restaurant });

  const selectRestaurant = useCallback(
    ({ requestedServiceMode = defaultServiceMode, callback }: ISelectRestaurantOptions = {}) => {
      const selectStoreCallback = isFunction(callback)
        ? callback
        : async () => {
            if (!enableOrdering) {
              navigate(routes.offers);

              return;
            }

            // Currently, catering service mode is selected deliberatly in a modal.
            if (isCatering(serviceMode)) {
              navigate(storeLocatorCallbackUrl || routes.menu);

              return;
            }

            // set service mode to ensure we aren't taken back to static menu
            // need to do this after previous checks so that we don't set service mode
            // when enableOrdering is false and/or when a catering mode has already
            //  been selected
            setServiceMode(requestedServiceMode);

            // if user has a pending reorder return and let the useEffect handle it below
            if (pendingReorder) {
              setReordering(true);
              return;
            }

            // If showing static menu and user has selected item redirect to the item if
            // available after store selection, otherwise route to main menu
            if (selectedStaticMenuItemId) {
              try {
                setCheckingItemAvailability(true);
                await checkStaticMenuItemAvailability(restaurant, serviceMode);
                return;
              } catch (e) {
                throw new NotFoundMenuItemError(selectedStaticMenuItemId);
              } finally {
                setCheckingItemAvailability(false);
              }
            } else if (enableServiceModeCartSelection) {
              trackEvent({
                name: CustomEventNames.ORDER_HERE_STORE_CONFIRMATION_FLOW,
                type: EventTypes.Navigation,
                attributes: {
                  'Restaurant Id': restaurant._id,
                  'Restaurant Name': restaurant.name,
                },
              });
              updateUserAttributes(
                {
                  'Restaurant ID': restaurant._id,
                  'Restaurant Name': restaurant.name,
                },
                {},
                sendUpdateUserAttributesEvent
              );

              navigate(storeLocatorCallbackUrl || routes.menu);
            } else {
              // if the enableServiceModeCartSelection is false,
              // redirecting to this route will pop a modal
              // enabling user to select their preferred pickup
              // method (Drive Thru, Curbside, etc.)
              navigate(routes.pickupMode, { replace: true });
            }
          };

      selectStore(restaurant, selectStoreCallback, requestedServiceMode);
    },
    [
      selectStore,
      restaurant,
      enableOrdering,
      serviceMode,
      enableServiceModeCartSelection,
      pendingReorder,
      selectedStaticMenuItemId,
      navigate,
      storeLocatorCallbackUrl,
      trackEvent,
      setServiceMode,
      checkStaticMenuItemAvailability,
      defaultServiceMode,
    ]
  );

  useEffect(() => {
    // we have to wait for pricing to be complete from selecting a new store before we redirect
    // we also only want to reorder if we haven't already, pendingReorder gets set to null when we are done reordering
    if (isStoreOpenAndAvailable && pendingReorder && prices && reordering) {
      setReordering(false);
      handleReorder(pendingReorder);
    }
  }, [isStoreOpenAndAvailable, pendingReorder, prices, reordering]);

  return {
    selectRestaurant,
    checkingItemAvailability,
  };
}

interface IUseStoreCardOptions {
  restaurant: IStore | IRestaurantNode;
  shouldGetRemoteAvailabilityData?: boolean;
}

export function useStoreCard({
  restaurant,
  shouldGetRemoteAvailabilityData = true,
}: IUseStoreCardOptions) {
  const { activeCoordinates } = useGeolocation();
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);
  const driveThruOpen =
    serviceModeStatus.DRIVE_THRU.available && !serviceModeStatus.DRIVE_THRU.disabled;
  const dineInOpen = serviceModeStatus.EAT_IN.available && !serviceModeStatus.EAT_IN.disabled;
  const takeoutOpen = serviceModeStatus.TAKEOUT.available && !serviceModeStatus.TAKEOUT.disabled;
  const diningRoomOpen = dineInOpen || takeoutOpen;
  const curbsideOpen = serviceModeStatus.CURBSIDE.available && !serviceModeStatus.CURBSIDE.disabled;
  const openHours = driveThruOpen ? restaurant.driveThruHours : restaurant.diningRoomHours;
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const areDiagnosticToolsEnabled = useFlag(LaunchDarklyFlag.ENABLE_INTERNAL_DIAGNOSTIC_TOOLS);
  const checkAvailability = useGetRestaurantAvailabilityFn();

  const closeHourTodayText = readableCloseHourToday(openHours);

  const isWithinOneHourOfCloseToday = checkIfWithinOneHourOfCloseToday(openHours);
  const restaurantConnectivityStatus = useRestaurantPosConnectivityStatus({
    storeId: restaurant.number,
    skip: !shouldGetRemoteAvailabilityData,
  });

  // use the just-fetched restaurant if queried from backend
  const { isRestaurantPosOnline } = shouldGetRemoteAvailabilityData
    ? restaurantConnectivityStatus
    : {
        isRestaurantPosOnline: checkAvailability(restaurant as unknown as IRestaurant),
      };

  let distanceText;
  if (activeCoordinates && restaurant.latitude && restaurant.longitude) {
    const distance = milesBetweenCoordinates(activeCoordinates, {
      lat: restaurant.latitude,
      lng: restaurant.longitude,
    });
    distanceText = readableDistanceFromStore(distance, region, formatMessage);
  }

  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);

  const storeSEOSlug = getStoreSEOSlug(restaurant);

  const { selectRestaurant, checkingItemAvailability } = useSelectRestaurant({ restaurant });

  return {
    storeSEOSlug,
    distanceText,
    curbsideOpen,
    dineInOpen,
    takeoutOpen,
    diningRoomOpen,
    driveThruOpen,
    openHours,
    selectRestaurant,
    isMobileOrderingAvailable,
    isWithinOneHourOfCloseToday,
    closeHourTodayText,
    isRestaurantPosOnline: !!isRestaurantPosOnline,
    enableOrdering,
    areDiagnosticToolsEnabled,
    checkingItemAvailability,
  };
}
