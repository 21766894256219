import React, { FC } from 'react';

import { Icon } from '@rbilabs/components-library';

import { IconInfoOutline } from 'components/icons/info-outline';
import { IWidgetElement } from 'pages/loyalty/loyalty-widget-components/types';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';

import { Container, InfoBackButton, InfoButton } from '../loyalty-cart-drawer.styled';

import { InRestaurantScreen } from './types';

interface QRCodeDrawerProps {
  widgets: Array<IWidgetElement>;
  setDrawerType: (type: InRestaurantScreen) => void;
}

export const QRCodeDrawer: FC<QRCodeDrawerProps> = ({ widgets, setDrawerType }) => {
  const { inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();
  const { trackEvent } = useCdpContext();
  const { location } = useLocationContext();

  return (
    <>
      {inRestaurantRedemptionCart?.length ? (
        <InfoBackButton
          data-testid="qr-code-loyalty-back-button"
          onClick={() => {
            trackEvent({
              name: CustomEventNames.BUTTON_CLICK,
              type: EventTypes.Other,
              attributes: {
                Name: 'Back',
                Path: location.pathname,
              },
            });
            setDrawerType(InRestaurantScreen.CART_LIST);
          }}
        >
          <Icon icon="back" color="icon-default" width="24px" aria-hidden />
        </InfoBackButton>
      ) : null}
      <InfoButton
        data-testid="how-to-use-loyalty-code"
        onClick={() => {
          trackEvent({
            name: CustomEventNames.BUTTON_CLICK,
            type: EventTypes.Other,
            attributes: {
              Name: 'Info',
              Path: location.pathname,
            },
          });
          setDrawerType(InRestaurantScreen.HOW_TO_USE);
        }}
      >
        <IconInfoOutline />
      </InfoButton>
      <Container>{widgets.map(({ element }) => element)}</Container>
    </>
  );
};
