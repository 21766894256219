import React, { useEffect } from 'react';

import { format, parse } from 'date-fns';
import { defaultTo } from 'lodash';
import { useIntl } from 'react-intl';

import { useDialogModal } from 'hooks/use-dialog-modal';
import { useMediaQuery } from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import {
  getAddressMessage,
  getOrderingDisabledStoreSelectionMessage,
  isCatering,
} from 'utils/service-mode';

import { ChangeServiceModeLink } from '../change-service-mode-link';
import { AdditionalTextContainer } from '../styled';
import { IServiceModeAddressProps } from '../types';

export const ServiceModeAddress: React.FC<IServiceModeAddressProps> = ({
  serviceMode,
  userAddress,
  cateringPickupDateTime,
}) => {
  const { formatMessage } = useIntl();
  const { isStoreOpenAndAvailable, isStoreOpenAndUnavailable, noStoreSelected, store } =
    useStoreContext();
  const isMobile = useMediaQuery('mobile');
  const enableOrdering = defaultTo(useFlag(LaunchDarklyFlag.ENABLE_ORDERING), true);
  const { showStaticMenu } = useMenuContext();

  const addressMessage = getAddressMessage({
    store,
    noStoreSelected,
    isStoreOpenAndUnavailable,
    serviceMode,
    userAddress,
    formatMessage,
    isMobile,
  });

  const orderingDisabledStoreSelectionMessage = getOrderingDisabledStoreSelectionMessage({
    store,
    formatMessage,
    isStoreOpenAndAvailable,
  });

  // show time for catering pickup
  const additionalText =
    isCatering(serviceMode) &&
    cateringPickupDateTime &&
    format(parse(cateringPickupDateTime, 'yyyy-MM-dd h:mm aaaa', new Date()), 'MM/dd/yy h:mm aaaa');

  let primaryText = orderingDisabledStoreSelectionMessage;

  if (enableOrdering) {
    primaryText = showStaticMenu ? formatMessage({ id: 'forItemAvailability' }) : addressMessage;
  }

  /**
   * NMS-424: Temporary mechanism to alert PLK ES users of failing credit card payments
   * @todo DELETE
   */

  const iberiaWarningModalRestaurantNumbers: string[] =
    useFlag(LaunchDarklyFlag.IBERIA_WARNING_MODAL_NO_CREDIT_CARD_RESTAURANTS) || [];

  const showModalIberia = iberiaWarningModalRestaurantNumbers.indexOf(store.number || '') > -1;
  const [AlertDialog, openAlert, alertMessage] = useDialogModal({
    onConfirm: () => {
      return;
    },
    modalAppearanceEventMessage: 'Alert: Payment Failed',
  });

  useEffect(() => {
    if (showModalIberia) {
      openAlert({
        body: formatMessage({ id: 'noCreditCardAccept' }),
        buttonLabel: formatMessage({ id: 'accept' }),
        heading: formatMessage({ id: 'headsUpModalTitle' }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalIberia]);

  return (
    <div data-testid="service-mode-address">
      <AlertDialog {...(alertMessage || {})} />
      <h3>{primaryText}</h3>
      {additionalText && <AdditionalTextContainer>{additionalText}</AdditionalTextContainer>}
      <ChangeServiceModeLink
        storeSelected={isStoreOpenAndAvailable}
        orderingEnabled={enableOrdering}
      />
    </div>
  );
};
