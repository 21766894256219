import * as amplitude from '@amplitude/analytics-browser';

import { getApiKey } from 'utils/environment';
import { getCurrentVersion } from 'utils/live-updates';

export const initSdk = (callback: () => void) => {
  getCurrentVersion().then(async () => {
    await amplitude.init(getApiKey('amplitudeCdpKey'), undefined, {
      serverZone: getApiKey('amplitudeCdpRegion'),
      autocapture: {
        attribution: false,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
    });
    callback();
  });
};
