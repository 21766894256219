import React from 'react';

import { ISanityImage } from '@rbi-ctg/menu';
import { TypographyBlock } from 'components/features/components/typography-block';
import { IMarketingTileBasicFragment } from 'generated/sanity-graphql';

import {
  StyledTileContainer,
  TextContainer,
  TileImage,
  TileTitle,
} from './marketing-tile-list.styled';

interface MarketingTileListElementProps {
  basicTile: IMarketingTileBasicFragment;
}

export const MarketingTileListElement = ({ basicTile }: MarketingTileListElementProps) => {
  const description = basicTile.marketingBasicTileDescription?.localeRaw;
  const title = basicTile.marketingBasicTileTitle?.locale || '';
  const image = basicTile.marketingBasicTileImage?.locale?.app as ISanityImage;
  return (
    <StyledTileContainer
      key={`MarketingTileList_${basicTile._id}`}
      data-testid="marketing-tile-element"
    >
      {image && <TileImage image={image} alt={title} />}
      <TextContainer data-testid="marketing-tile-element-text-container">
        <TileTitle>{title}</TileTitle>
        <TypographyBlock color={Styles.color.black} content={description} />
      </TextContainer>
    </StyledTileContainer>
  );
};
