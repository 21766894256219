import * as React from 'react';
import { FC, useEffect } from 'react';

import { Icon } from '@rbilabs/components-library';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { useEffectOnce } from 'hooks/use-effect-once';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { EventName, emitEvent } from 'utils/event-hub';
import {
  PerformancePages,
  initializePagePerformance,
  pageLoaded,
  pageUnloaded,
  performanceReport,
} from 'utils/render-performance';
import { routes } from 'utils/routing';

import {
  BackButton,
  Container,
  PageHeader,
  PageName,
} from './loyalty-in-restaurant-redemption.styled';
import { LoyaltyInRestaurantRedemptionViewProps } from './types';

export const LoyaltyInRestaurantRedemptionView: FC<LoyaltyInRestaurantRedemptionViewProps> = ({
  widgets,
}) => {
  // Track page loading performance
  initializePagePerformance(PerformancePages.MY_CODE_PAGE);

  const { formatMessage } = useIntl();
  const { trackEvent } = useCdpContext();
  const { navigate, location } = useLocationContext();
  const { state } = location;

  const enableLoyaltyQRCodeHeaderButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_QR_CODE_HEADER_BUTTON
  );

  const handleBackButtonClick = () => {
    navigate(state?.backRoute || routes.base);
  };

  useEffectOnce(() => {
    emitEvent(EventName.SCREEN_RENDER, {
      screenId: 'inRestaurantRedemption',
    });
  });

  useEffect(() => {
    initializePagePerformance(PerformancePages.MY_CODE_PAGE);

    requestAnimationFrame(() => {
      // Page content loaded event
      pageLoaded(PerformancePages.MY_CODE_PAGE);

      trackEvent({
        name: CustomEventNames.LOYALTY_LOAD,
        type: EventTypes.Other,
        attributes: performanceReport(PerformancePages.MY_CODE_PAGE),
      });
    });

    return () => {
      pageUnloaded(PerformancePages.MY_CODE_PAGE);
    };
  }, [trackEvent]);

  return (
    <>
      <Helmet title={formatMessage({ id: 'redeemInRestaurant' })} />
      {enableLoyaltyQRCodeHeaderButton ? (
        <PageHeader>
          <BackButton onClick={handleBackButtonClick}>
            <Icon icon="back" color="icon-header-contrast" width="24px" aria-hidden />
          </BackButton>
          <PageName>{formatMessage({ id: 'yourInRestaurantOrderPageTitle' })}</PageName>
        </PageHeader>
      ) : null}
      <Container>{widgets.map(({ element }) => element)}</Container>
    </>
  );
};
