import React from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { GoogleGeolocationLibraryLoader } from 'components/google-geolocation-library-loader';
import { DeliveryConfirmation } from 'pages/order-confirmation/delivery/delivery-confirmation';

import { StyledModal } from './details-modal.styled';

export const IsEnRouteModal: React.FC<{
  serverOrder: IServerOrder;
  onModalDismiss: (orderId: string) => void;
}> = ({ serverOrder, onModalDismiss }) => {
  return (
    <StyledModal
      onDismiss={() => onModalDismiss('')}
      eventData={{
        modalAppearanceEventMessage: 'Order Details',
      }}
    >
      <GoogleGeolocationLibraryLoader>
        <DeliveryConfirmation serverOrder={serverOrder} isInsideModal />
      </GoogleGeolocationLibraryLoader>
    </StyledModal>
  );
};
