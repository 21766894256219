import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Fab } from 'components/fab';
import { IconLoyaltyMyCode } from 'components/icons/loyalty-my-code';
import { useEffectOnUpdates } from 'hooks/use-effect-on-updates';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useOrderContext } from 'state/order';
import { isMobile } from 'utils/environment';
import { routes } from 'utils/routing';

import { shouldDisplayFab } from './utils';

const Container = styled.div`
  position: relative;
  height: 0;

  button {
    padding-inline-start: 1.2rem;
    padding-inline-end: 1.2rem;
    padding-block-start: 1rem;
    padding-block-end: 0.9rem;
  }

  &.animate div > div::after {
    animation: scaleAnimate 1s ease-out;
  }

  @keyframes scaleAnimate {
    0% {
      transform: scale(1);
    }
    12% {
      transform: scale(1.5);
    }
    40% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const LoyaltyFab = ({ ...rest }) => {
  const { formatMessage } = useIntl();
  const { inRestaurantRedemptionCart, setIsDrawerOpen } = useInRestaurantRedemptionContext();
  const { pathname } = useLocation();
  const { navigate, location } = useLocationContext();
  const { numCartPreviewEntries } = useOrderContext();
  const { isAuthenticated } = useAuthContext();
  const [animationClassName, setAnimationClassName] = useState('');
  const enableLoyaltyFloatingCartButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_FLOATING_CART_BUTTON
  );
  const enableRedesignLoyaltyQRCodePage = useFlag(
    LaunchDarklyFlag.ENABLE_REDESIGN_LOYALTY_QR_CODE_PAGE
  );

  const allowedRoutes = [
    routes.base,
    routes.rewardsOffers,
    routes.rewardsList,
    routes.bkRewards,
    formatMessage({ id: 'routes.loyaltyHome' }),
    formatMessage({ id: 'routes.loyaltyDashboard' }),
    formatMessage({ id: 'routes.loyaltyOfferList' }),
    formatMessage({ id: 'routes.loyaltyRewardList' }),
  ];

  const isIncentiveDetailsPage =
    pathname.includes(routes.rewardsOffers) ||
    pathname.includes(routes.rewardsList) ||
    pathname.includes(formatMessage({ id: 'routes.loyaltyOfferList' })) ||
    pathname.includes(formatMessage({ id: 'routes.loyaltyDashboard' }));

  // The button should only be visible for certain pages
  const displayFab = isIncentiveDetailsPage || shouldDisplayFab(pathname, allowedRoutes);

  // The button will be rendered conditionally
  const shouldRender =
    enableLoyaltyFloatingCartButton &&
    isMobile() &&
    isAuthenticated() &&
    inRestaurantRedemptionCart.length &&
    !numCartPreviewEntries &&
    displayFab;

  const badgeCount = inRestaurantRedemptionCart.reduce(
    (acc, currentValue) => acc + currentValue.quantity,
    0
  );

  useEffectOnUpdates(() => {
    if (!shouldRender) {
      return;
    }

    // This makes the zoom-in animation start
    setAnimationClassName('animate');

    // Reset animation class so it doesn't trigger on refresh
    setTimeout(() => {
      setAnimationClassName('');
    }, 2000);
  }, [badgeCount]);

  const handleClick = useCallback(() => {
    if (enableRedesignLoyaltyQRCodePage) {
      setIsDrawerOpen(true);
      return;
    }

    navigate(routes.redeem, {
      state: {
        backRoute: location.pathname,
      },
    });
  }, [enableRedesignLoyaltyQRCodePage, location.pathname, navigate, setIsDrawerOpen]);

  // The loyalty floating button should render only when user is authenticated and has offers/rewards selected
  if (!shouldRender) {
    return null;
  }

  return (
    <Container data-testid="loyalty-fab-container" className={animationClassName}>
      <Fab
        caption={<IconLoyaltyMyCode />}
        badge={badgeCount}
        onClick={handleClick}
        eventAttributes={{ Name: formatMessage({ id: 'myCode' }) }}
        {...rest}
      />
    </Container>
  );
};
