import * as React from 'react';
import { FC } from 'react';

// import { useDialogModal } from 'hooks/use-dialog-modal';
import { noop } from 'lodash';

import { ConfirmDialog } from 'components/confirm-dialog';

import { useShortCodeOverrideModalContent } from './use-short-code-override-modal';

interface IShortCodeOverrideModalProps {
  onConfirm: VoidFunction;
  onDismiss?: VoidFunction;
  onCancel?: VoidFunction;
}

export const ShortCodeOverrideModal: FC<IShortCodeOverrideModalProps> = ({
  onConfirm,
  onDismiss = noop,
  onCancel = onDismiss,
}) => {
  const { loading, data: modalContent } = useShortCodeOverrideModalContent();

  if (loading) {
    return null;
  }

  return (
    <ConfirmDialog
      data-testid="ready-to-redeem-dialog"
      heading={modalContent?.header?.locale ?? ''}
      body={modalContent?.body?.locale}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
      onCancel={onCancel}
      confirmLabel={modalContent?.confirmButton?.locale ?? ''}
      cancelLabel={modalContent?.discardButton?.locale}
      modalAppearanceEventMessage="Loyalty Short Code Override Modal"
      showCloseButton
    />
  );
};
