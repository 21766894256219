import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox, Icon, PrimaryButton, SecondaryButton } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { List, ListItem } from 'components/list';
import { LoadingAnimation } from 'components/loading-animation';
import { useDialogModal } from 'hooks/use-dialog-modal';
import { useFeatureAccountUpdatedAgreements } from 'hooks/use-feature-account-updated-agreements';
import { UpdateAgreementAcceptance } from 'state/auth';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { IRequiredUserAcceptance, IRequiredUserAcceptanceList } from 'utils/user-agreements/types';

import { ModalAgreementDetail } from './modal-agreement-detail';
import {
  ContentCheckbox,
  ContentDescription,
  ContentHeader,
  ContentListLinks,
  LoadingContainer,
  ModalButtons,
  ModalContainer,
  ModalContent,
  ModalContentAgreements,
  ModalWrapper,
  Row,
  TitleModal,
} from './styled';

interface IModalAgreement {
  continueUpdate: (updateAgreementAcceptance: UpdateAgreementAcceptance) => Promise<any>;
  cancel: VoidFunction;
  eventData: IEventData;
  requiredTerms: IRequiredUserAcceptanceList;
  commPrefs: any[];
}

interface IModalAgreementEvent {
  Message?: string;
  ModalHeader?: string;
  ModalMessage?: string;
  ModalShowMarketingCheckbox?: boolean;
  ModalMarketingCheckboxValue?: boolean;
}

export const ModalAgreement = ({
  continueUpdate,
  cancel,
  eventData: { modalAppearanceEventMessage, modalHeader, modalMessage },
  requiredTerms,
  commPrefs = [],
}: IModalAgreement) => {
  const { formatMessage } = useIntl();
  const [isChecked, setcheck] = useState(false);
  const [isCheckedMarketing, setCheckedMarketing] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [showModalPolicies, setModalPolicies] = useState(false);
  const [newTerms, setNewTerms] = useState({} as IRequiredUserAcceptance);
  const { trackEvent } = useCdpContext();
  const [showMarketingCheckbox, setShowMarketingCheckbox] = useState(false);

  const {
    marketingCommunicationText,
    updateAgreementText,
    featureAccountUpdatedAgreementsLoading,
  } = useFeatureAccountUpdatedAgreements();

  const eventObj = useMemo(
    (): IModalAgreementEvent => ({
      Message: modalAppearanceEventMessage,
      ModalHeader: modalHeader,
      ModalMessage: modalMessage,
      ModalShowMarketingCheckbox: showMarketingCheckbox,
    }),
    [modalAppearanceEventMessage, modalHeader, modalMessage, showMarketingCheckbox]
  );

  const sendEvent = useCallback(
    (eventNames: CustomEventNames, eventTypes: EventTypes, message: string) => {
      if (showMarketingCheckbox) {
        eventObj.ModalMarketingCheckboxValue = isCheckedMarketing;
      }
      trackEvent({
        name: eventNames,
        type: eventTypes,
        attributes: { ...eventObj, Message: message },
      });
    },
    [eventObj, isCheckedMarketing, trackEvent, showMarketingCheckbox]
  );

  const [Confirm, openConfirm] = useDialogModal<{ id: string }>({
    onConfirm: () => {
      cancel();
    },
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Sign Out',
  });

  const requiredTermsSortedAlphabetically = useMemo(
    () =>
      [...requiredTerms].sort((a, b) => {
        const aTitle = a.localeTitle?.locale ?? a.title ?? '';
        const bTitle = b.localeTitle?.locale ?? b.title ?? '';
        return aTitle.localeCompare(bTitle);
      }),
    [requiredTerms]
  );

  const onClickDisagree = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openConfirm();

      sendEvent(
        CustomEventNames.BUTTON_CLICK_ACCEPTANCE_AGREEMENTS_DISAGREE,
        EventTypes.Navigation,
        'Disagree updated agreement and logout'
      );
    },
    [openConfirm, sendEvent]
  );

  const handleChange = useCallback(() => setcheck(previousValue => !previousValue), []);

  const handleChangeMarketing = useCallback(
    () => setCheckedMarketing(!isCheckedMarketing),
    [isCheckedMarketing]
  );

  const onClickContinue = useCallback(async () => {
    setLoadingModal(true);
    const result = await continueUpdate({
      acceptanceFromSanity: requiredTerms,
      emailMarketing: isCheckedMarketing,
    });

    if (result) {
      setLoadingModal(false);
    }

    sendEvent(
      CustomEventNames.BUTTON_CLICK_ACCEPTANCE_AGREEMENTS_CONTINUE,
      EventTypes.Navigation,
      'Accepted updated agreement and continue'
    );
  }, [continueUpdate, isCheckedMarketing, requiredTerms, sendEvent]);

  const openTermsModal = (term: IRequiredUserAcceptance) => {
    setNewTerms(term);
    setModalPolicies(true);
  };

  const closeTermsModal = useCallback(() => {
    setModalPolicies(false);
  }, []);

  useEffect(() => {
    const hasMarketingEmail = commPrefs.find(
      (comm: { id: string; value: string }) => comm.id === 'marketingEmail' && comm.value === 'true'
    );

    const hasMarketingPush = commPrefs.find(
      (comm: { id: string; value: string }) => comm.id === 'marketingPush' && comm.value === 'true'
    );

    const hasMarketingPrefs = hasMarketingEmail && hasMarketingPush;

    setShowMarketingCheckbox(!hasMarketingPrefs);

    trackEvent({
      name: CustomEventNames.MODAL_APPEARANCE,
      type: EventTypes.Other,
      attributes: {
        ...eventObj,
        ModalShowMarketingCheckbox: !hasMarketingPrefs,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalContainer>
        <ModalContentAgreements>
          {featureAccountUpdatedAgreementsLoading && (
            <LoadingContainer>
              <LoadingAnimation fillColor={Styles.color.primary} />
            </LoadingContainer>
          )}
          {!featureAccountUpdatedAgreementsLoading && (
            <ModalWrapper hasOverflow={!showModalPolicies}>
              <ModalContent>
                <ContentHeader>
                  <TitleModal aria-label={formatMessage({ id: 'updatedAgreements' })}>
                    {formatMessage({ id: 'updatedAgreements' })}
                  </TitleModal>
                </ContentHeader>
                <ContentDescription
                  aria-label={formatMessage({ id: 'reviewAcceptUpdatedAgreement' })}
                >
                  {formatMessage({ id: 'reviewAcceptUpdatedAgreement' })}
                </ContentDescription>
                <ContentCheckbox>
                  <Checkbox
                    aria-label={updateAgreementText}
                    label={updateAgreementText}
                    name="checkAgree"
                    checked={isChecked}
                    onChange={handleChange}
                  />
                </ContentCheckbox>
                <ContentListLinks>
                  <List>
                    {requiredTermsSortedAlphabetically.map((term: IRequiredUserAcceptance) => (
                      <ListItem key={term._id} onClick={() => openTermsModal(term)}>
                        <Row>
                          <span>{term.localeTitle?.locale || term.title}</span>
                          <Icon
                            icon="next"
                            aria-hidden
                            color="icon-default"
                            width="15px"
                            height="15px"
                          />
                        </Row>
                      </ListItem>
                    ))}
                  </List>
                </ContentListLinks>

                {showMarketingCheckbox && (
                  <ContentCheckbox>
                    <Checkbox
                      aria-label={marketingCommunicationText}
                      label={marketingCommunicationText}
                      name="checkMarketing"
                      checked={isCheckedMarketing}
                      onChange={handleChangeMarketing}
                    />
                  </ContentCheckbox>
                )}
                <ModalButtons>
                  <PrimaryButton
                    fullWidth
                    disabled={!isChecked}
                    aria-label={formatMessage({ id: 'continue' })}
                    onClick={onClickContinue}
                    loading={loadingModal}
                  >
                    {formatMessage({ id: 'continue' })}
                  </PrimaryButton>
                  <SecondaryButton
                    fullWidth
                    aria-label={formatMessage({ id: 'disagreeLogout' })}
                    onClick={onClickDisagree}
                  >
                    {formatMessage({ id: 'disagreeLogout' })}
                  </SecondaryButton>
                </ModalButtons>
                <Confirm
                  heading={formatMessage({ id: 'signOut' })}
                  body={formatMessage({ id: 'signOutConfirmSubheader' })}
                  confirmLabel={formatMessage({ id: 'yes' })}
                  cancelLabel={formatMessage({ id: 'no' })}
                />
              </ModalContent>

              {showModalPolicies && (
                <ModalAgreementDetail onBack={closeTermsModal} termsObject={newTerms} />
              )}
            </ModalWrapper>
          )}
        </ModalContentAgreements>
      </ModalContainer>
    </>
  );
};
