import styled, { css } from 'styled-components';

import { ActionButton } from 'components/action-button';
import { brandFont } from 'components/layout/brand-font';
import { DrawerContainer } from 'components/navigation-drawer/navigation-drawer.styled';
import { primitive } from 'styles/constants/primitives';

import { OverviewContainer } from './product-overview/product-overview.styled';

const baseStyles = css<{ $windowHeight: number }>`
  min-height: calc(${p => p.$windowHeight}px - ${Styles.layout.navHeight.mobile});
  ${Styles.desktopLarge} {
    height: 100%;
    min-height: auto;
    position: relative;
    margin-block-start: ${primitive.$spacing2};
    margin-block-end: ${primitive.$spacing6};
  }
`;

const displayFlex = css`
  display: flex;
  flex-direction: column;

  ${Styles.desktopLarge} {
    flex-direction: row;
    gap: ${primitive.$spacing6};
  }
`;

const displayBlock = css`
  display: block;
`;

export const LayoutContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${primitive.$white};
  border-radius: 10px;
`;

export const LayoutContent = styled.div<{ $windowHeight: number; isStoreSelected: boolean }>`
  ${baseStyles}
  ${p => (p.isStoreSelected ? displayBlock : displayFlex)}
`;

export const LayoutContainer = styled.div<{ $isStatic?: boolean }>`
  display: flex;
  flex-direction: column;

  ${Styles.desktopLarge} {
    position: relative;
    width: ${(p: { $isStatic?: boolean }) =>
      p.$isStatic ? Styles.layout.sectionMaxWidth : '1136px'};
    margin: 0 auto;
    padding-block-end: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? '2rem' : '0')};
    margin-block-start: ${p => p.theme.token('margin-top-product-wizard-layout')};
    ${OverviewContainer} {
      width: ${(p: { $isStatic?: boolean }) => (p.$isStatic ? 'inherit' : '60%')};
    }
    ${LayoutContainerWrapper} {
      width: 40%;
      position: relative;
      ${DrawerContainer} {
        height: 100%;
      }
    }
  }
`;

export const ProductNoteText = styled.p`
  font: ${brandFont.copyTwo};
  margin: 0;
`;

export const StyledActionButton = styled(ActionButton)`
  font: ${brandFont.headerThree};
  padding: 0.75rem 3rem;
  font-size: 1rem;

  ${Styles.mobileSmall} {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  ${Styles.mobileTiny} {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
`;

export const StyledNumberInputWrapper = styled.div`
  input[type='number'] {
    width: 3rem;
    margin: 0 0.5rem;
  }

  ${Styles.mobileTiny} {
    input[type='number'] {
      width: 1rem;
      margin: 0 0.1rem;
    }
  }

  ${Styles.mobileSmall} {
    input[type='number'] {
      width: 1.5rem;
      margin: 0 0.2rem;
    }
  }
`;

export const StyledOverviewText = styled.span`
  margin: 0 ${primitive.$spacing2};
`;

export const DesktopMainViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const CheckAvailabilityButton = styled.span`
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ScrollableArea = styled.div`
  overflow: auto;
`;
