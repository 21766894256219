import React, { useState } from 'react';

import {
  IStoreDiagnosticData,
  InternalStoreDiagnosticModal,
} from 'components/store-diagnostic-modal';

import { DiagnosticCogIconView } from './diagnostic-cog-icon.view';

const DiagnosticCogIconContainer: React.FC<{
  storeDiagnosticData: IStoreDiagnosticData | undefined;
}> = ({ storeDiagnosticData }) => {
  const [showInternalDiagnosticsModal, setShowInternalDiagnosticsModal] = useState(false);

  const onModalDismiss = () => {
    setShowInternalDiagnosticsModal(false);
  };

  const onModalShow = () => {
    setShowInternalDiagnosticsModal(true);
  };

  return (
    <>
      <DiagnosticCogIconView onClick={onModalShow} />
      {showInternalDiagnosticsModal && (
        <InternalStoreDiagnosticModal
          onModalDismiss={onModalDismiss}
          storeDiagnosticData={storeDiagnosticData}
        />
      )}
    </>
  );
};

export const DiagnosticCogIcon = DiagnosticCogIconContainer;
