import { Loggable } from '@rbi-ctg/frontend';
import { env } from 'utils/environment';

import { DEFAULT_HANDLERS, DEFAULT_MIDDLEWARES } from './constants';
import { createContextManager } from './context';
import { ErrorCategory } from './middleware';
import { ICreateLoggerOptions, ILogger, LogLevel, TContext, TLogFunction, TMethod } from './types';

const methods: TMethod[] = ['debug', 'error', 'fatal', 'info', 'trace', 'warn'];

export const prepareMessage = (message: Loggable, level: LogLevel, ctx: TContext): TContext => {
  if (message instanceof Error) {
    return { error: message, ...ctx, level };
  }

  if (typeof message === 'object') {
    return { ...message, ...ctx, level };
  }

  return { message, ...ctx, level };
};

export const createLogger = <T extends TContext>(
  initialContext: T,
  options: ICreateLoggerOptions = {}
): ILogger<T> => {
  const { middlewares = [], currentEnv = env(), handlers = DEFAULT_HANDLERS } = options;

  const ctx = createContextManager(initialContext);

  const logFunction =
    (method: TMethod): TLogFunction =>
    (message, extraContext) => {
      const level = LogLevel[method];

      const rawContext = extraContext ? { ...ctx.getContext(), ...extraContext } : ctx.getContext();
      const context = middlewares.reduce<TContext>(
        (attr, middleware) => middleware(attr),
        prepareMessage(message, level, rawContext)
      );

      if (context?.category === ErrorCategory.DoNotLog) {
        return;
      }

      for (const handleLog of handlers) {
        handleLog({ message, context, level, method, env: currentEnv });
      }
    };

  const loggerPublicInterface = methods.reduce<ILogger<T>>((logger, method) => {
    return { ...logger, [method]: logFunction(method) };
  }, {} as ILogger<T>);

  loggerPublicInterface.child = (
    childInitialContext,
    {
      middlewares: childMiddlewares = DEFAULT_MIDDLEWARES,
      handlers: childHandlers = DEFAULT_HANDLERS,
    } = {}
  ) =>
    createLogger(
      { ...ctx.getContext(), ...childInitialContext },
      {
        middlewares: middlewares.concat(childMiddlewares),
        handlers: childHandlers,
      }
    );

  loggerPublicInterface.getContext = ctx.getContext;
  loggerPublicInterface.setContext = ctx.setContext;

  return loggerPublicInterface;
};
