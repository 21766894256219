import { useEffect, useRef } from 'react';

const EVENT_NAME = 'mousedown';

export const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: VoidFunction,
  buttonRef: React.RefObject<HTMLElement>
) => {
  const blockingElements = useRef([ref.current, buttonRef.current]);
  const handleClickInside = (event: MouseEvent) => {
    event.stopPropagation();
  };
  const addBlockingListener = (el: HTMLElement) => {
    el?.addEventListener(EVENT_NAME, handleClickInside);
  };
  const removeBlockingListener = (el: HTMLElement) => {
    el?.removeEventListener(EVENT_NAME, handleClickInside);
  };

  useEffect(() => {
    blockingElements.current = [ref.current, buttonRef.current];
    document.addEventListener(EVENT_NAME, callback);
    blockingElements.current.forEach(addBlockingListener);
    return () => {
      document.removeEventListener(EVENT_NAME, callback);
      blockingElements.current.forEach(removeBlockingListener);
    };
  }, [ref, buttonRef, callback]);
};
