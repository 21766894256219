import styled from 'styled-components';

export const UnstyledButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  font-family: ${Styles.fontFamily.base};
`;
