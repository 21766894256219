import React, { PureComponent, createContext, useCallback, useContext, useRef } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ErrorBoundaryStatic } from 'components/error-boundary-static';
import { LogLevel, logger } from 'utils/logger';

import { useLocationContext } from '../location';

interface IErrorBoundaryProps extends IBaseProps {
  onError: (e: Error, meta: object) => void;
}

export interface IErrorCtx {
  handleError: (e: Error, meta?: object) => void;
  setCurrentOrderId: (id: string) => void;
  // This ref is used to provide the current sanity data that was fetched when the app crashed for datadog logging purposes.
  setSanityDataRef: (data: any) => void;
}

type SanityData = Record<string, any> | Array<Record<string, any>> | undefined;

export const ErrorContext = createContext<IErrorCtx>({} as any as IErrorCtx);
export const useErrorContext = () => useContext(ErrorContext);

/**
 * `componentDidCatch` is only available
 * on Class components :(
 **/
class TopLevelErrorBoundary extends PureComponent<IErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    const { onError } = this.props;
    onError(error, { info, level: LogLevel.fatal });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryStatic />;
    }
    return this.props.children;
  }
}

export function ErrorsProvider({ children }: IBaseProps) {
  const locationContext = useLocationContext();
  const location = locationContext ? locationContext.location : { pathname: '/' };
  const orderId = useRef<string>('');
  const sanityDataRef = useRef<SanityData>();
  const setCurrentOrderId = useCallback((serverOrderId: string) => {
    orderId.current = serverOrderId;
  }, []);

  const setSanityDataRef = useCallback((data: any) => {
    sanityDataRef.current = data;
  }, []);

  const sanityData = sanityDataRef.current;

  const handleError = useCallback(
    (error: Error, meta: object = {}) => {
      const errorMeta = {
        ...meta,
        'Transaction RBI Cloud Order ID': orderId.current,
        'Source Page': location.pathname,
        errorBoundary: true,
        containsSanityData: !!sanityData,
        // Only apply the sanity data key if sanity data is available
        ...(sanityData && { sanityData }),
      };
      logger.error({ error, ...errorMeta });
      // If sanity data was present, clear the data after logging.
      if (sanityDataRef.current) {
        setSanityDataRef(undefined);
      }
    },
    [location.pathname, setSanityDataRef, sanityData]
  );

  return (
    <ErrorContext.Provider value={{ handleError, setCurrentOrderId, setSanityDataRef }}>
      <TopLevelErrorBoundary onError={handleError}>{children}</TopLevelErrorBoundary>
    </ErrorContext.Provider>
  );
}
