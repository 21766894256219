import { Language, asLanguage } from '@rbilabs/intl';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import { LocalStorage, StorageKeys } from 'utils/local-storage';
import * as location from 'utils/location';

import {
  extractLanguageCode,
  getDefaultMarketLanguage,
  getSupportedMarketLanguage,
  getSupportedMarketLanguages,
  isSupportedMarketLanguage,
} from './language';

export const LANGUAGE_SEARCH_PARAM = 'lang';

export const getLanguageFromUrlPath = (pathname: string) => {
  const maybeLanguageCode = pathname.split('/')?.[1] || '';

  return asLanguage(maybeLanguageCode);
};

export const getLanguageFromQueryString = () =>
  location.getSearchParams().get(LANGUAGE_SEARCH_PARAM);

export const getLanguageFromUserPreferences = () =>
  LocalStorage.getItem<SupportedLanguages>(StorageKeys.LANGUAGE);

export const getNavigatorLanguages = () => {
  const maybeLocales = [...(window?.navigator?.languages ?? []), window?.navigator?.language];

  return maybeLocales.filter(Boolean).map(extractLanguageCode);
};

/**
 * Retrieves the language requested by the user.
 *
 * This takes into account:
 *  - The URL path
 *  - Query string parameters
 *  - Saved user preferences
 *  - User language settings as reported by the browser/device
 *  - The market's default language
 *
 * @returns Language
 */
export const getInferredLanguage = () => {
  const inferredLanguages = [
    getLanguageFromUrlPath(location.get('pathname')),
    getLanguageFromQueryString(),
    getLanguageFromUserPreferences(),
    ...getNavigatorLanguages(),
    getDefaultMarketLanguage(),
  ]
    .filter(Boolean)
    .map(asLanguage)
    .filter((maybeLanguage): maybeLanguage is Language => Boolean(maybeLanguage));
  const inferredAndSupportedLanguages = inferredLanguages.filter(isSupportedMarketLanguage);

  return inferredAndSupportedLanguages[0];
};

/**
 * Retrieves the application language.
 *
 * The user-requested language will be taken into account only when the market
 * is multilingual or when the `useDefault` argument is set to true.
 *
 * @param useDefault when true, the default market language is used, hence
 *  ignoring any explicit language requests.
 * @returns Language
 */
export const getAppLanguage = (useDefault = false) => {
  return getSupportedMarketLanguage(getInferredLanguage(), useDefault);
};

/**
 * Given a set of LD flags, determines whether the market should use language
 * codes in its URL paths or not.
 *
 * @param disableLocalization value of `disableLocalization` LD flag.
 * @returns whether language codes should be used in URL paths or not.
 */
export const shouldUseLanguageCodeInUrls = (disableLocalization: boolean) => {
  const marketIsMultilingual = getSupportedMarketLanguages().length > 1;
  return !disableLocalization && marketIsMultilingual;
};

/**
 * Determines whether a path is eligible or not for a redirect that includes the
 * language code prepended to the path.
 *
 * The assumption for calling this function is that
 * `shouldUseLanguageCodeInUrls()` returns true.
 *
 * @param pathname
 * @returns whether a redirect should happen or not.
 */
export const shouldRedirectToLanguagePath = (pathname: string) => {
  const languageOrPath = getLanguageFromUrlPath(pathname);
  return !languageOrPath || (languageOrPath && !isSupportedMarketLanguage(languageOrPath));
};
