import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import { Picture } from 'components/picture';
import { SanityBlockRenderer } from 'components/sanity-block-renderer';

export const ModalContainer = styled.div``;

export const SectionImage = styled(Picture)`
  max-width: 80%;
  margin-block-start: 0;
  margin-block-end: 2rem;
  margin-inline: auto;
  height: 250px;
`;

export const SectionTitle = styled.h1`
  text-align: start;
  font: ${brandFont.headerOne};
  margin: 0;
  margin-block-end: 1rem;
`;

export const StyledBlockRenderer = styled(SanityBlockRenderer)`
  margin-block-end: 1rem;
`;
