import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useCdpContext } from 'state/cdp';
import { ClickEventComponentNames, CustomEventNames, EventTypes } from 'state/cdp/constants';
import { clamp } from 'utils/calc';
import { HapticsImpactStyle, hapticImpact } from 'utils/haptic';

import { Button } from './button';
import { DisplayValue } from './display-value';
import { MinusIcon } from './minus-icon';
import { PlusIcon } from './plus-icon';
import { Value } from './value';

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Display = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 0.9375rem;
  font-family: ${Styles.fontFamily.body};
`;

const Controller = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 0.9375rem;
  font-family: ${Styles.fontFamily.body};
  font-weight: 500;
  justify-content: flex-end;
`;

interface IDisabled {
  disabled?: boolean;
}

export enum IncrementorActions {
  Increment = 'increment',
  Decrement = 'decrement',
}

export interface IIncrementor {
  children?: React.ReactNode;
  value: number;
  valueDisplayTransformer?: (v: number) => number | string;
  onChange(v: number, incrementorActions: IncrementorActions): void;
  border?: boolean;
  'data-testId'?: string | null;
  'aria-label': string;
  className?: string;
  min?: number;
  max?: number;
  name?: string;
  valueWidth?: string;
  IncrementButton?: <T extends IDisabled>(props: T) => JSX.Element;
  DecrementButton?: <T extends IDisabled>(props: T) => JSX.Element;
  disableIncrement?: boolean;
  disableDecrement?: boolean;
  disabled?: boolean;
}

export const Incrementor = ({
  name,
  children,
  value = 0,
  valueDisplayTransformer = v => v,
  onChange,
  border = false,
  'data-testId': dataTestId,
  'aria-label': ariaLabel,
  className,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  valueWidth,
  DecrementButton = MinusIcon,
  IncrementButton = PlusIcon,
  disableIncrement,
  disableDecrement,
  disabled,
}: IIncrementor) => {
  const clampV = useMemo(() => clamp(min, max), [min, max]);
  const decrementDisabled = disabled || disableDecrement || value <= min;
  const incrementDisabled = disabled || disableIncrement || value >= max;
  const { formatMessage } = useIntl();

  const [decrementAriaLabel, incrementAriaLabel] = useMemo(() => {
    return [
      formatMessage({ id: 'decrementName' }, { name }),
      formatMessage({ id: 'incrementName' }, { name }),
    ];
  }, [formatMessage, name]);

  const valueId = useMemo(() => `item-quantity-${name?.replace(/\s+/g, '-')}`, [name]);

  const cdp = useCdpContext();
  const handleMutateQuantityClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    increment: boolean
  ) => {
    hapticImpact({ style: HapticsImpactStyle.Light });
    cdp.trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: increment ? 'Checkout Product Incrementor' : 'Checkout Product Decrementor',
      },
    });

    e.preventDefault();
    if (increment) {
      onChange(clampV(value + 1), IncrementorActions.Increment);
    } else {
      onChange(clampV(value - 1), IncrementorActions.Decrement);
    }
  };

  return (
    <Container data-testid="mutate-quantity" aria-label={ariaLabel} className={className}>
      {children && <Display>{children}</Display>}
      <Controller>
        <Button
          data-testid={IncrementorActions.Decrement}
          onClick={e => handleMutateQuantityClick(e, false)}
          disabled={decrementDisabled}
          aria-label={decrementAriaLabel}
        >
          <DecrementButton disabled={decrementDisabled} />
        </Button>
        <Value id={valueId} width={valueWidth} data-testid={dataTestId} border={border}>
          <DisplayValue value={valueDisplayTransformer(value)} />
        </Value>
        <Button
          data-testid={IncrementorActions.Increment}
          onClick={e => handleMutateQuantityClick(e, true)}
          disabled={incrementDisabled}
          aria-label={incrementAriaLabel}
        >
          <IncrementButton disabled={incrementDisabled} />
        </Button>
      </Controller>
    </Container>
  );
};
