import { useState } from 'react';

import { useEffectOnce } from 'hooks/use-effect-once';
import { getDeviceId } from 'utils/device-id';
import { releaseTagRef } from 'utils/environment';
import { getCurrentVersion } from 'utils/live-updates';

type AppInfo = Partial<Awaited<ReturnType<typeof getCurrentVersion>>>;

export const useAppInfo = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>({});
  const [deviceId, setDeviceId] = useState('');

  useEffectOnce(() => {
    const load = async () => {
      const currentVersion = await getCurrentVersion();
      const deviceId = await getDeviceId();

      setDeviceId(deviceId);
      setAppInfo(currentVersion);
    };

    load();
  });

  return {
    ...appInfo,
    deviceId,
    releaseTagRef,
  };
};
