import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ActionButton } from 'components/action-button';

export const HeaderContainer = styled.div`
  padding-block-start: env(safe-area-inset-top);
  padding-inline-end: env(safe-area-inset-right);
  padding-inline-start: env(safe-area-inset-left);
  width: 100%;
  background: ${p => p.theme.token('background-hero-light-native')};
  z-index: ${Styles.zIndex.overlay};

  ${Styles.desktopLarge} {
    display: ${props => props['aria-hidden'] && `none`};
    z-index: ${Styles.zIndex.desktopHeader};
    background-color: transparent;
  }
`;

export const NavBar = styled.div`
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 2fr max-content 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'start center end';
  grid-auto-flow: column;
  height: ${Styles.layout.navHeight.mobile};
  padding-inline: 16px;
  text-align: center;
  width: 100%;
  overflow-y: hidden;

  ${Styles.desktopLarge} {
    background: ${p => p.theme.token('background-hero-light')};
    height: ${Styles.layout.navHeight.desktop};
    padding-inline: 30px;
  }
`;

export const NavSectionContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.3rem;
  width: 100%;

  ${Styles.desktopLarge} {
    padding: 0.313rem;
  }
`;

export const PrimaryActionContainer = styled(NavSectionContainer)`
  grid-area: start;
  justify-self: start;
  justify-content: flex-start;
`;

export const SecondaryActionContainer = styled(NavSectionContainer)`
  grid-area: end;
  justify-self: end;
  justify-content: flex-end;
  gap: 1rem;
`;

export const LogoContainer = styled.div`
  grid-area: center;
  justify-self: center;
  padding-inline: 0.25rem;

  ${Styles.desktopLarge} {
    padding-inline: 1rem;
  }
`;

export const LinkNoDecoration = styled(Link)`
  &::before {
    color: transparent;
  }
  &:active,
  &:hover,
  &:link,
  &:visited {
    color: inherit;
  }
`;

export const StyledLoyaltyButton = styled(ActionButton)`
  border-color: ${Styles.color.contrastBackground};
  color: ${Styles.color.contrastBackground};
  padding: 0.375rem 0.725rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSignupButton = styled(ActionButton)`
  padding-inline: 0.725rem;
`;

export const SignupButtonWrapper = styled.div`
  min-width: 0;
`;

export const TopServiceModeContainer = styled.div`
  padding-inline: 1.125rem 1rem;
  padding-block: 6px;
  box-shadow: ${Styles.boxShadow};
  z-index: ${Styles.zIndex.min};
`;
