import { ICartEntry } from '@rbi-ctg/menu';
import { IPromotionInput, PromotionType, SwapType } from 'generated/graphql-gateway';
import { ISwapInput } from 'generated/rbi-graphql';
import {
  IAppliedRewards,
  IIncentiveEvaluationResult,
  IncentiveEvaluationMap,
} from 'state/loyalty/hooks/types';
import { LoyaltyAppliedOffer, LoyaltyOffer } from 'state/loyalty/types';
import { LocalStorage, StorageKeys } from 'utils/local-storage';
import { parseStringifiedJSON } from 'utils/parse-string';

import { ICartEntryAdapter, IEntriesIdsMap } from './offers.types';

export const getAppliedOffersFromStorage: () => LoyaltyAppliedOffer[] = () => {
  const storedAppliedOffers = LocalStorage.getItem(StorageKeys.APPLIED_LOYALTY_OFFERS);
  return parseStringifiedJSON({ value: storedAppliedOffers, defaultValue: [] }) || [];
};

export const updateAppliedOffersInStorage = (appliedOffers: LoyaltyAppliedOffer[]) => {
  LocalStorage.setItem(StorageKeys.APPLIED_LOYALTY_OFFERS, JSON.stringify(appliedOffers));
};

export const removeAppliedOffersInStorage = () => {
  LocalStorage.removeItem(StorageKeys.APPLIED_LOYALTY_OFFERS);
};

export const getSelectedOfferFromStorage = () => {
  const storedAppliedOffers = LocalStorage.getItem(StorageKeys.SELECTED_LOYALTY_OFFER);
  return parseStringifiedJSON({ value: storedAppliedOffers, defaultValue: null }) || null;
};

export const updateSelectedOfferInStorage = (selectedOffer: LoyaltyOffer | null) => {
  LocalStorage.setItem(StorageKeys.SELECTED_LOYALTY_OFFER, JSON.stringify(selectedOffer));
};

export const removeSelectedOfferInStorage = () => {
  LocalStorage.removeItem(StorageKeys.SELECTED_LOYALTY_OFFER);
};

// Creates a flat map of all cart entries with sanityId property as key
export const flattenEntriesToMap: (
  acc: IEntriesIdsMap,
  entry: ICartEntryAdapter
) => IEntriesIdsMap = (acc: IEntriesIdsMap, entry: ICartEntryAdapter) => ({
  ...acc,
  [entry.sanityId]: true,
  ...entry.children?.reduce(flattenEntriesToMap, {}),
});

// Function maps from ICartEntry to ICartEntryAdapter to send entries to BE
// Also filters entries that have a reward applied
export const parseEntry =
  (appliedLoyaltyRewards?: IAppliedRewards | null) =>
  (acc: ICartEntryAdapter[], { _id, cartId, price = 0, quantity = 1, children }: ICartEntry) => {
    const rewardApplied = appliedLoyaltyRewards?.[cartId];
    if (rewardApplied?.timesApplied === quantity) {
      return acc;
    }

    const parsed: ICartEntryAdapter = {
      sanityId: _id,
      lineId: cartId,
      price,
      quantity,
    };
    if (children.length) {
      parsed.children = children.reduce(parseEntry(appliedLoyaltyRewards), []);
    }
    return [...acc, parsed];
  };

export const parseOffer = ({
  cartId: lineId,
  id,
  swap,
}: LoyaltyAppliedOffer): IPromotionInput | null => {
  let result = null;
  if (id) {
    result = {
      ...(swap && { swap: parseSwap(swap) }),
      id,
      lineId,
      type: PromotionType.OFFER,
    };
  }

  return result;
};

export const parseOffers = (appliedLoyaltyOffers: LoyaltyAppliedOffer[]) =>
  appliedLoyaltyOffers.reduce((acc: IPromotionInput[], offer: LoyaltyAppliedOffer) => {
    const parsedOffer = parseOffer(offer);
    if (parsedOffer) {
      acc.push(parsedOffer);
    }

    return acc;
  }, []);

const parseSwap = ({ cartId: lineId, to, from, swapType }: ISwapInput) => ({
  to,
  from,
  lineId,
  type: swapType as SwapType,
});

export const incentiveErrorsFilteredList = (
  offersFeedbackMap: IncentiveEvaluationMap,
  filterCallback: (item: IIncentiveEvaluationResult) => boolean
) => {
  return Object.values(offersFeedbackMap).flat().filter(filterCallback);
};
