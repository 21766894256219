import React from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ActionButton, ActionButtonVariants } from 'components/action-button';
import { useStoreCard } from 'hooks/store-card';
import { useMediaQuery } from 'hooks/use-media-query';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

import { ICardFooterProps } from './types';

export const StoreCardFooter: React.FC<ICardFooterProps> = ({
  isRestaurantSelectable,
  restaurant,
  selectRestaurant,
}: ICardFooterProps) => {
  const { formatMessage } = useIntl();
  const isDesktop = useMediaQuery('desktop');
  const { enableOrdering, storeSEOSlug } = useStoreCard({ restaurant });
  const { allServiceModesUnavailable } = useServiceModeStatus(restaurant);
  const { isRestaurantPosOnline, loading: isAvailabilityLoading } =
    useRestaurantPosConnectivityStatus({ storeId: restaurant?.number ?? '' });
  const { serviceMode } = useServiceModeContext();
  const isCateringServiceMode = isCatering(serviceMode);
  const navigate = useNavigate();

  // This is the same as TH's moreInfoRoute on <StoreCard/>
  // Relative canonical urls are technically permitted.
  // However, because the code sometimes stacks relative urls, be careful!
  const storeUrl = `${routes.store}/${storeSEOSlug}`;
  const storeLabel =
    restaurant.customerFacingAddress?.locale || restaurant.physicalAddress?.address1 || '';

  const isEnabled =
    isCateringServiceMode ||
    (enableOrdering &&
      isRestaurantSelectable &&
      !allServiceModesUnavailable &&
      isRestaurantPosOnline);

  const orderOrSelectButtonText = formatMessage(
    enableOrdering
      ? isRestaurantSelectable
        ? { id: 'orderHere' }
        : { id: 'onlineSoon' }
      : { id: 'select' }
  );

  return (
    <Box flexDirection={isDesktop ? 'row' : 'column'} center width="100%">
      <Box width="100%" minWidth="auto">
        <ActionButton
          variant={ActionButtonVariants.OUTLINE}
          fullWidth
          onClick={() => navigate(storeUrl)}
          aria-label={formatMessage({ id: 'storeInfoAssistive' }, { storeLabel })}
        >
          {formatMessage({ id: 'moreInfo' })}
        </ActionButton>
      </Box>
      <Box margin={isDesktop ? '0 0 0 1rem' : '1rem 0 0'} width="100%" minWidth="auto">
        <ActionButton
          data-testid={enableOrdering ? 'order-here' : 'select-this-store'}
          fullWidth
          disabled={!isEnabled}
          onClick={selectRestaurant}
          aria-label={formatMessage(
            enableOrdering ? { id: 'orderAtThisStore' } : { id: 'selectThisStore' },
            { storeLabel }
          )}
          isLoading={isAvailabilityLoading}
        >
          {orderOrSelectButtonText}
        </ActionButton>
      </Box>
    </Box>
  );
};
