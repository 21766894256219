import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';

import { useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { merge } from 'lodash';

import { IBaseProps } from '@rbi-ctg/frontend';
import { MenuObject, PremiumComboSlotPricingVarations, SanityMenuObject } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus, MenuObjectTypes } from 'enums/menu';
import {
  GetComboAvailabilityDocument,
  GetComboDocument,
  GetItemAvailabilityDocument,
  GetItemDocument,
  GetPickerAvailabilityDocument,
  GetPickerDocument,
} from 'generated/sanity-graphql';
import { usePosVendor } from 'hooks/menu/use-pos-vendor';
import { useConvertPluConfigs } from 'hooks/use-convert-plu-configs';
import { useForceUpdate } from 'hooks/use-force-update';
import { useImagesByChannels } from 'hooks/use-images-by-channels';
import { fixTypes } from 'remote/api/menu-objects';
import { MenuDataError, NotFoundMenuItemError } from 'remote/exceptions';
import { useDayPartContext } from 'state/day-part';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import { usePosDataQuery } from 'state/store/hooks/use-pos-data-query';
import { isAvailableForActiveDayParts } from 'utils/availability';
import { useSanityGqlEndpoint } from 'utils/network';
import { PosVendors } from 'utils/vendor-config';

import { applyFiltersForAvailability } from './apply-filters-for-availability';
import { filterForStaticMenu } from './filter-for-static-menu';
import { usePricingFunction } from './hooks/use-pricing-function';
import { remapItemOptions } from './remap-item-options';

interface ICheckItemAvailability {
  vendor: PosVendors | null;
  itemId: string;
  restaurantPosDataId: string;
  storeNumber: string;
}

export type ItemAvailability = {
  availabilityStatus: ItemAvailabilityStatus;
  data?: MenuObject;
};

export type IMenuOptionsContext = {
  getMenuObject(id: string): IState;
  asyncGetMenuObject(id: string, customFilter?: ICustomFilter): Promise<IState>;
  asyncGetRawMenuObject(id: string): Promise<IState['data']>;
  filterForAvailability(
    menuData: SanityMenuObject[] | SanityMenuObject
  ): MenuObject | MenuObject[] | null;
  checkItemAvailability(args: ICheckItemAvailability): Promise<ItemAvailability>;
} & ReturnType<typeof usePricingFunction>;

export interface IState {
  data: MenuObject | null;
  loading: boolean;
}
interface ICustomFilter {
  (data: SanityMenuObject): MenuObject | MenuObject[] | null;
}

export const MenuOptionsContext = createContext<IMenuOptionsContext>({} as IMenuOptionsContext);
export const useMenuOptionsContext = () => useContext(MenuOptionsContext);

export const LegacyMenuOptions = MenuOptionsContext.Consumer;

export const MenuOptionsProvider = ({ children }: IBaseProps) => {
  const { activeDayParts, dayParts } = useDayPartContext();
  const { isPricesLoading, prices, store } = useStoreContext();
  const { vendor: posVendor } = usePosVendor();
  const menu = useRef<Record<string, IState>>({});
  const { locale } = useLocale();
  const client = useApolloClient();
  const uri = useSanityGqlEndpoint();
  const { showStaticMenu } = useMenuContext();
  const { refetch: getPosData } = usePosDataQuery({
    storeNumber: null,
    restaurantPosDataId: null,
    lazy: true,
  });
  const premiumComboSlotPricingMethod = useFlag<PremiumComboSlotPricingVarations>(
    LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS
  );

  const { convertDeep } = useConvertPluConfigs();

  const { changeImageByChannel } = useImagesByChannels();

  // a small hack until our menu data store is more reactive
  // forces a re-render upon successful menu data load
  const forceUpdate = useCallback(useForceUpdate(), [useForceUpdate]);

  const filterForAvailability = useCallback(
    applyFiltersForAvailability({
      activeDayParts,
      dayParts,
      prices,
      vendor: posVendor,
      enableCompositeComboSlotPlus: premiumComboSlotPricingMethod === 'composite',
    }),
    [activeDayParts, dayParts, prices, posVendor]
  );

  const filterForAvailabilityMemo = useCallback(filterForAvailability, [filterForAvailability]);

  const filterData = showStaticMenu ? filterForStaticMenu : filterForAvailabilityMemo;

  const {
    priceForItemOptionModifier,
    priceForComboSlotSelection,
    pricingFunction,
    priceForItemInComboSlotSelection,
  } = usePricingFunction({
    prices,
    vendor: posVendor,
  });

  const setMenuData = useCallback(
    (
      menuData: SanityMenuObject,
      menuDataLoading: boolean,
      itemID: string,
      skipForceUpdate = false,
      customFilter?: ICustomFilter
    ) => {
      const fixedData = menuData && fixTypes(menuData);
      const filter = customFilter || filterData;
      const filteredData = filter(fixedData);

      let sectionOptions = {};
      // if its a section we can pull out the pickers, combos and items to preload the data into their own keys (This saves wizard from having to make the same queries again)
      // NOTE: Checking if filteredData is an array here as a typecheck... We should never hit that case here. I am assuming its because the filter function is used somewhere else and could return an array of data
      if (!Array.isArray(filteredData) && filteredData?._type === MenuObjectTypes.SECTION) {
        // typecasting as the filterForStaticMenu returns sanity types while applyFiltersForAvailability returns manual menu type
        sectionOptions = (filteredData.options as MenuObject[]).reduce(
          (mappedOptions: any, option) => {
            return {
              ...mappedOptions,
              [option._id]: {
                loading: menuDataLoading,
                data: option,
                id: option._id,
              },
            };
          },
          {}
        );
      }

      menu.current = {
        ...menu.current,
        // preload all the pickers, combos, and items inside a section so we don't need to query again for them
        ...sectionOptions,
        [itemID]: {
          loading: menuDataLoading,
          data: filteredData,
          id: itemID,
        },
      };
      if (!skipForceUpdate) {
        forceUpdate();
      }
    },
    [filterData, forceUpdate]
  );

  const getItemIdAndType = useCallback((id: string) => {
    const [type, ...idSplit] = id.split('-');
    const itemID = idSplit.join('-');
    return { type, itemID };
  }, []);

  const maybeGetCurrentItem = useCallback(
    (itemID: string, customFilter?: ICustomFilter) => {
      const { current: currentMenu } = menu;

      const filter = customFilter || filterData;

      if (currentMenu[itemID]) {
        const filteredData = currentMenu[itemID].data
          ? filter(currentMenu[itemID].data as SanityMenuObject)
          : null;

        currentMenu[itemID] = {
          ...currentMenu[itemID],
          data: filteredData as any,
        };

        /**
         * We prevent any menu object to get displayed while we are still
         * fetching prices because this affects how we filter unavailable
         * products in applyFiltersForAvailability. If we don't await plu
         * prices to get resolved we might end up showing unavailable
         * products in the UI until then.
         */
        return {
          ...currentMenu[itemID],
          loading: isPricesLoading || currentMenu[itemID].loading,
        };
      }
      return undefined;
    },
    [filterData]
  );
  /**
   *  id is a bit of a misnomer. This is the actually
   *  `${MenuObjectTypes}-${sanityId}`
   *  A CartEntryType can be mapped to MenuObjectTypes
   *  by transforming to camelCase
   *  e.g ComboSlot -> comboSlot
   */
  const getMenuObject = useCallback(
    (id: string) => {
      const { type, itemID } = getItemIdAndType(id);

      if (type === 'offerDiscount') {
        return { data: null, loading: false };
      }

      const currentMenuItem = maybeGetCurrentItem(itemID);

      if (currentMenuItem) {
        if (currentMenuItem?.data) {
          currentMenuItem.data = changeImageByChannel(currentMenuItem.data);
        }

        return currentMenuItem;
      }

      const queryMenuData = (
        menuItemId: string,
        dataQuery: DocumentNode,
        availabilityQuery: DocumentNode
      ) => {
        Promise.all([
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: dataQuery,
            variables: {
              id: menuItemId,
            },
          }),
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: availabilityQuery,
            variables: {
              id: menuItemId,
            },
          }),
        ])
          .then(response => {
            const { data: rawData, loading } = response.reduce(
              (finalResponse, res) => {
                const capitalizedType = type[0].toUpperCase() + type.slice(1);
                const data = res?.data;

                return {
                  data: data?.[capitalizedType]
                    ? [...finalResponse.data, data[capitalizedType]]
                    : finalResponse.data,
                  loading: res.loading || finalResponse.loading,
                };
              },
              { data: [], loading: false }
            );
            const [uiData, availability] = rawData;

            const mergedData = changeImageByChannel(merge(uiData, availability));

            convertDeep?.(mergedData);

            setMenuData(mergedData, loading, menuItemId);
          })
          .catch(err => {
            throw new MenuDataError(`An error occurred loading menu ${type}`, err);
          });
      };

      switch (type) {
        case MenuObjectTypes.ITEM:
          queryMenuData(itemID, GetItemDocument, GetItemAvailabilityDocument);
          break;
        case MenuObjectTypes.COMBO:
          queryMenuData(itemID, GetComboDocument, GetComboAvailabilityDocument);
          break;
        case MenuObjectTypes.PICKER:
          queryMenuData(itemID, GetPickerDocument, GetPickerAvailabilityDocument);
          break;
        default:
          throw new NotFoundMenuItemError(type);
      }

      return menu.current[itemID] || { loading: true, data: null };
    },
    [
      getItemIdAndType,
      maybeGetCurrentItem,
      changeImageByChannel,
      client,
      uri,
      convertDeep,
      setMenuData,
    ]
  );

  /**
   * Like getMenuObject but async instead of reactive
   */
  const asyncGetMenuObject = useCallback(
    async (id: string, customFilter?: ICustomFilter) => {
      const { type, itemID } = getItemIdAndType(id);
      if (type === 'offerDiscount') {
        return { data: null, loading: false };
      }
      const currentMenuItem = maybeGetCurrentItem(itemID, customFilter);
      if (currentMenuItem) {
        if (currentMenuItem?.data) {
          currentMenuItem.data = changeImageByChannel(currentMenuItem.data);
        }
        return currentMenuItem;
      }
      const queryMenuData = async (
        menuItemId: string,
        dataQuery: DocumentNode,
        availabilityQuery: DocumentNode
      ) => {
        await Promise.all([
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: dataQuery,
            variables: {
              id: menuItemId,
            },
          }),
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: availabilityQuery,
            variables: {
              id: menuItemId,
            },
          }),
        ])
          .then(response => {
            const { data: rawData, loading } = response.reduce(
              (finalResponse, res) => {
                const capitalizedType = type[0].toUpperCase() + type.slice(1);
                const data = res?.data;

                return {
                  data: data?.[capitalizedType]
                    ? [...finalResponse.data, data[capitalizedType]]
                    : finalResponse.data,
                  loading: res.loading || finalResponse.loading,
                };
              },
              { data: [], loading: false }
            );
            const [uiData, availability] = rawData;

            const mergedData = changeImageByChannel(merge(uiData, availability));
            convertDeep?.(mergedData);

            setMenuData(mergedData, loading, menuItemId, true, customFilter);
          })
          .catch(err => {
            throw new MenuDataError(`An error occurred loading menu ${type}`, err);
          });
      };
      switch (type) {
        case MenuObjectTypes.ITEM:
          await queryMenuData(itemID, GetItemDocument, GetItemAvailabilityDocument);
          break;
        case MenuObjectTypes.COMBO:
          await queryMenuData(itemID, GetComboDocument, GetComboAvailabilityDocument);
          break;
        case MenuObjectTypes.PICKER:
          await queryMenuData(itemID, GetPickerDocument, GetPickerAvailabilityDocument);
          break;
        default:
          throw new NotFoundMenuItemError(type);
      }

      return menu.current[itemID];
    },
    [
      getItemIdAndType,
      maybeGetCurrentItem,
      changeImageByChannel,
      client,
      uri,
      convertDeep,
      setMenuData,
    ]
  );

  /**
   * Like asyncGetMenuObject but will always fire a network request instead of using reference (network responses are cached by the browser)
   */
  const asyncGetRawMenuObject = useCallback(
    async (id: string) => {
      const { type, itemID } = getItemIdAndType(id);
      const queryMenuData = async (
        menuItemId: string,
        dataQuery: DocumentNode,
        availabilityQuery: DocumentNode
      ) => {
        return Promise.all([
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: dataQuery,
            variables: {
              id: menuItemId,
            },
          }),
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: availabilityQuery,
            variables: {
              id: menuItemId,
            },
          }),
        ])
          .then(response => {
            const { data: rawData } = response.reduce(
              (finalResponse, res) => {
                const capitalizedType = type[0].toUpperCase() + type.slice(1);
                const data = res?.data;

                return {
                  data: data?.[capitalizedType]
                    ? [...finalResponse.data, data[capitalizedType]]
                    : finalResponse.data,
                  loading: res.loading || finalResponse.loading,
                };
              },
              { data: [], loading: false }
            );
            const [uiData, availability] = rawData;

            const mergedData = changeImageByChannel(merge(uiData, availability));
            const fixedData = mergedData ? fixTypes(mergedData) : null;

            convertDeep?.(fixedData);

            return remapItemOptions(fixedData) as MenuObject;
          })
          .catch(err => {
            throw new MenuDataError(`An error occurred loading menu ${type}`, err);
          });
      };

      switch (type) {
        case MenuObjectTypes.ITEM:
          return queryMenuData(itemID, GetItemDocument, GetItemAvailabilityDocument);
        case MenuObjectTypes.COMBO:
          return queryMenuData(itemID, GetComboDocument, GetComboAvailabilityDocument);
        case MenuObjectTypes.PICKER:
          return queryMenuData(itemID, GetPickerDocument, GetPickerAvailabilityDocument);
        default:
          throw new NotFoundMenuItemError(type);
      }
    },
    [getItemIdAndType, client, uri, changeImageByChannel, convertDeep]
  );

  const checkItemAvailability: IMenuOptionsContext['checkItemAvailability'] = useCallback(
    async ({
      vendor,
      itemId,
      restaurantPosDataId,
      storeNumber,
    }: ICheckItemAvailability): Promise<ItemAvailability> => {
      try {
        const posDataResponse = await getPosData({ restaurantPosDataId, storeNumber });
        if (!posDataResponse) {
          return { availabilityStatus: ItemAvailabilityStatus.UNAVAILABLE };
        }

        const isItemAvailable = async (plus: Record<string, number>) => {
          const availabilityFilter = applyFiltersForAvailability({
            activeDayParts,
            dayParts,
            prices: plus,
            vendor,
          });
          const { data } = await asyncGetMenuObject(itemId, availabilityFilter);

          if (
            dayParts.length &&
            activeDayParts.length &&
            !isAvailableForActiveDayParts({ activeDayParts, menuData: data })
          ) {
            return {
              availabilityStatus: ItemAvailabilityStatus.OUT_OF_DAYPART,
              data: data ?? undefined,
            };
          }

          const optionsAvailableForPicker = (menuData: MenuObject): ItemAvailability => {
            // If selected item is picker, make sure there are at least some options available.
            const notPicker = menuData?._type !== MenuObjectTypes.PICKER;
            if (notPicker || Boolean(menuData?.options?.length)) {
              return {
                availabilityStatus: ItemAvailabilityStatus.AVAILABLE,
                data: data ?? undefined,
              };
            }

            return {
              availabilityStatus: ItemAvailabilityStatus.OUT_OF_MENU,
              data: data ?? undefined,
            };
          };

          return !!data
            ? optionsAvailableForPicker(data)
            : { availabilityStatus: ItemAvailabilityStatus.OUT_OF_MENU, data: data ?? undefined };
        };

        return !!posDataResponse.posData
          ? isItemAvailable(posDataResponse.posData)
          : { availabilityStatus: ItemAvailabilityStatus.UNAVAILABLE };
      } catch (err) {
        throw new NotFoundMenuItemError(itemId);
      }
    },
    [activeDayParts, asyncGetMenuObject, dayParts, getPosData]
  );

  // There are a few specs that cause us to force remove all items.
  //
  // 1. When the store changes, we have to remove all loaded items.
  //    This is because a store might have different menu data.
  // 2. When then language changes, we wipe the data so that the user
  //    refetches the data and then gets it in the new language
  // 3. When the prices are refetched, we clear all loaded items since
  //    the filtered data will be completely different
  useEffect(() => {
    menu.current = {};
  }, [locale, store, prices]);

  return (
    <MenuOptionsContext.Provider
      value={{
        getMenuObject,
        priceForItemOptionModifier,
        pricingFunction,
        priceForComboSlotSelection,
        priceForItemInComboSlotSelection,
        filterForAvailability,
        asyncGetMenuObject,
        asyncGetRawMenuObject,
        checkItemAvailability,
      }}
    >
      {children}
    </MenuOptionsContext.Provider>
  );
};
