import React from 'react';

import { normalizedTranslate } from '@rbilabs/components-library';
import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';

import { LOADING_FILL_COLOR } from './constants';

export interface ILoadingAnimationProps extends IBaseProps {
  fillColor?: string;
  ariaLabel?: string;
}

const DURATION = 1.25;
const STAGGER_OFFSET = 7.5;
const CUBIC_IN = 'cubic-bezier(0.55, 0.055, 0.675, 0.19)';
const CUBIC_OUT = 'cubic-bezier(0.215, 0.61, 0.355, 1)';

const ParticleAnimation = styled.div<ILoadingAnimationProps>`
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 46px;

  span {
    background: ${p => p.fillColor};
    border-radius: 50%;
    display: block;
    height: 10px;
    width: 10px;
    transform-origin: 50% 50%;

    &.one {
      animation: one ${DURATION}s infinite;
    }

    &.two {
      animation: two ${DURATION}s infinite;
    }

    &.three {
      animation: three ${DURATION}s infinite;
    }
  }

  @keyframes one {
    0% {
      transform: scale(0.5);
      animation-timing-function: ${CUBIC_IN};
    }
    42.5% {
      transform: scale(1) ${normalizedTranslate('-80%', 0, 0)};
      animation-timing-function: ${CUBIC_OUT};
    }
    85% {
      transform: scale(0.5) translate3d(0, 0, 0);
    }
    100% {
      transform: scale(0.5);
    }
  }

  @keyframes two {
    0% {
      transform: scale(0.5);
    }
    ${`${0 + STAGGER_OFFSET}%`} {
      transform: scale(0.5);
      animation-timing-function: ${CUBIC_IN};
    }
    ${`${42.5 + STAGGER_OFFSET}%`} {
      transform: scale(1) translate3d(0, 0, 0);
      animation-timing-function: ${CUBIC_OUT};
    }
    ${`${85 + STAGGER_OFFSET}%`} {
      transform: scale(0.5) translate3d(0, 0, 0);
    }
    100% {
      transform: scale(0.5);
    }
  }

  @keyframes three {
    0% {
      transform: scale(0.5);
    }
    ${`${0 + 2 * STAGGER_OFFSET}%`} {
      transform: scale(0.5);
      animation-timing-function: ${CUBIC_IN};
    }
    ${`${42.5 + 2 * STAGGER_OFFSET}%`} {
      transform: scale(1) ${normalizedTranslate('80%', 0, 0)};
      animation-timing-function: ${CUBIC_OUT};
    }
    ${`${85 + 2 * STAGGER_OFFSET}%`} {
      transform: scale(0.5) translate3d(0, 0, 0);
    }
    100% {
      transform: scale(0.5);
    }
  }
`;

export function LoadingAnimation({
  fillColor = LOADING_FILL_COLOR,
  className,
  ariaLabel,
}: ILoadingAnimationProps) {
  return (
    <ParticleAnimation
      data-testid="loading-animation"
      fillColor={fillColor}
      className={className}
      aria-label={ariaLabel}
    >
      <span className="one" />
      <span className="two" />
      <span className="three" />
      {/*
      This fancy character is a zero-width space!
      Putting this here forces the animation to have a minimum height the same as any sibling text.
      When this animation is used in the context of an ActionButton, it prevents the button from changing size when it toggles between loading and not loading.
       */}
      &#8203;
    </ParticleAnimation>
  );
}
