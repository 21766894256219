import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IsoCountryCode2 } from '@rbilabs/intl';
import { chunk, compact, merge, noop } from 'lodash';
import uuidv4 from 'uuid/v4';

import { IBackendCartEntries, ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useHasAcceptedCookies } from 'hooks/use-has-accepted-cookies';
import { MediaQuery, useMediaQuery } from 'hooks/use-media-query';
import { useReadyQueue } from 'hooks/use-ready-queue';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  SignUpFieldsVariations,
  defaultSignUpFieldsVariation,
} from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { getBrowserType, getBrowserVersion, getIsMobileWeb } from 'utils/browser';
import { RBIBrand, appVersionCode, brand, getCountry } from 'utils/environment';
import { centsToDollars } from 'utils/index';
import { showField } from 'utils/launchdarkly';
import { getCurrentVersion as getCurrentAppflowVersion } from 'utils/live-updates';
import { logger } from 'utils/logger';
import { isOTPEnabled } from 'utils/otp';
import { isHome, isLocalRoute, routes } from 'utils/routing';

import {
  ClickEventComponentNames,
  CustomEventNames,
  EVENT_NAMES_ECOMMERCE,
  EventTypes,
  LAYER_ATTRIBUTE_DEFAULT_VALUE,
  LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT,
  ONE_INDEX_OFFSET,
  SignInPhases,
  TRACKED_PAGES,
} from '../constants';
import { createIdentity } from '../create-identity';
import { defaultCdpCtx } from '../types';
import * as I from '../types';
import { useFlagsForUniversalAttributes } from '../use-flags-for-universal-attributes';
import {
  ProductItemType,
  booleanToString,
  errorIdentityCallback,
  flattenCartEntryItems,
  getAllowedAttributes,
  getCdpSanitizedAttributes,
  getSourcePage,
  normalizeBooleans,
  sanitizeValues,
  serializeNumberOfDriveThruWindows,
  serializePaymentType,
  serializePickupMode,
  serializeServiceMode,
} from '../utils';

import { BloomreachAdapter } from './bloomreach-adapter';
import { initSdk } from './init';

declare global {
  interface Window {
    exponea: I.ICdp;
  }
}

type ExcludesNull = <T>(x: T | null) => x is T;

declare interface ILogPageView {
  pathname: string;
  normalizedAndSanitizedAttrs: any;
  normalizedFlags: any;
}

export const BloomreachContext = React.createContext<I.ICdpCtx>(defaultCdpCtx);
export const useBloomreachContext = () => useContext<I.ICdpCtx>(BloomreachContext);

export function BloomreachProvider({ children }: { children: ReactNode }) {
  const { language, locale } = useLocale();
  const {
    location: { pathname },
  } = useLocationContext();
  const cartEntryTypeItem = 'Item';
  const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
  const enableCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER);
  const hasAcceptedCookies = useHasAcceptedCookies();
  const isSmallScreen = useMediaQuery(MediaQuery.Mobile);
  const isUpsellSimplified = useFlag(LaunchDarklyFlag.ENABLE_PRODUCT_UPSELL_SIMPLIFIED);
  const staticRoutes = useRef<string[]>([]);
  const logPageViewParameters = useRef<ILogPageView>();
  const flagsForUniversalAttributes = useFlagsForUniversalAttributes();
  const signUpFieldsVariations =
    useFlag<SignUpFieldsVariations>(LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS) ||
    defaultSignUpFieldsVariation;
  let additionalSignupFields = {} as SignUpFieldsVariations;
  const enablePushNotificationsFlag = useFlag(
    LaunchDarklyFlag.ENABLE_PUSH_NOTIFICATIONS_ON_SIGN_UP
  );
  const [signUpFlowTrackData, setSignUpFlowTrackData] = useState<I.ITrackSignUpEvent>({
    event: CustomEventNames.SIGN_UP,
    data: '',
  });
  const [isNewSignUp, setIsNewSignUp] = useState(false);

  // set up a backup unique session id in case ad blockers block mParticle
  const uniqueGuid = useRef<string>(uuidv4());

  if (brand() === RBIBrand.PLK && getCountry()?.toUpperCase() === IsoCountryCode2.KR) {
    additionalSignupFields.gender = showField(signUpFieldsVariations.gender);
    additionalSignupFields.ageFourteen = showField(signUpFieldsVariations.ageFourteen);
    additionalSignupFields.additionalSignUpTerms = showField(
      signUpFieldsVariations.additionalSignUpTerms
    );
  }

  const universalAttributes = useRef<I.ICdpUniversalAttributes>({
    'Service Mode': '',
    'Pickup Mode': '',
    'Source Page': getSourcePage(pathname),
    browserType: getBrowserType(),
    browserVersion: getBrowserVersion(),
    isMobileWeb: getIsMobileWeb(),
    locale,
    isSmallScreen,
    ...additionalSignupFields,
    layer: LAYER_ATTRIBUTE_DEFAULT_VALUE,
  });

  // initializes bloomreach
  const init = useCallback(() => {
    initSdk(() => {
      drainQueue();
    });
  }, [drainQueue]);

  const trackEvent: I.ItrackEvent = event => {
    let universalAttrs = sanitizeValues(universalAttributes.current);
    universalAttrs = normalizeBooleans(universalAttrs);
    const globalAttributes: I.IGlobalAttributes = {
      currentScreen: getSourcePage(pathname),
      serviceMode: universalAttrs['Service Mode'],
      appBuild: universalAttrs.currentBuild,
      browserType: universalAttrs.browserType,
      browserVersion: universalAttrs.browserVersion,
      isMobileWeb: universalAttrs.isMobileWeb,
    };

    BloomreachAdapter.logEvent(
      event.name,
      event.type,
      {
        ...universalAttrs,
        ...globalAttributes,
        ...event.globalAttributes,
        ...event.attributes,
        Locale: locale,
      },
      event.customFlags
    );

    logger.info('bloomreach event', {
      event_type: EventTypes[event.type],
      from_bloomreach: 1,
      event_name: event.name,
      event_attributes: getAllowedAttributes(event),
    });
  };

  const login = enqueueIfNotDrained(
    ({ email, customerid, ...userAttributes } = {}, { callback = noop } = {}) => {
      try {
        userAttributes = getCdpSanitizedAttributes(userAttributes, enablePushNotificationsFlag);
        BloomreachAdapter.identify(
          email,
          customerid,
          userAttributes,
          (result: { status: string }) => {
            if (isNewSignUp) {
              const { event, data } = signUpFlowTrackData;
              trackEvent({ name: event, type: EventTypes.Other, attributes: data });
            }

            // configureSessionId();
            callback(result);
          }
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Failed login event - bloomreach cdp', err);
      }
    }
  );

  const emptyUserIdentityRequest = { customerIds: {} };
  const logout = enqueueIfNotDrained(({ callback = noop, tryAgain = true } = {}) => {
    try {
      window.exponea.identify(emptyUserIdentityRequest, (result: { status: string }) => {
        if (result.status !== 'ok') {
          return errorIdentityCallback({
            identityFn: logout,
            callback,
            tryAgain,
            result,
          });
        }

        callback();
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed logout event - bloomreach cdp', err);
    }
  });

  const deviceId = '';
  const sessionId = '';

  const signUpEvent = ({
    success,
    message,
    otpMethod,
    additionalAttrs = {},
  }: I.ISignUpEventOptions) => {
    let event = success ? CustomEventNames.SIGN_UP : CustomEventNames.ERROR;
    const data = {
      Name: !success ? CustomEventNames.SIGN_UP_FAILED : undefined,
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
      Type: 'Backend',
      ...additionalAttrs,
    };

    if (isOTPEnabled(otpMethod)) {
      data['LaunchDarkly Flag Value'] = otpMethod;
    }

    if (success) {
      setIsNewSignUp(true);
      setSignUpFlowTrackData({ event, data });
    } else {
      trackEvent({ name: event, type: EventTypes.Other, attributes: data });
    }
  };

  const signUpTermToggleEvent = ({
    postToggleValue,
    signUpTermName,
  }: I.ISignUpTermToggleEventOptions) => {
    let event = CustomEventNames.SIGN_UP_TERM_TOGGLE;

    const data = {
      'Sign Up Term': signUpTermName,
      'Post Toggle Value': postToggleValue,
    };

    trackEvent({ name: event, type: EventTypes.Other, attributes: data });
  };

  const updateUniversalAttributes = useCallback(
    (newAttributes: Partial<I.ICdpUniversalAttributes>) =>
      (universalAttributes.current = { ...universalAttributes.current, ...newAttributes }),
    []
  );

  const updateStaticRoutes = (newStaticRoutes: IStaticPageRoute[]) => {
    staticRoutes.current = newStaticRoutes.reduce((acc: string[], route) => {
      const staticPath = route?.localePath?.[language]?.current || route?.path?.current;
      if (staticPath) {
        acc.push(`/${staticPath}`);
      }
      return acc;
    }, []);

    if (staticRoutes.current.length && logPageViewParameters.current) {
      maybeTrackPage({ ...logPageViewParameters.current });
      logPageViewParameters.current = undefined;
    }
  };

  const updateUserAttributes = enqueueIfNotDrained(
    (userAttributes = {}, { callback = noop } = {}) => {
      const finalUserAttributes = { language, ...userAttributes };
      const normalizedAttrs = normalizeBooleans(finalUserAttributes);
      const updateAttributes = getCdpSanitizedAttributes(
        normalizedAttrs,
        enablePushNotificationsFlag
      );
      BloomreachAdapter.update({ ...updateAttributes });
      callback();
    }
  );

  const updateUserLocationPermissionStatus = () => {
    // updateLocationPermissionStatus();
    // TODO: Implement with mobile SDK
    return;
  };

  const updateUserIdentities: any = enqueueIfNotDrained(
    ({ email, customerid, ccToken } = ({} = {})) => {
      const updatedUserIdentities = merge({}, createIdentity(email, customerid, ccToken));
      return BloomreachAdapter.update({ customerAttributes: updatedUserIdentities });
    }
  );

  //pageView data
  const maybeTrackPage = enqueueIfNotDrained(
    ({ pathname, normalizedAndSanitizedAttrs, normalizedFlags }: ILogPageView) => {
      const trackedPages = TRACKED_PAGES.concat(staticRoutes.current);
      const matchedPages = trackedPages.filter(page => pathname.startsWith(page));
      if (matchedPages.length) {
        // Find the longest match by setting it to be the first element
        const matchedPage = matchedPages.sort((a, b) => b.length - a.length)[0];
        BloomreachAdapter.logEvent(
          'matchedPage',
          EventTypes.Other,
          { matchedPage, ...normalizedAndSanitizedAttrs },
          normalizedFlags
        );
      }
    }
  );

  const maybeTrackWhenStaticRoutesAvailable = ({
    pathname,
    normalizedAndSanitizedAttrs,
    normalizedFlags,
  }: ILogPageView) => {
    logPageViewParameters.current = {
      pathname,
      normalizedAndSanitizedAttrs,
      normalizedFlags,
    };
  };

  const logPageView = enqueueIfNotDrained(
    (pathname, attrs = {}, customFlags = {}, force = false) => {
      const allAttributes = {
        ...universalAttributes.current,
        ...attrs,
      };
      const sanitizedAttributes = sanitizeValues(allAttributes);
      const normalizedAndSanitizedAttrs = normalizeBooleans(sanitizedAttributes);
      const normalizedFlags = normalizeBooleans(customFlags);
      if (force) {
        BloomreachAdapter.logEvent(
          'page_view',
          EventTypes.Other,
          { ...normalizedAndSanitizedAttrs, pathname },
          normalizedFlags
        );
        return;
      }
      if (pathname.startsWith(routes.menu)) {
        const onMainMenu = new RegExp(routes.menu.concat('$')).test(pathname);
        if (onMainMenu) {
          BloomreachAdapter.logEvent(
            'page_view',
            EventTypes.Other,
            { ...normalizedAndSanitizedAttrs, pathname },
            normalizedFlags
          );
        }
      }
      // fixes issue with home page not being captured
      if (isHome(pathname)) {
        BloomreachAdapter.logEvent(
          'page_view',
          EventTypes.Other,
          { ...normalizedAndSanitizedAttrs, pathname },
          normalizedFlags
        );
      } else {
        const trackParameters = {
          pathname,
          normalizedAndSanitizedAttrs,
          normalizedFlags,
        };
        // If staticRoutes have not loaded yet
        // Store them for later when they are available
        if (!isLocalRoute(pathname) && !staticRoutes.current.length) {
          return maybeTrackWhenStaticRoutesAvailable(trackParameters);
        }
        maybeTrackPage({ ...trackParameters });
      }
    }
  );

  const logCommercePageView = (
    menuData: { id: string; name: string; menuType: string },
    attrs = {}
  ) => {
    const { name, id, menuType } = menuData;
    const product: I.ICdpProduct = {
      Name: name,
      Sku: id,
      Price: 0,
      Quantity: 1,
      Attributes: {},
      SubProducts: [],
    };

    BloomreachAdapter.logEvent(
      EVENT_NAMES_ECOMMERCE.VIEW_DETAIL,
      EventTypes.Other,
      { ...product },
      {
        menuType,
        ...attrs,
      }
    );
  };

  //eCommerce events
  const createSublevelItems = (cartEntry: ICartEntry): I.ICdpSublevelItem[] => {
    // Get cart entry sublevel items
    const subItems = flattenCartEntryItems(cartEntry).filter(
      item => item._id !== cartEntry._id && item.type === cartEntryTypeItem
    );

    // Merge sublevel items by item id
    const mergedItemsById = subItems.reduce<{
      [id: string]: I.ICdpSublevelItem | undefined;
    }>((acc, item) => {
      const curItem = acc[item._id];
      if (curItem) {
        curItem.quantity += item.quantity;
      } else {
        acc[item._id] = { id: item._id, quantity: item.quantity };
      }
      return acc;
    }, {});

    return compact(Object.values(mergedItemsById));
  };

  const createSublevelProducts = (
    cartEntry: ICartEntry,
    withAttributes: boolean = true
  ): I.ICdpProduct[] => {
    const subProducts: I.ICdpProduct[] = [];
    // Get cart entry sublevel items
    const subItems = flattenCartEntryItems(cartEntry);

    // Merge sublevel items by item id
    for (const subItem of subItems) {
      if (
        subItem._id !== cartEntry._id &&
        subItem.type === cartEntryTypeItem &&
        subItem.productHierarchy
      ) {
        const p = createProduct(subItem, withAttributes);
        if (p?.Attributes) {
          p.Attributes.comboChild = booleanToString(true);
          subProducts.push(p);
        }
      }
    }
    return subProducts;
  };

  const createProduct = (
    cartEntry: ICartEntry | IBackendCartEntries,
    withAttributes: boolean = true
  ): I.ICdpProduct | null => {
    const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
    const _id = '_id' in cartEntry ? cartEntry._id : cartEntry.sanityId;
    const { name = '', price, quantity, isDonation = false, isExtra = false } = cartEntry;
    const basePrice = price ? centsToDollars(price / quantity) : 0;
    const product: I.ICdpProduct = {
      Name: name,
      Sku: _id,
      Price: basePrice,
      Quantity: quantity,
    };

    if (!product) {
      return null;
    }

    const productSublevelItems = createSublevelItems(cartEntry as ICartEntry);
    const itemLevel =
      productSublevelItems.length === 0 ? ProductItemType.Child : ProductItemType.Parent;

    if (withAttributes) {
      product.Attributes = {
        cartId: cartId || _id,
        sublevelItems: JSON.stringify(productSublevelItems),
        isDonation: booleanToString(isDonation),
        isExtra: booleanToString(isExtra),
        'Item Level': itemLevel,
        comboChild: booleanToString(false),
      };

      if (itemLevel === ProductItemType.Child) {
        product.Attributes = {
          ...product.Attributes,
          L1: cartEntry.productHierarchy?.L1 || '',
          L2: cartEntry.productHierarchy?.L2 || '',
          L3: cartEntry.productHierarchy?.L3 || '',
          L4: cartEntry.productHierarchy?.L4 || '',
          L5: cartEntry.productHierarchy?.L5 || '',
        };
      }
    }

    product.SubProducts =
      productSublevelItems.length > 0
        ? createSublevelProducts(cartEntry as ICartEntry, withAttributes)
        : [];
    return product;
  };

  const getCartDataItems = (cartEntries: ICartEntry[]): string => {
    const cartData = JSON.stringify({
      items: cartEntries.map(entry => ({
        items: createProduct(entry),
      })),
    });
    const cartDataItemsRegex = /item_\d{3,}/gi;
    return cartData?.match(cartDataItemsRegex)?.join() || '';
  };

  const addToCart = enqueueIfNotDrained(
    (
      cartEntry: ICartEntry,
      serviceMode: ServiceMode,
      previousCartEntries: ICartEntry[],
      sendUpdateUserAttributesEvent = noop,
      selectionAttrs?: I.IAddToCartSelectionAttributes
    ) => {
      const product = createProduct(cartEntry);

      if (!product) {
        return;
      }

      const { Attributes: attr, ...productWithoutAttributes } = product;

      BloomreachAdapter.logEvent(EVENT_NAMES_ECOMMERCE.ADD_TO_CART, EventTypes.Other, {
        ...productWithoutAttributes,
        ...attr,
        'Pickup Mode': serializePickupMode(serviceMode),
        'Source Page': getSourcePage(pathname),
        'Cart Data': getCartDataItems(previousCartEntries),
        'Picker Aspect Selection': booleanToString(!!selectionAttrs?.pickerAspectSelection),
        'Combo Slot Selection': booleanToString(!!selectionAttrs?.comboSlotSelection),
        'Item Modified': booleanToString(!!selectionAttrs?.itemModified),
      });
      updateUserAttributes({ 'Pickup Mode': serializePickupMode(serviceMode) });

      // NOTE: This is added to allow the CI to pass without errors as this will be deprecated and no longer used.
      sendUpdateUserAttributesEvent();
    }
  );

  const updateItemInCart = enqueueIfNotDrained(
    (newCartEntry: ICartEntry, originalCartEntry: ICartEntry, serviceMode: ServiceMode) => {
      const oldProduct = createProduct(originalCartEntry);
      const newProduct = createProduct(newCartEntry);

      if (!oldProduct || !newProduct) {
        return;
      }
      const { Attributes: oldAttr, ...oldProductWithoutAttributes } = oldProduct;
      BloomreachAdapter.logEvent(
        EVENT_NAMES_ECOMMERCE.REMOVE_FROM_CART,
        EventTypes.Other,
        oldProductWithoutAttributes,
        {
          ...oldAttr,
          'Source Page': getSourcePage(pathname),
        }
      );
      const { Attributes: newAttr, ...newProductWithoutAttributes } = newProduct;

      BloomreachAdapter.logEvent(
        EVENT_NAMES_ECOMMERCE.ADD_TO_CART,
        EventTypes.Other,
        newProductWithoutAttributes,
        {
          ...newAttr,
          'Pickup Mode': serializePickupMode(serviceMode),
          'Source Page': getSourcePage(pathname),
        }
      );
      updateUserAttributes({ 'Pickup Mode': serializePickupMode(serviceMode) });
    }
  );

  const removeFromCart = enqueueIfNotDrained((cartEntry: ICartEntry) => {
    const product = createProduct(cartEntry);

    if (!product) {
      return;
    }
    const { Attributes: attr, ...productWithoutAttributes } = product;

    BloomreachAdapter.logEvent(
      EVENT_NAMES_ECOMMERCE.REMOVE_FROM_CART,
      EventTypes.Other,
      productWithoutAttributes,
      {
        ...attr,
        'Source Page': getSourcePage(pathname),
      }
    );
  });

  const logOrderLatencyEvent = enqueueIfNotDrained(
    (order: IServerOrder | undefined, actionType: 'commit' | 'price', duration: number) => {
      const orderStatus = order?.status;
      const storeId = order?.cart?.storeDetails?.storeNumber;
      const orderId = order?.rbiOrderId;
      const serviceMode = order?.cart?.serviceMode ?? null;

      const eventName =
        actionType === 'price'
          ? CustomEventNames.ORDER_LATENCY_PRICING
          : CustomEventNames.ORDER_LATENCY_COMMIT;

      const context = {
        Duration: Math.floor(duration),
        Status: orderStatus,
        'Store ID': storeId,
        'Order ID': orderId,
        'Service Mode': serializeServiceMode(serviceMode),
        'Source Page': getSourcePage(pathname),
        Locale: locale,
      };
      updateUserAttributes({ 'Service Mode': serializeServiceMode(serviceMode) });

      BloomreachAdapter.logEvent(eventName, EventTypes.Other, context);
      logger.info(eventName, context);
    }
  );

  const logPurchase = enqueueIfNotDrained(
    (
      cartEntries: ICartEntry[],
      store: StoreProxy,
      serviceMode: ServiceMode,
      serverOrder: IServerOrder,
      attrs = {}
    ) => {
      const appliedOffersCmsIds = (serverOrder.cart.appliedOffers || []).map(
        ({ sanityId }) => sanityId
      );

      // Upsells
      // - hasUpsell
      // - upsellTotal
      const upsells = cartEntries.filter(entry => entry.isUpsell);
      const hasUpsell = !!upsells.length;
      const upsellTotal = centsToDollars(
        upsells.reduce((total, entry) => total + (entry.price || 0), 0)
      );

      // Create Chef payload
      const upsellEntry = upsells.find(entry => !!entry.recommendationToken);
      const recommendationToken = upsellEntry?.recommendationToken;
      const recommender = upsellEntry?.recommender;
      const chef =
        hasUpsell && recommendationToken
          ? {
              eventType: 'purchase-complete',
              eventDetail: {
                recommendationToken,
              },
              productEventDetail: {
                productDetails: cartEntries.map(entry => ({
                  id: entry._id,
                  quantity: entry.quantity,
                  displayPrice: entry.price,
                  currencyCode: attrs?.currencyCode || 'USD',
                })),
                purchaseTransaction: {
                  id: serverOrder.rbiOrderId,
                  revenue: centsToDollars(serverOrder.cart.subTotalCents),
                  currencyCode: attrs?.currencyCode || 'USD',
                },
              },
            }
          : null;

      const couponIDString = appliedOffersCmsIds.join();
      const serializedServiceMode = serializeServiceMode(serviceMode);

      const rewardAttributes = serverOrder.cart.rewardsApplied?.map(reward => ({
        'Reward ID': reward.rewardId,
        'Reward Quantity': reward.timesApplied,
      }));

      const transactionAttributes = {
        Id: serverOrder.rbiOrderId,
        Revenue: centsToDollars(serverOrder.cart.subTotalCents),
        Tax: centsToDollars(serverOrder.cart.taxCents),
      };

      updateUserAttributes({
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant ID': store.number,
        'Restaurant Name': store.name,
        'Restaurant Number': store.number,
      });

      // Some of these are duplicates from transactionAttributes,
      // but BI wants to have them under specific property names.
      const additionalAttrs: I.ICdpPurchaseEventAttributes = {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        customer_event_alias: serializedServiceMode,
        'CC Token': serverOrder?.cart?.payment?.panToken ?? null,
        'Coupon ID': couponIDString,
        'Coupon Applied': booleanToString(!!couponIDString),
        Currency: attrs.currencyCode,
        'Tax Amount': transactionAttributes.Tax,
        'Total Amount': transactionAttributes.Revenue,
        'Transaction Order Number ID': serverOrder?.posOrderId ?? '',
        'Transaction POS': serverOrder?.cart?.posVendor ?? null,
        'Transaction RBI Cloud Order ID': serverOrder?.rbiOrderId ?? null,
        'Timed Fire Minutes': attrs.fireOrderInMinutes,
        'Restaurant ID': store.number,
        'Restaurant Name': store.name,
        'Restaurant Number': store.number,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant City': store.physicalAddress?.city ?? null,
        'Restaurant State/Province Name': store.physicalAddress?.stateProvince ?? null,
        'Restaurant Postal Code': store.physicalAddress?.postalCode ?? null,
        'Restaurant Country': store.physicalAddress?.country ?? null,
        'Restaurant Latitude': store.latitude,
        'Restaurant Longitude': store.longitude,
        'Restaurant Status': store.status,
        'Restaurant Drink Station Type': store.drinkStationType,
        'Restaurant Drive Thru Lane Type': store.driveThruLaneType ?? null,
        'Restaurant Franchise Group Id': store.franchiseGroupId,
        'Restaurant Franchise Group Name': store.franchiseGroupName,
        'Restaurant Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Breakfast': store.hasBreakfast,
        'Restaurant Has Burgers For Breakfast': store.hasBurgersForBreakfast,
        'Restaurant Has Curbside': store.hasCurbside,
        'Restaurant Has Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Catering': store.hasCatering,
        'Restaurant Has Dine In': store.hasDineIn,
        'Restaurant Has Drive Thru': store.hasDriveThru,
        'Restaurant Has Mobile Ordering': store.hasMobileOrdering,
        'Restaurant Has Parking': store.hasParking,
        'Restaurant Has Playground': store.hasPlayground,
        'Restaurant Has Take Out': store.hasTakeOut,
        'Restaurant Has Wifi': store.hasWifi,
        'Restaurant Number Drive Thru Windows': serializeNumberOfDriveThruWindows(
          store.driveThruLaneType
        ),
        'Restaurant Parking Type': store.parkingType,
        'Restaurant Playground Type': store.playgroundType,
        'Restaurant POS': store.pos?.vendor ?? null,
        'Restaurant POS Version': store.pos?.version ?? null,
        'Is Guest Order': !!serverOrder.cart.guestId,
        'Guest ID': serverOrder.cart.guestId,
        'Card Type': serverOrder.cart.payment?.cardType || '',
        'Payment Type': serializePaymentType(serverOrder.cart.payment?.paymentType),
        'Has Upsell': hasUpsell,
        'Upsell Total': upsellTotal,
        'Recommender Provider': recommender || '',
        Chef: chef ? JSON.stringify(chef) : null,
        'Source Page': getSourcePage(pathname),
        'Cart Data': getCartDataItems(cartEntries),
        Rewards: rewardAttributes ? JSON.stringify(rewardAttributes) : null,
        'Currency Code': attrs.currencyCode || 'USD',
        'Upsell Simplified Enabled': isUpsellSimplified,
      };

      // Delivery Fees
      if (serializeServiceMode(serviceMode) === 'Delivery') {
        additionalAttrs.quotedFeeAmount = centsToDollars(attrs.quotedFeeCents);
        additionalAttrs['Address Type'] = attrs.addressType;
        additionalAttrs.hasSavedDeliveryAddress = attrs.hasSavedDeliveryAddress;
        additionalAttrs.hasSelectedRecentAddress = attrs.hasSelectedRecentAddress;
        additionalAttrs.hasRecentAddress = attrs.hasRecentAddress;
      }

      if (transactionAttributes.Revenue >= 20) {
        additionalAttrs['Value Threshold 20 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 15) {
        additionalAttrs['Value Threshold 15 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 10) {
        additionalAttrs['Value Threshold 10 Met'] = true;
      }
      if (transactionAttributes.Revenue >= 5) {
        additionalAttrs['Value Threshold 5 Met'] = true;
      }

      const normalizedTransactionAttrs = normalizeBooleans(transactionAttributes);

      const sanitizedAdditionAttrs = sanitizeValues(additionalAttrs);
      const normalizedAdditionalAttrs = normalizeBooleans(sanitizedAdditionAttrs);

      const products = cartEntries.reduce((accumulator, cartEntry) => {
        const eCommerceProduct = createProduct(cartEntry, false);

        if (!eCommerceProduct) {
          return accumulator;
        }

        // Using destructuring for deletion of a property

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { Attributes, SubProducts, ...productWithoutAttr } = eCommerceProduct;
        accumulator.push({
          ...productWithoutAttr,
        });

        return accumulator;
      }, [] as I.ICdpProduct[]);

      try {
        // for docs, refer https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle.eCommerce.html
        BloomreachAdapter.logEvent(
          EVENT_NAMES_ECOMMERCE.PURCHASE,
          EventTypes.Other,
          { products },
          {
            ...normalizedAdditionalAttrs,
            ...normalizedTransactionAttrs,
          }
        );
      } catch (error) {
        logger.error({ error, message: 'bloomreach > logPurchase error' });
      }

      // log rbi purchase events
      if (serializedServiceMode === 'Pickup' || serializedServiceMode === 'Delivery') {
        const eventName =
          serializedServiceMode === 'Pickup' ? 'Purchase Pick Up' : 'Purchase Delivery';
        trackEvent({
          name: eventName,
          type: EventTypes.Other,
        });
      }
    }
  );

  const logCustomerInitiatedRefund = (
    event: CustomEventNames,
    items: IBackendCartEntries[] = [],
    amount: number = 0,
    reason: string = ''
  ) => {
    // Converts refunded items ids to a string to be sent in the custom event below.
    const refundedItemsString = items.map(({ lineId }) => lineId).join(', ');

    trackEvent({
      name: event,
      type: EventTypes.Transaction,
      attributes: {
        amount,
        items: refundedItemsString,
        reason,
      },
    });
  };

  //error tracking
  const logError = enqueueIfNotDrained(
    (
      error: { name: string; message?: string; stack?: string },
      attrs?: { [key: string]: string | number | boolean }
    ) => {
      logger.error({
        name: error.name,
        message: error.message,
        stack: error.stack,
        attrs,
      });
    }
  );

  const logValidationError = enqueueIfNotDrained(
    (
      error: { name: string; description?: string },
      attrs?: { [key: string]: string | number | boolean }
    ) => {
      const normalizedAttrs = attrs ? normalizeBooleans(attrs) : {};
      trackEvent({
        name: CustomEventNames.ERROR,
        type: EventTypes.Transaction,
        attributes: {
          Name: error.name,
          Description: error.description,
          ...normalizedAttrs,
        },
      });
    }
  );

  //custom events
  const logEvent = useCallback(
    enqueueIfNotDrained(
      (eventName: CustomEventNames, eventType: EventTypes, attrs = {}, customFlags = {}) => {
        const logEventWithAttributes = (eventAttrs = {}) => {
          const attributes = {
            ...universalAttributes.current,
            ...eventAttrs,
            Locale: locale,
          };

          const sanitizedAttributes = sanitizeValues(attributes);
          const normalizedAndSanitizedAttrs = normalizeBooleans(sanitizedAttributes);
          const normalizedFlags = normalizeBooleans(customFlags);

          const allKeys = Object.keys(normalizedAndSanitizedAttrs);
          let attributeBatches = [normalizedAndSanitizedAttrs];
          if (allKeys.length > LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT) {
            // Break the keys/values into chunks e.g.
            // [{'key': 'value'}], [{'key_2': 'value'}];
            const arrayFromObject = Object.entries(normalizedAndSanitizedAttrs).map(
              ([key, value]) => ({ [key]: value })
            );
            const arrayOfObjectArrays = chunk(
              arrayFromObject,
              LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT
            );
            attributeBatches = arrayOfObjectArrays.map(arr =>
              // reduce the arrays to an object e.g.
              // [{'key': 'value', 'key_2': 'value'}];
              arr.reduce((acc, cur) => ({ ...acc, ...cur }), {})
            );
          }

          // LogEvents for each of the attributes
          attributeBatches.forEach(batch => {
            BloomreachAdapter.logEvent(eventName, eventType, batch, normalizedFlags);
          });
        };
        logEventWithAttributes({ ...attrs });
      }
    ),
    []
  );

  const autoSignInEvent = useCallback(
    ({ success, message, phase }: I.IAutoSignInEventOptions) => {
      trackEvent({
        name:
          phase === SignInPhases.COMPLETE
            ? success
              ? CustomEventNames.SIGN_IN_COMPLETE
              : CustomEventNames.ERROR
            : CustomEventNames.SIGN_IN_SUBMITTED,
        type: EventTypes.Other,
        attributes: {
          Response: success ? 'Successful' : 'Failure',
          'Response Description': success ? 'Successful' : message,
          Method: CustomEventNames.AUTO_SIGN_IN,
        },
      });
    },
    [trackEvent]
  );

  const logNavigationClick = (eventName: CustomEventNames, attrs = {}, customFlags = {}) => {
    trackEvent({
      name: CustomEventNames.BUTTON_CLICK,
      type: EventTypes.Navigation,
      attributes: {
        Name: eventName,
        ...attrs,
      },
      customFlags,
    });
  };

  const logAddPaymentMethodClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_ADD_PAYMENT_METHOD);
  };
  const logCashVoucherMethodClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_CASH_OR_VOUCHER);
  };

  const selectServiceMode = enqueueIfNotDrained(() => {
    trackEvent({
      name: 'Select Service Mode',
      type: EventTypes.Other,
      attributes: null,
    });
  });

  const logOfferActivatedEvent = (sanityId: string, offerName: string, tokenId?: string | null) => {
    trackEvent({
      name: CustomEventNames.OFFER_ACTIVATED,
      type: EventTypes.Other,
      attributes: {
        'Sanity ID': sanityId,
        'Offer Name': offerName,
        external_offer_id: tokenId,
      },
    });
  };

  const logCheckoutEvent = (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => {
    const products = cartEntries
      .map(cart => createProduct(cart, false))
      .filter(Boolean as any as ExcludesNull);
    const pickUpMode = serializePickupMode(serviceMode);
    const customAttributes = {
      'Pickup Mode': pickUpMode,
      'Cart Data': getCartDataItems(cartEntries),
    };

    BloomreachAdapter.logEvent(
      EVENT_NAMES_ECOMMERCE.CHECKOUT,
      EventTypes.Other,
      { products },
      customAttributes
    );
    updateUserAttributes({ 'Pickup Mode': pickUpMode });
  };

  const logUpsellAddedEvent = enqueueIfNotDrained((item: ICartEntry, itemPosition?: number) => {
    if (!item.isUpsell) {
      return;
    }
    const { name, price } = item;

    trackEvent({
      name: 'Upsell Added',
      type: EventTypes.Other,
      attributes: {
        name,
        sanityId: item._id,
        price: price && price / 100,
        upsellItemPosition: itemPosition,
      },
    });
  });

  const logUpsellRemovedEvent = enqueueIfNotDrained((item: ICartEntry) => {
    if (!item.isUpsell) {
      return;
    }
    const { recommender, recommendationToken, price, name } = item;
    BloomreachAdapter.logEvent(CustomEventNames.UPSELL_REMOVED, EventTypes.Other, {
      Id: item._id,
      Name: name,
      Price: price && price / 100,
      Recommender: recommender,
      RecommendationToken: recommendationToken,
      'Source Page': getSourcePage(pathname),
      Locale: locale,
    });
  });

  const logNavBarClickEvent = (text: string, componentKey: string) => {
    trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.NAV_BAR,
        text,
        componentId: componentKey,
      },
    });
  };

  const marketingTileClickEvent = (title: string, position: number, cardId: string) => {
    trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.MARKETING_TILE,
        text: title,
        position: `Tile ${position + ONE_INDEX_OFFSET}`,
        componentId: cardId,
      },
    });
  };

  const signInEvent = useCallback(
    ({ phase, success, message, otpMethod, method, providerType }: I.ISignInEventOptions) => {
      let event;
      const data: Record<string, unknown> = {
        Name: !success ? CustomEventNames.SIGN_IN_FAILED : undefined,
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
        Method: method,
        'Social Service': method === I.SignInMethods.SOCIAL ? providerType : undefined,
        Biometrics: undefined, // To be implemented later
      };

      if (isOTPEnabled(otpMethod)) {
        data['LaunchDarkly Flag Value'] = otpMethod;
      }

      event =
        phase === SignInPhases.START
          ? CustomEventNames.SIGN_IN_SUBMITTED
          : success
            ? CustomEventNames.SIGN_IN_COMPLETE
            : CustomEventNames.ERROR;

      trackEvent({ name: event, type: EventTypes.Other, attributes: data });
    },
    [trackEvent]
  );

  const signOutEvent = (success: boolean, message?: string) => {
    setIsNewSignUp(false);
    trackEvent({
      name: CustomEventNames.SIGN_OUT,
      type: EventTypes.Other,
      attributes: {
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
      },
    });
  };

  useEffectOnce(() => {
    // Enqueue setting the sessionId and deviceId to prevent race condition
    if (!enableCookieBanner || hasAcceptedCookies) {
      init();
    }
  });

  const { enableFlavorFlow } = flagsForUniversalAttributes;

  useEffect(() => {
    const getCurrentAppflowVersionAndSetAttributes = async () => {
      const currentBuild =
        (await getCurrentAppflowVersion().then(version => version?.appflowBuildId)) ||
        appVersionCode();
      updateUniversalAttributes({
        currentBuild,
        enableFlavorFlow,
        isSmallScreen,
      });
    };

    getCurrentAppflowVersionAndSetAttributes().catch(error => logger.error(error));
  }, [enableFlavorFlow, updateUniversalAttributes, isSmallScreen]);

  const providerValues = useMemo(
    () => ({
      providerName: I.CdpProviderTypes.Bloomreach,
      // auth events
      init,
      login,
      logout,
      signInEvent,
      deviceId,
      signOutEvent,
      signUpEvent,
      signUpTermToggleEvent,
      autoSignInEvent,
      updateStaticRoutes,
      updateUserAttributes,
      updateUserIdentities,
      updateUserLocationPermissionStatus,

      // pageView data
      logPageView,
      logCommercePageView,

      // eCommerce events
      addToCart,
      updateItemInCart,
      removeFromCart,
      logOrderLatencyEvent,
      logPurchase,
      logCustomerInitiatedRefund,

      // error tracking
      logError,
      logValidationError,

      // custom events
      logEvent,
      trackEvent,
      logNavigationClick,
      logAddPaymentMethodClick,
      logCashVoucherMethodClick,
      selectServiceMode,
      logOfferActivatedEvent,
      logCheckoutEvent,
      logUpsellAddedEvent,
      logUpsellRemovedEvent,
      logNavBarClickEvent,
      marketingTileClickEvent,
      // initialized sessionId from mParticle
      sessionId: sessionId || uniqueGuid.current,
      // Allows for universal attrs
      updateUniversalAttributes,
    }),
    [
      addToCart,
      autoSignInEvent,
      deviceId,
      init,
      logAddPaymentMethodClick,
      logCashVoucherMethodClick,
      logCheckoutEvent,
      logCustomerInitiatedRefund,
      logError,
      logValidationError,
      logEvent,
      logNavBarClickEvent,
      logNavigationClick,
      logOfferActivatedEvent,
      logOrderLatencyEvent,
      logPageView,
      logPurchase,
      logUpsellAddedEvent,
      logUpsellRemovedEvent,
      login,
      logout,
      marketingTileClickEvent,
      removeFromCart,
      selectServiceMode,
      sessionId,
      signInEvent,
      signOutEvent,
      signUpEvent,
      signUpTermToggleEvent,
      trackEvent,
      updateItemInCart,
      updateStaticRoutes,
      updateUniversalAttributes,
      updateUserAttributes,
      updateUserIdentities,
    ]
  );

  return <BloomreachContext.Provider value={providerValues}>{children}</BloomreachContext.Provider>;
}

export const Bloomreach = BloomreachContext.Consumer;
