import React, { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { ArrowLink } from 'components/arrow-link';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { routes } from 'utils/routing';

export const InvitationCodeLink = () => {
  const cdp = useCdpContext();
  const { formatMessage } = useIntl();

  const onInvitationCodeClick = useCallback(() => {
    cdp.logNavigationClick(CustomEventNames.BUTTON_CLICK_INVITATION_CODE);
  }, [cdp]);

  return (
    <ArrowLink
      data-testid="invitation-code"
      to={routes.invitationCode}
      onClick={onInvitationCodeClick}
      prefixIcon="invitationCode"
    >
      {formatMessage({ id: 'invitationCodeLink' })}
    </ArrowLink>
  );
};
