import * as React from 'react';

import { ISanityImage, ISanityTypographyBlock } from '@rbi-ctg/menu';
import { ActionButton, ActionButtonVariants } from 'components/action-button';
import { TypographyBlock } from 'components/features/components/typography-block';

import {
  BasicTileContainer,
  BasicTileImage,
  BasicTileTextContainer,
  BasicTileTitle,
} from './styled';

interface IMktBasicTileProps {
  className?: string;
  description: ISanityTypographyBlock[];
  image?: ISanityImage;
  title: string;
  link?: string;
  linkLabel?: string;
}

export function MarketingBasicTile({
  className,
  description,
  image,
  title,
  link,
  linkLabel,
}: IMktBasicTileProps) {
  return (
    <BasicTileContainer className={className}>
      {image && <BasicTileImage image={image} alt={title} />}
      <BasicTileTextContainer data-testid="basic-tile-text">
        <BasicTileTitle data-testid="basic-tile-title">{title}</BasicTileTitle>
        <TypographyBlock color={Styles.color.white} content={description} />
      </BasicTileTextContainer>
      {!!link && !!linkLabel && (
        <ActionButton to={link} variant={ActionButtonVariants.OUTLINE}>
          {linkLabel}
        </ActionButton>
      )}
    </BasicTileContainer>
  );
}
