import React, { FC } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { Logo as BrandLogo } from 'components/logo';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { isMobile } from 'utils/environment';
import { routes } from 'utils/routing';

import { LinkNoDecoration, LogoContainer } from '../styled';

import { LOGO_TITLE_KEY, StyledLogo } from './styled';

export const Logo: FC = () => {
  const cdp = useCdpContext();
  const { formatMessage } = useIntl();
  const testId = isMobile() ? 'mobile-header-logo' : 'desktop-header-logo';

  return (
    <LogoContainer>
      <LinkNoDecoration
        to={routes.base}
        onClick={() => cdp.logNavigationClick(CustomEventNames.BUTTON_CLICK_LOGO)}
      >
        <VisuallyHidden>{formatMessage({ id: LOGO_TITLE_KEY })}</VisuallyHidden>
        <StyledLogo data-testid={testId}>
          <BrandLogo height="auto" />
        </StyledLogo>
      </LinkNoDecoration>
    </LogoContainer>
  );
};
