import styled from 'styled-components';

import { IconCircleMinus } from 'components/icons/circle-minus';

export const MinusIcon = styled(IconCircleMinus)<{ disabled: boolean }>`
  width: 24px;
  display: inline-block;
  margin: auto;
  fill: transparent;
  border: 2px solid
    ${props => (props.disabled ? Styles.color.disabledBorder : Styles.color.primary)};
  border-radius: 100%;
  path:last-child {
    fill: ${props => (props.disabled ? Styles.color.disabledColor : Styles.color.primary)};
  }
`;
