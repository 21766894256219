import React, { useEffect, useMemo, useState } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import styled from 'styled-components';

import { IBaseItem, ICartEntry } from '@rbi-ctg/menu';
import { LoadingAnimation } from 'components/loading-animation';
import { useAddUpsellCartItem } from 'pages/cart/cart-item/use-add-upsell-cart-item';
import { useMenuFeatureUpsell } from 'pages/cart/upsell/use-menu-feature-upsell';
import { useOrderContext } from 'state/order';

import { UpsellDialog } from './upsell-dialog';
import { UpsellModalBottomButton } from './upsell-modal-bottom-button';
import { UpsellModalContent } from './upsell-modal-content';

export interface UpsellModalProps {
  title: string;
  isOpen: boolean;
  onClose: VoidFunction;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 50vh;
`;

export const UpsellModal: React.FC<UpsellModalProps> = ({ isOpen, title, onClose }) => {
  const { addToCart, cartPreviewEntries } = useOrderContext();
  const { items, loading } = useMenuFeatureUpsell();

  const [loadedItems, setLoadedItems] = useState<IBaseItem[]>([]);

  const upsellItemsFromCart: ICartEntry[] = useMemo(
    () => cartPreviewEntries.filter((entry: ICartEntry) => entry.isUpsell),
    [cartPreviewEntries]
  );

  useEffect(() => {
    if (!loadedItems.length && items.length) {
      setLoadedItems([...items]);
    }

    if (!loadedItems.length) {
      onClose();
    }
  }, [loadedItems, items, onClose]);

  const { orderAddedAlertMessage, handleAddToCart } = useAddUpsellCartItem({ addToCart, items });
  const hasAddedUpsellItem = upsellItemsFromCart.length > 0;
  const isModalOpen = isOpen && !!loadedItems.length;

  return (
    <UpsellDialog open={isModalOpen} title={title} onCloseModal={onClose}>
      {loading ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <>
          {isOpen && (
            <UpsellModalContent
              upsellItems={loadedItems}
              onAddItem={handleAddToCart}
              cartEntries={cartPreviewEntries}
            />
          )}

          {orderAddedAlertMessage && (
            <VisuallyHidden role="alert">{orderAddedAlertMessage}</VisuallyHidden>
          )}

          <UpsellModalBottomButton onClose={onClose} hasAddedUpsellItem={hasAddedUpsellItem} />
        </>
      )}
    </UpsellDialog>
  );
};
