import React, { FC } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IServerOrder } from '@rbi-ctg/menu';
import { ActionButton, IActionButtonProps } from 'components/action-button';
import { ServiceMode, useOrderContext } from 'state/order';

import { Text } from '../text';

import { usePrepareNow } from './use-prepare-now';

const MessageContainer = styled.div`
  padding-block-start: 2rem;
  padding-block-end: 0;
  padding-inline: 1rem;
`;

const ErrorMessage = styled.p`
  color: ${Styles.color.error};
  text-align: center;
`;

const BoldText = styled(Text)`
  font-weight: ${Styles.fontWeight.heavy};
`;

export const PrepareNowButton: FC<IActionButtonProps & { serverOrder: IServerOrder }> = props => {
  const { fireOrderInXSeconds } = useOrderContext();
  const { serverOrder } = props;
  const { formatMessage } = useIntl();
  const { showPrepareNowButton, hasPrepareNowBeenPressed, showErrorMessage, loading, onClick } =
    usePrepareNow({
      fireOrderInDelay: serverOrder.fireOrderIn || 0,
      createdAtTimeString: serverOrder.createdAt,
      serviceMode: serverOrder.cart.serviceMode,
      fireOrderInXSeconds,
      rbiOrderId: serverOrder.rbiOrderId,
    });

  const buttonMessage =
    serverOrder.cart.serviceMode === ServiceMode.CURBSIDE ? 'imHere' : 'prepareNow';
  const yourOrderIsBeingPrepared =
    serverOrder.cart.serviceMode !== ServiceMode.CURBSIDE
      ? 'yourOrderIsBeingPrepared'
      : 'yourOrderIsBeingPreparedCurbside';
  return (
    <>
      {showErrorMessage && (
        <MessageContainer>
          <ErrorMessage>{formatMessage({ id: 'updateOrderErrorMessage' })}</ErrorMessage>
        </MessageContainer>
      )}
      {showPrepareNowButton && (!hasPrepareNowBeenPressed || loading) && (
        <Box margin="1.5rem auto 1.5rem" justify="center">
          <ActionButton
            data-testid="im-here-button"
            isLoading={loading}
            onClick={onClick}
            {...props}
          >
            {formatMessage({ id: buttonMessage })}
          </ActionButton>
        </Box>
      )}

      {!loading && hasPrepareNowBeenPressed && (
        <Box margin="1.5rem auto 1.5rem">
          <BoldText>{formatMessage({ id: yourOrderIsBeingPrepared })}</BoldText>
        </Box>
      )}
    </>
  );
};
