import { useEffect } from 'react';

import { useLoadingState } from 'state/loading';

export const SetLoading = () => {
  const { setLoading } = useLoadingState();
  useEffect(() => {
    setLoading(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};
