import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { AuthStorage } from 'utils/cognito';
import { StorageKeys } from 'utils/local-storage';

import { getCurrentCognitoUser } from './util';

const clearUserSessionFromLocalStorage = () => {
  AuthStorage.setItem(StorageKeys.USER, null);
  AuthStorage.removeItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY);
};

// return the current user session
export const getCurrentSession = (): Promise<CognitoUserSession | null> => {
  return new Promise(resolve => {
    const cognitoUser = getCurrentCognitoUser();

    if (cognitoUser) {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err || !session.isValid()) {
          // Clear local storage to prevent a user with no session from being "logged in"
          clearUserSessionFromLocalStorage();
          resolve(null);
        } else {
          resolve(session);
        }
      });
    } else {
      // No current user found, clear local storage
      clearUserSessionFromLocalStorage();
      resolve(null);
    }
  });
};
