import { useCallback, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { cloneDeepWith, isPlainObject, pickBy } from 'lodash';
import { useIntl } from 'react-intl';
import uuid from 'uuid';

import { DeepPartial } from '@rbi-ctg/frontend';
import {
  CreateOtpDocument,
  Platform as GatewayPlatform,
  Gender,
  ICreateOtpMutation,
  ICreateOtpMutationVariables,
  ISocialLoginInput,
  IValidateGuestOtpCodeMutation,
  IValidateOtpMutation,
  ProviderType,
  useCreateGuestOtpMutation,
  useSignInJwtMutation,
  useSignUpMutation,
  useSignUpVerifiedGuestMutation,
  useValidateAuthJwtMutation,
  useValidateGuestOtpCodeMutation,
  useValidateOtpMutation,
} from 'generated/graphql-gateway';
import { ModalCb } from 'hooks/use-error-modal';
import { useGetRequiredUserAcceptance } from 'hooks/use-get-required-user-acceptance';
import { signUpMethodToOTPAuthDeliveryMethod, useOtpFeature } from 'hooks/use-otp-feature';
import { useSocialLogin } from 'hooks/use-social-login';
import {
  SignInMethods,
  UserBlocked,
  UserNotFoundContinueToSignUp,
} from 'pages/authentication/sign-up/types';
import {
  signOut as cognitoSignOut,
  validateLogin as cognitoValidateLogin,
} from 'remote/auth/cognito';
import { useAuthGuestContext } from 'state/auth-guest';
import { useBluecodeContext } from 'state/bluecode';
import { useCdpContext } from 'state/cdp';
import { SignInPhases } from 'state/cdp/constants';
import { logOut } from 'state/cdp/mParticle/session-manager';
import { useFlag, useLDContext } from 'state/launchdarkly';
import { SignUpMethodVariations } from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { wipeBrazeUserData } from 'utils/braze';
import { platform, welcomeEmailDomain } from 'utils/environment';
import { isBlockedUserSignInError, parseGraphQLErrorCodes } from 'utils/errors';
import { GraphQLErrorCodes } from 'utils/errors/types';
import { parseJwt } from 'utils/jwt';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { LocalStorage, StorageKeys } from 'utils/local-storage';
import { OTPAuthDeliveryMethod, isOTPEmailEnabled, isOTPSMSEnabled } from 'utils/otp';
import { routes } from 'utils/routing';
import { toAcceptanceAgreementInfoModel } from 'utils/user-agreements';

import { SIGN_IN_FAIL } from '../constants';
import { JwtValidationError, OtpValidationError, UserNotFoundError } from '../errors';

import { useThirdPartyAuthentication } from './use-third-party-authentication';

const NON_SESSION_SPECIFIC_STORAGE_KEYS = [
  StorageKeys.LANGUAGE,
  StorageKeys.REGION,
  StorageKeys.LAST_TIME_COOKIES_ACCEPTED,
  StorageKeys.TABLE_NUMBER,
  StorageKeys.QUEST_COMPLETED_UNLOCKED_INCENTIVE,
];

export interface ISocialLoginParams extends ISocialLoginInput {
  readonly throwError?: boolean;
}

export interface IUseAccountAuthentication {
  refreshCurrentUser(): Promise<void>;
  openErrorDialog: ModalCb;
  setCurrentUser(session: null | CognitoUserSession): void;
}

interface INavigateOptions {
  state: {
    email?: string;
    phoneNumber?: string;
  };
}

interface ISignInNavigation {
  navigateOnSuccess?: boolean;
  navigateState?: INavigateOptions;
}

interface ISignInUserParams {
  email?: string;
  phoneNumber?: string;
}

export type ISignIn = ISignInNavigation & ISignInUserParams;

type MessageId = 'authErrorDifferentProvider' | 'authErrorUserNotFound' | 'authError';

interface ISignUp {
  email: string;
  name: string;
  dob?: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails?: boolean;
  wantsPromotionalEmailOrSms?: boolean;
  zipcode?: string;
  gender?: Gender;
  providerType?: ProviderType;
  invitationCode?: string;
}

export interface ISignUpResult {
  jwt: string | null | undefined;
  cognitoId: string | null;
}

export interface ICreateAccount {
  name: string;
  email: string;
  country: string;
  dob?: string;
  phoneNumber?: string;
  zipcode?: string;
  gender?: string;
  agreesToTermsOfService: boolean;
  invitationCode?: string;
  wantsPromotionalEmails?: boolean;
  navigateOnSuccess: boolean;
  navigateState?: INavigateOptions;
}

interface IValidateLogin {
  jwt: string;
  username: string;
}

interface IGetSesionIdAndChallengeCodeOtp {
  email?: string;
  phoneNumber?: string;
  otpCode: string;
  sessionId: string;
  cognitoEmail?: string;
  isGuest: boolean;
}

type IStoreOtpCredentials = { sessionId: string } & ISignInUserParams;

interface ValidateLoginOtpParams {
  otpCode: string;
  isGuest: boolean;
}
export const getStoredOtpCredentials = () => LocalStorage.getItem(StorageKeys.OTP);
export const storeOtpCredentials = (data: IStoreOtpCredentials) =>
  LocalStorage.setItem(StorageKeys.OTP, data);

export const useAccountAuthentication = ({
  refreshCurrentUser,
  openErrorDialog,
  setCurrentUser,
}: IUseAccountAuthentication) => {
  const { formatMessage } = useIntl();
  const { attemptGetUpdatedLdFlag } = useLDContext();
  const { signInEvent, signOutEvent } = useCdpContext();
  const { logUserOutOfThirdPartyServices } = useThirdPartyAuthentication();
  const enableUserNotFoundMaskAuthFlow = useFlag(
    LaunchDarklyFlag.ENABLE_USER_NOT_FOUND_MASK_ON_AUTH_FLOW
  );
  const enableLoginFromUS = useFlag(LaunchDarklyFlag.ENABLE_LOGIN_FROM_US_IF_USER_NOT_IN_EU);
  const { enableOtpFlagValue } = useOtpFeature();
  const preloaded = LocalStorage.getItem(StorageKeys.AUTH_REDIRECT) || {};
  const [originLocation, setOriginLoc] = useState<null | string>(preloaded.callbackUrl || null);
  const {
    navigate,
    location: { search },
  } = useLocationContext();
  // Track sign in otp method separatly from `enableOtpFlagValue` so that changes
  // in `enableOtpFlagValue` does not affect the sign in method used.
  const [signInOtpMethod, setSignInOtpMethod] = useState<OTPAuthDeliveryMethod>(enableOtpFlagValue);

  const [signInJwtMutation, { loading: signInMutationLoading }] = useSignInJwtMutation();
  const [validateAuthJwtMutation, { loading: validateAuthMutationLoading }] =
    useValidateAuthJwtMutation();

  const { signOut: guestSignOut } = useAuthGuestContext();
  const isUnifiedSocialLoginEnabled = useFlag(LaunchDarklyFlag.ENABLE_UNIFIED_SOCIAL_SIGN_IN);

  /*
  This code ensures that the user service and whitelabel service can be deployed in any order.
  Based on an LD flag, we use either the original CreateOtpDocument (which doesn't have the redirectToUS flag)
  or we use the new CreateOtpDocumentWithUSRedirect, which includes the field.
  In this way, we prevent the front-end from making a request to graphql with a field that might not be there yet
  */
  const CreateOtpDocumentWithUSRedirect = gql`
    mutation CreateOTP($input: CreateOTPInput!) @gateway {
      createOTP(input: $input) {
        maxValidateAttempts
        ttl
        redirectToUS
      }
    }
  `;
  const [createOtpMutation, { loading: createOtpMutationLoading }] = useMutation<
    ICreateOtpMutation,
    ICreateOtpMutationVariables
  >(!enableLoginFromUS ? CreateOtpDocument : CreateOtpDocumentWithUSRedirect);

  const [createGuestOtpMutation, { loading: createGuestOtpMutationLoading }] =
    useCreateGuestOtpMutation();

  const [validateOtpMutation, { loading: validateOtpMutationLoading }] = useValidateOtpMutation();

  const [validateGuestOtpMutation, { loading: validateGuestOtpMutationLoading }] =
    useValidateGuestOtpCodeMutation();

  const [signUpMutation] = useSignUpMutation();

  const [signUpVerifiedGuestMutation] = useSignUpVerifiedGuestMutation();

  const { socialLoginMutate } = useSocialLogin();

  const { getRequiredUserAcceptance } = useGetRequiredUserAcceptance();

  const getSessionIdAndChallengeCodeOtp = useCallback(
    async ({
      email,
      phoneNumber,
      otpCode,
      sessionId,
      isGuest,
    }: IGetSesionIdAndChallengeCodeOtp) => {
      const methodForValidation = isGuest ? validateGuestOtpMutation : validateOtpMutation;

      const { data } = await methodForValidation({
        variables: {
          input: {
            code: otpCode,
            email: email || '',
            phoneNumber,
            sessionId,
          },
        },
      });

      const result = isGuest
        ? (data as IValidateGuestOtpCodeMutation)?.validateGuestOTPCode
        : (data as IValidateOtpMutation)?.exchangeOTPCodeForCognitoCredentials;

      const { sessionId: validatedSessionId, challengeCode, email: cognitoEmail } = result ?? {};

      const invalidResponseForRegisteredUser = !validatedSessionId || !challengeCode;
      if (!isGuest && invalidResponseForRegisteredUser) {
        throw new OtpValidationError('GraphQL validation failed');
      }

      return { sessionId: validatedSessionId, code: challengeCode, cognitoEmail };
    },
    [validateOtpMutation, validateGuestOtpMutation]
  );

  const getSessionIdAndChallengeCode = useCallback(
    async (jwt: string) => {
      try {
        const { data } = await validateAuthJwtMutation({
          variables: {
            input: { jwt },
          },
        });
        const { sessionId, challengeCode, email: cognitoEmail } = data?.validateAuthJwt ?? {};
        if (!sessionId || !challengeCode) {
          throw new JwtValidationError();
        }

        return { sessionId, code: challengeCode, cognitoEmail };
      } catch (error) {
        const errorMessage = error?.originalError?.[0]?.message?.toLowerCase();
        if (
          errorMessage === 'email not registered' ||
          errorMessage === 'phone number not registered'
        ) {
          throw new UserNotFoundError();
        }
        throw error;
      }
    },
    [validateAuthJwtMutation]
  );
  const { requestResetBluecode, isBluecodeEnabled } = useBluecodeContext();

  const signOut = useCallback(async () => {
    try {
      await Promise.all([cognitoSignOut(), isBluecodeEnabled && requestResetBluecode()]);
      setCurrentUser(null);
      // remove USER_SIGNED_IN_SUCCESSFULLY to avoid mis-trigged unexpected sign outs events
      LocalStorage.removeItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY);

      // Explicitly log out in mparticle to stop sending user data.
      logOut();

      wipeBrazeUserData();

      const user = LocalStorage?.getItem(StorageKeys.USER)?.cognitoId;
      if (user) {
        // Once we have namespaced auth storage for all platforms we can remove the excluded
        // keys from here but for now we need to make sure we don't wipe all LocalStorage
        LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });

        signOutEvent(true);
        logUserOutOfThirdPartyServices();
      }
    } catch (error) {
      signOutEvent(false, error.message);
      refreshCurrentUser();
      throw error;
    }
  }, [
    isBluecodeEnabled,
    requestResetBluecode,
    setCurrentUser,
    signOutEvent,
    logUserOutOfThirdPartyServices,
    refreshCurrentUser,
  ]);

  const createGuestOtp = async (param: { email: string }) => {
    const sessionId = uuid();
    await createGuestOtpMutation({
      variables: {
        input: {
          ...param,
          platform: platform() as unknown as GatewayPlatform,
          sessionId,
        },
      },
    });
    storeOtpCredentials({ ...param, sessionId });

    return (navigateState?: INavigateOptions) => {
      navigate(routes.confirmOtp + search, navigateState);
    };
  };

  const signInWithOtp = async (param: { email: string } | { phoneNumber: string }) => {
    const sessionId = uuid();
    await createOtpMutation({
      variables: {
        input: {
          ...param,
          platform: platform() as unknown as GatewayPlatform,
          sessionId,
        },
      },
    });
    storeOtpCredentials({ ...param, sessionId });

    return (navigateState?: INavigateOptions) => {
      navigate(routes.confirmOtp + search, navigateState);
    };
  };

  const signInWithJwt = async (email: string) => {
    LocalStorage.setItem(StorageKeys.LOGIN, email);
    await signInJwtMutation({
      variables: {
        input: {
          email,
          stage: welcomeEmailDomain(),
          platform: platform() as unknown as GatewayPlatform,
        },
      },
    });
    return (navigateState?: INavigateOptions) => {
      navigate(routes.authChallengeJwt, navigateState);
    };
  };

  const getOtpFlagValueForAttributes = useCallback(
    async (ldAttributes: DeepPartial<LDUser>) => {
      // omit nested empty/null values
      const attributes = cloneDeepWith(ldAttributes, value =>
        isPlainObject(value) ? pickBy(value, (v: any) => !!v) : !!value
      );

      const flagValue = (await attemptGetUpdatedLdFlag(
        LaunchDarklyFlag.SIGN_UP_METHOD,
        attributes,
        SignUpMethodVariations.EMAIL
      )) as SignUpMethodVariations;

      return signUpMethodToOTPAuthDeliveryMethod(flagValue);
    },
    [attemptGetUpdatedLdFlag]
  );

  const signInUsingEnabledMethod = async ({
    email,
    phoneNumber,
    otpMethod,
  }: Pick<ISignIn, 'email' | 'phoneNumber'> & { otpMethod: OTPAuthDeliveryMethod }) => {
    const isEmailEnabled = isOTPEmailEnabled(otpMethod);
    const isSMSEnabled = isOTPSMSEnabled(otpMethod);

    if (isSMSEnabled && phoneNumber) {
      return signInWithOtp({ phoneNumber });
    }

    if (!email) {
      throw new Error('No email provided and SMS login disabled');
    }

    if (isEmailEnabled) {
      return signInWithOtp({ email });
    }

    return signInWithJwt(email);
  };

  const createAccountFromVerifiedGuest = async ({
    email,
    name,
    agreesToTermsOfService,
    invitationCode,
    country,
    dob = '',
    phoneNumber = '',
    zipcode = '',
    gender,
    wantsPromotionalEmails = false,
    navigateOnSuccess = true,
    navigateState,
  }: ICreateAccount) => {
    if (!email) {
      throw new Error('No email provided');
    }
    if (!name) {
      throw new Error('No name provided');
    }

    if (!agreesToTermsOfService) {
      throw new Error('User must agree to terms of service');
    }

    const { code, sessionId, cognitoEmail } = await signUpVerifiedGuest({
      email,
      name,
      invitationCode,
      dob,
      phoneNumber,
      country,
      zipcode,
      gender: gender as Gender,
      wantsPromotionalEmails,
    });

    const session = await cognitoValidateLogin({
      username: cognitoEmail || email,
      code: `${code}`,
      sessionId: `${sessionId}`,
    });
    signInEvent({
      phase: SignInPhases.COMPLETE,
      success: true,
      otpMethod: OTPAuthDeliveryMethod.Email,
      method: SignInMethods.OTP,
    });
    guestSignOut();
    setCurrentUser(session);

    if (navigateOnSuccess) {
      navigate(routes.base, navigateState);
    }
  };

  const sendOtpToGuestEmail = async ({
    email,
    navigateOnSuccess = true,
    navigateState,
  }: ISignIn) => {
    if (!email?.length) {
      throw new Error('No email provided');
    }

    try {
      const redirect = await createGuestOtp({ email });
      if (navigateOnSuccess) {
        redirect(navigateState);
      }
    } catch (error) {
      error.code = SIGN_IN_FAIL;
      throw error;
    }
  };

  const signIn = async ({
    email,
    phoneNumber,
    navigateOnSuccess = true,
    navigateState,
  }: ISignIn) => {
    const otpMethod: OTPAuthDeliveryMethod = await getOtpFlagValueForAttributes({
      email,
      custom: { phoneNumber },
    });

    const authenticationCdpMethod = phoneNumber ? SignInMethods.SMS_OTP : SignInMethods.OTP;

    setSignInOtpMethod(otpMethod);
    try {
      const redirect = await signInUsingEnabledMethod({ email, phoneNumber, otpMethod });
      signInEvent({
        phase: SignInPhases.START,
        success: true,
        otpMethod,
        method: authenticationCdpMethod,
      });
      if (navigateOnSuccess) {
        redirect(navigateState);
      }
    } catch (error) {
      const createOtpError = enableUserNotFoundMaskAuthFlow
        ? GraphQLErrorCodes.CREATE_OTP_FAILED
        : GraphQLErrorCodes.AUTH_EMAIL_NOT_REGISTERED;
      const notRegisteredError = parseGraphQLErrorCodes(error)
        .map(err => err.errorCode)
        .includes(createOtpError);
      if (!notRegisteredError) {
        signInEvent({
          phase: SignInPhases.START,
          success: false,
          message: error.message,
          otpMethod,
          method: authenticationCdpMethod,
        });
      }
      error.code = SIGN_IN_FAIL;
      throw error;
    }
  };

  const signUp = async (
    {
      email,
      name,
      dob,
      phoneNumber,
      country,
      wantsPromotionalEmails = false,
      wantsPromotionalEmailOrSms = false,
      zipcode,
      gender,
      providerType,
      invitationCode,
    }: ISignUp,
    signInOverride: (args: ISignIn) => Promise<void> = signIn
  ): Promise<any> => {
    let jwt;
    let cognitoId = null;

    const requiredUserAcceptanceDocs = await getRequiredUserAcceptance();

    const requiredAcceptanceAgreementInfo = !requiredUserAcceptanceDocs.length
      ? undefined
      : toAcceptanceAgreementInfoModel(requiredUserAcceptanceDocs);

    try {
      const currentPlatform = platform();
      const { data } = await signUpMutation({
        variables: {
          input: {
            country,
            dob,
            name,
            phoneNumber,
            platform: currentPlatform as unknown as GatewayPlatform,
            stage: welcomeEmailDomain(),
            userName: email || phoneNumber,
            wantsPromotionalEmails: wantsPromotionalEmails || wantsPromotionalEmailOrSms,
            wantsPromotionalSms: wantsPromotionalEmailOrSms,
            zipcode,
            gender,
            providerType,
            referralCode: invitationCode,
            requiredAcceptanceAgreementInfo,
          },
        },
      });
      jwt = data?.signUp;
      cognitoId = jwt ? parseJwt(jwt).sub : null;
    } catch (error) {
      throw error;
    }

    // If providerType is present we can't sign in with OTP
    if (!providerType) {
      await signInOverride({
        email,
        phoneNumber,
      });
    }

    return { jwt, cognitoId };
  };

  const signUpVerifiedGuest = async ({
    email,
    name,
    dob,
    phoneNumber,
    country,
    wantsPromotionalEmails = false,
    zipcode,
    gender,
    providerType,
    invitationCode,
  }: ISignUp) => {
    const currentPlatform = platform();
    try {
      const { data } = await signUpVerifiedGuestMutation({
        variables: {
          input: {
            country,
            dob,
            name,
            phoneNumber,
            platform: currentPlatform as unknown as GatewayPlatform,
            stage: welcomeEmailDomain(),
            userName: email,
            wantsPromotionalEmails,
            zipcode,
            gender,
            providerType,
            referralCode: invitationCode,
          },
        },
      });
      const {
        challengeCode: code,
        email: cognitoEmail,
        sessionId,
      } = data?.signUpVerifiedGuest || {};

      if (!sessionId || !code) {
        throw new JwtValidationError();
      }

      return { code, cognitoEmail, sessionId };
    } catch (error) {
      throw error;
    }
  };

  const signInSocialLogin = useCallback(
    async ({
      providerToken,
      providerType,
      providerEmail,
      throwError,
    }: ISocialLoginParams): Promise<void | UserNotFoundContinueToSignUp | UserBlocked> => {
      try {
        const {
          challengeCode = '',
          sessionId = '',
          email,
        } = await socialLoginMutate({
          providerToken,
          providerType,
          providerEmail,
        });

        const session = await cognitoValidateLogin({
          username: email || '',
          code: challengeCode,
          sessionId,
        });

        guestSignOut();
        setCurrentUser(session);

        if (throwError) {
          signInEvent({
            phase: SignInPhases.START,
            success: true,
            method: SignInMethods.SOCIAL,
            providerType,
          });
        }

        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          method: SignInMethods.SOCIAL,
          providerType,
        });
        navigate(originLocation || routes.base);
      } catch (error) {
        // This flow is only used when checking if the user already has an account
        // The error should be thrown only if the user is not found in our system
        if (throwError && error?.graphQLErrors?.[0]?.extensions.code === 'USER_NOT_FOUND') {
          throw error;
        }

        const errorMap: Record<string, MessageId> = {
          INVALID_PROVIDER_TOKEN_FOR_USER: 'authErrorDifferentProvider',
          USER_NOT_FOUND: 'authErrorUserNotFound',
        };

        signInEvent({
          phase: SignInPhases.START,
          success: false,
          message: error.message,
          method: SignInMethods.SOCIAL,
          providerType,
        });

        const errorCode = error?.graphQLErrors?.[0]?.extensions.code as keyof typeof errorMap;

        const errorMessageId: MessageId = errorMap[errorCode] || 'authError';

        if (isUnifiedSocialLoginEnabled && errorMessageId === 'authErrorUserNotFound') {
          return { shouldContinueToSignUp: true };
        }

        if (isBlockedUserSignInError(error?.graphQLErrors)) {
          return {
            isBlocked: true,
          };
        }

        openErrorDialog({
          error,
          message: formatMessage({ id: errorMessageId }),
          modalAppearanceEventMessage: 'Error: Sign In Failure',
        });

        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: formatMessage({ id: errorMessageId }),
          method: SignInMethods.SOCIAL,
          providerType,
        });
      }
    },
    [formatMessage, signInEvent, navigate, openErrorDialog, setCurrentUser, socialLoginMutate]
  );

  const validateLoginOtp = useCallback(
    async ({ otpCode, isGuest }: ValidateLoginOtpParams) => {
      const { email, phoneNumber, sessionId: storedSessionId } = getStoredOtpCredentials() ?? {};
      const cdpMethod = phoneNumber ? SignInMethods.SMS_OTP : SignInMethods.OTP;
      try {
        const emailOrPhone = email || phoneNumber;
        if (!emailOrPhone || !storedSessionId) {
          throw new OtpValidationError('Missing email/phone number or sessionId');
        }

        const { code, sessionId, cognitoEmail } = await getSessionIdAndChallengeCodeOtp({
          email,
          phoneNumber,
          otpCode,
          sessionId: storedSessionId,
          isGuest,
        });

        // For a guest user just validating the OTP is fine.
        // If no code or session, then is a non-registered user.
        if (isGuest && !(code && sessionId)) {
          throw new UserNotFoundError();
        }

        const validateOtpMethod =
          signInOtpMethod && signInOtpMethod !== OTPAuthDeliveryMethod.None
            ? signInOtpMethod
            : await getOtpFlagValueForAttributes({
                email,
                custom: { phoneNumber },
              });
        const session = await cognitoValidateLogin({
          username: cognitoEmail || email || phoneNumber,
          code: `${code}`,
          sessionId: `${sessionId}`,
        });
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          otpMethod: validateOtpMethod,
          method: cdpMethod,
        });
        guestSignOut();
        setCurrentUser(session);
      } catch (error) {
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: error.message,
          otpMethod: signInOtpMethod,
          method: cdpMethod,
        });

        throw error;
      }
    },
    [
      signInOtpMethod,
      getSessionIdAndChallengeCodeOtp,
      getOtpFlagValueForAttributes,
      signInEvent,
      setCurrentUser,
    ]
  );

  const validateLogin = useCallback(
    async ({ jwt, username }: IValidateLogin) => {
      try {
        const { sessionId, code, cognitoEmail } = await getSessionIdAndChallengeCode(jwt);
        const session = await cognitoValidateLogin({
          username: cognitoEmail || username,
          code,
          sessionId,
        });
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          method: SignInMethods.OTP,
        });
        guestSignOut();
        setCurrentUser(session);
        // Add a marker when a user is successful login to track unexpected sign outs
        LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
      } catch (error) {
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          message: error.message,
          method: SignInMethods.OTP,
        });
        openErrorDialog({
          error,
          message: formatMessage({ id: 'authError' }),
          modalAppearanceEventMessage: 'Error: JWT Validation Failure',
        });
        throw error;
      }
    },
    [formatMessage, getSessionIdAndChallengeCode, signInEvent, openErrorDialog, setCurrentUser]
  );

  return {
    authLoading:
      signInMutationLoading ||
      validateAuthMutationLoading ||
      createOtpMutationLoading ||
      createGuestOtpMutationLoading ||
      validateOtpMutationLoading ||
      validateGuestOtpMutationLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signInSocialLogin,
    signOut,
    signUp,
    validateLogin,
    validateLoginOtp,
    sendOtpToGuestEmail,
    createAccountFromVerifiedGuest,
  };
};
