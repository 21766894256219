import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import { IconLoyaltyPoints } from 'components/icons/loyalty-points-icon';

import { Block } from '../details-modal.styled';

import { getPointsLabel } from './get-points-label';
import { PointsEarnedContainer, PointsEarnedLabel } from './styled';
import { IEarnedPointsProps } from './types';

export const PointsEarned: FC<IEarnedPointsProps> = ({ lylTransaction }) => {
  const { formatMessage } = useIntl();
  const pointsEarned = lylTransaction.pointsEarned + lylTransaction.bonusPointsEarned;

  if (!pointsEarned) {
    return null;
  }

  return (
    <Block>
      <PointsEarnedContainer>
        <PointsEarnedLabel>
          <IconLoyaltyPoints />
          {getPointsLabel(pointsEarned, formatMessage)}
        </PointsEarnedLabel>
      </PointsEarnedContainer>
    </Block>
  );
};
