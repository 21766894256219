import React from 'react';

import { ExternalLink } from 'components/external-link';
import { ITextWidgetWithUrlFragment } from 'generated/sanity-graphql';

import { P } from './styled';

type ITextWidgetWithUrlProps = ITextWidgetWithUrlFragment;

export const TextWithUrl: React.FC<ITextWidgetWithUrlProps> = props => {
  const link = (
    <ExternalLink href={`${props.url?.locale!}`}> {props.textUrl?.locale} </ExternalLink>
  );

  const linkEmbeddedText = props.text?.locale
    ?.split('{url}')
    .reduce((acc, text) => [...acc, text, link], [])
    .slice(0, -1);

  return <P>{linkEmbeddedText} </P>;
};
