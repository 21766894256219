import React, { createContext, useContext } from 'react';

import type { ConnectionStatus } from '@capacitor/network';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import { NetworkConnectionError } from 'remote/exceptions';

import { Query, useNetwork } from './hook';

export interface NetworkCtx {
  connection: ConnectionStatus;
  hasNetworkError: boolean;
  hasNotAuthenticatedError: boolean;
  query: Query;
  setHasNetworkError: SetState<boolean>;
  setHasNotAuthenticatedError: SetState<boolean>;
  defaultErrorHandler(error: Error | NetworkConnectionError): void;
  sanityEndpoints: { graphql: string; groq: string };
}

export const NetworkContext = createContext<NetworkCtx>({} as NetworkCtx);
export const useNetworkContext = () => useContext(NetworkContext);

export function NetworkProvider({ children }: IBaseProps) {
  const value = useNetwork();

  return <NetworkContext.Provider value={value}>{children}</NetworkContext.Provider>;
}

export const Network = NetworkContext.Consumer;
