import React from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ILocation } from '@rbi-ctg/frontend';
import { nativeMapsHref } from 'utils/native-actions';

import { theme } from './theme';

const DirectionsLinkStyled = styled(Link)`
  color: ${theme.directionsLinkStyledColor};
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: underline;
  cursor: pointer;
`;

interface IProps {
  activeCoordinates: ILocation | null;
  position: ILocation;
  className?: string;
}

export const DirectionsLink: React.FC<IProps> = ({ activeCoordinates, position, className }) => {
  const { formatMessage } = useIntl();
  return (
    <DirectionsLinkStyled
      className={className}
      rel="noopener noreferrer"
      target="_blank"
      to={nativeMapsHref(activeCoordinates, position)}
    >
      {formatMessage({ id: 'directions' })}
    </DirectionsLinkStyled>
  );
};
