import React, { useCallback, useEffect, useState } from 'react';

import { Dialog, useDialogUIState } from '@rbilabs/components-library/build/components/dialog';
import { useIntl } from 'react-intl';

import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useNetworkContext } from 'state/network';
import * as location from 'utils/location';

export function ErrorNetwork() {
  const cdp = useCdpContext();
  const { formatMessage } = useIntl();
  const dialog = useDialogUIState();
  const { hasNetworkError } = useNetworkContext();
  const [browsingOffline, setBrowsingOffline] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);

  const onReload = useCallback(() => {
    cdp.trackEvent({ name: CustomEventNames.NETWORK_ERROR_RELOAD, type: EventTypes.Other });
    location.reload();
  }, [cdp]);

  useEffect(() => {
    if (!hasOpened && hasNetworkError && !browsingOffline) {
      cdp.trackEvent({ name: CustomEventNames.NETWORK_ERROR, type: EventTypes.Other });
      setHasOpened(true);
      dialog.show();
    }
  }, [browsingOffline, dialog, hasNetworkError, hasOpened, cdp]);

  return (
    <Dialog
      data-testid="error-network-dialog"
      dialog={dialog}
      headerText={formatMessage({ id: 'lostNetworkConnectivity' })}
      bodyText={formatMessage({ id: 'checkNetworkSettings' })}
      {...{ 'aria-label': formatMessage({ id: 'lostNetworkConnectivity' }) }}
      closeButtonText={formatMessage({ id: 'close' })}
      onDismiss={() => setBrowsingOffline(true)}
      buttons={[
        {
          text: formatMessage({ id: 'reload' }),
          onClick: onReload,
        },
      ]}
    />
  );
}
