import { onError } from '@apollo/client/link/error';

import { logger } from 'utils/logger';

import { REQUEST_CANCELLED_KEY } from './cancel-request-link';

export const withErrorLogger = onError(({ operation, graphQLErrors }) => {
  const loggableErrors = graphQLErrors?.filter(error => !error.extensions?.[REQUEST_CANCELLED_KEY]);
  if (!loggableErrors?.length) {
    return;
  }

  const logErrors = () => {
    for (const error of loggableErrors) {
      logger.error(`GraphQL error calling ${operation.operationName}`, {
        error,
        operationName: operation.operationName,
      });
    }
  };

  return logErrors();
});
