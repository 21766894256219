import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { ActionButton } from 'components/action-button';

import { ButtonSubText } from './styled';
import { ICardButton } from './type';

export const CardButton: FC<ICardButton> = ({
  imHereText,
  isLoading,
  commitOrder,
  imClose: { enabled: enableImClose, text: imCloseText, approxMinutesAway },
  storeUnavailable,
}) => {
  const { formatMessage } = useIntl();

  let buttonText = storeUnavailable ? formatMessage({ id: 'storeUnavailable' }) : imHereText;

  if (enableImClose) {
    buttonText = imCloseText;
  }

  return (
    <ActionButton
      fullWidth
      aria-label={buttonText}
      data-testid="im-here-button"
      isLoading={isLoading}
      onClick={commitOrder}
      disabled={isLoading || storeUnavailable}
    >
      {buttonText}
      {enableImClose && (
        <ButtonSubText data-testid="im-close-button">{approxMinutesAway}</ButtonSubText>
      )}
    </ActionButton>
  );
};
