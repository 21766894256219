import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { StyledLink } from 'components/list-of-links/styled';

export const CookieSettingsLink: FC = () => {
  const { formatMessage } = useIntl();
  const toggleBanner = () => {
    window?.OneTrust?.ToggleInfoDisplay();
  };

  return (
    <StyledLink onClick={toggleBanner} aria-hidden="true" to={''}>
      {formatMessage({ id: 'cookiesSettings', defaultMessage: 'Cookies Settings' })}
    </StyledLink>
  );
};
