import React, { FC } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { QueueModalContent } from 'pages/queue/modal-content';
import { OrderStatus } from 'state/order/types';
import { ServiceMode } from 'state/service-mode';

import { ConfirmPickupMemo } from './confirm-pickup';
import { DeliveryConfirmation } from './delivery/delivery-confirmation';
import { Fallback } from './fallback';
import { PickupConfirmedMemo } from './pickup-confirmed';

interface IOrderConfirmationContentProps {
  loading: boolean;
  serverOrder: IServerOrder | null;
}

export const OrderConfirmationPageContent: FC<IOrderConfirmationContentProps> = (
  props: IOrderConfirmationContentProps
) => {
  const { loading, serverOrder } = props;

  if (!serverOrder) {
    return <Fallback />;
  }

  if (loading) {
    return <ConfirmPickupMemo serverOrder={serverOrder} />;
  }

  const {
    cart: { serviceMode },
    status: orderStatus,
  } = serverOrder;

  if (serviceMode === ServiceMode.DELIVERY) {
    return <DeliveryConfirmation serverOrder={serverOrder} />;
  }

  switch (orderStatus) {
    case OrderStatus.INSERT_REQUESTED:
    case OrderStatus.PAYMENT_REQUESTED:
    case OrderStatus.PAYMENT_REQUIRED:
    case OrderStatus.PAYMENT_SUCCESSFUL:
    case OrderStatus.PRICE_SUCCESSFUL:
    case OrderStatus.VALIDATION_ERROR:
      return <ConfirmPickupMemo serverOrder={serverOrder} />;
    case OrderStatus.QUEUED:
      return <QueueModalContent rbiOrderId={serverOrder.rbiOrderId} />;
    case OrderStatus.REFUND_REQUESTED:
    case OrderStatus.INSERT_SUCCESSFUL:
    case OrderStatus.REFUND_SUCCESSFUL:
    case OrderStatus.UPDATE_REQUESTED:
    case OrderStatus.UPDATE_ERROR:
    case OrderStatus.UPDATE_SUCCESSFUL:
      return <PickupConfirmedMemo serverOrder={serverOrder} />;
    default:
      return <Fallback />;
  }
};
